import { useApi } from '~/restAPI';
import { Products } from '~/restAPI/Products';
import { Deeplink } from '~/restAPI/Deeplink';
import { Home } from '~/restAPI/Home';
import {
  ProductFilterItemsRequest,
  ProductFilterInfoRequest,
  SearchProductFilterRequest,
  SearchProductFilterItemsRequest,
  SearchProductFilterInfoRequest,
} from '~/restAPI/data-contracts';

export function useCatalog() {
  const cancelGetFiltersToken = 'cancelGetFiltersToken';

  const { createApiInstance } = useApi();
  const productsApi = createApiInstance(Products);
  const deeplinkApi = createApiInstance(Deeplink);
  const homeApi = createApiInstance(Home);

  const productsSearchQueryHash = useState('productsSearchQueryHash', () => '');

  function getDeeplink(path: string, withSEO?: boolean) {
    return deeplinkApi.resolveLinkUsingPost({
      link: path,
      withSEO,
    }, { format: 'json' })
      .then(({ data }) => {
        const params = mappedParams(data.data?.catalogFilter);

        return {
          ...data.data,
          catalogFilter: params,
        };
      });
  }

  // старая апишка получения фильтров
  function getFilters(params: SearchProductFilterRequest = {}) {
    return productsApi.postApiV2ProductsFilter(params, { format: 'json', cancelToken: cancelGetFiltersToken })
      .then(({ data }) => data.data);
  }

  // новая апишка получения фильтров
  function getFiltersV2(params: SearchProductFilterRequest = {}) {
    return productsApi.postApiV2ProductsSearch(params, { format: 'json', cancelToken: cancelGetFiltersToken })
      .then(({ data }) => data.data);
  }

  function cancelGetFilters() {
    productsApi.abortRequest(cancelGetFiltersToken);
  }

  // старая апишка получения товаров по фильтрам
  function getProductsByFilters(params: ProductFilterItemsRequest, query?: { code?: string }) {
    return productsApi.postApiV2ProductsFilterItems(params, query)
      .then(({ data }) => data.data);
  }

  // новая апишка получения товаров по фильтрам
  function getProductsByFiltersV2(params: SearchProductFilterItemsRequest, query?: { code?: string }) {
    return productsApi.postApiV2ProductsSearchItems(params, query)
      .then(({ data }) => data.data);
  }

  // старая апишка получения инфы по фильтру
  function getProductsFilterInfo(params: ProductFilterInfoRequest, query?: { code?: string }) {
    return productsApi.postApiV2ProductsFilterInfo(params, query)
      .then(({ data }) => data.data);
  }

  // новая апишка получения инфы по фильтру
  function getProductsFilterInfoV2(params: SearchProductFilterInfoRequest, query?: { code?: string }) {
    return productsApi.postApiV2ProductsSearchInfo(params, query)
      .then(({ data }) => data.data);
  }

  function getProductsForBanner(query: { id: string; countryId?: string; }, params: ProductFilterItemsRequest) {
    return homeApi.postApiV2HomeBannerFilterableItems(query, params)
      .then(({ data }) => data.data);
  }

  return {
    productsSearchQueryHash,
    getDeeplink,
    getFilters,
    getProductsByFilters,
    getProductsFilterInfo,
    getProductsForBanner,
    cancelGetFilters,
    getFiltersV2,
    getProductsByFiltersV2,
    getProductsFilterInfoV2,
  };
}
