/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Api2ResponseOfAddressEndpointDTO */
export interface Api2ResponseOfAddressEndpointDTO {
  data?: AddressEndpointDTO1;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfCategoryTree */
export interface Api2ResponseOfCategoryTree {
  data?: CategoryTree;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfCategoryDTO */
export interface Api2ResponseOfListOfCategoryDTO {
  data?: CategoryDTORes[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfBrandDTO */
export interface Api2ResponseOfListOfBrandDTO {
  data?: BrandDTORes[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfAttributeDTO */
export interface Api2ResponseOfListOfAttributeDTO {
  data?: AttributeDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfOrderSourceInfoDTO */
export interface Api2ResponseOfListOfOrderSourceInfoDTO {
  data?: OrderSourceInfoDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfLegalEntitiesDTO */
export interface Api2ResponseOfListOfLegalEntitiesDTO {
  data?: LegalEntityDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfLegalEntitiesDTO */
export interface Api2ResponseOfListOfDictionaryDTO {
  data?: DictionaryDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfProductTagDTO */
export interface Api2ResponseOfListOfProductTagDTO {
  data?: ProductTagDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOflong */
export interface Api2ResponseOfListOflong {
  data?: number[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfProductDTO */
export interface Api2ResponseOfProductDTO {
  data?: ProductDTORes;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfProductDTOLite */
export interface Api2ResponseOfProductDTOLite {
  data?: ProductDTOLite;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfProductDTO */
export interface Api2ResponseOfListOfProductDTO {
  data?: ProductDTORes[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOfProductDTO */
export interface Api2ResponseOfPageOfProductDTO {
  data?: PageOfProductDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOfProductDTOIntegrationLite */
export interface Api2ResponseOfPageOfProductDTOIntegrationLite {
  data?: PageOfProductDTOIntegrationLite;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfProductImageDTO */
export interface Api2ResponseOfListOfProductImageDTO {
  data?: ProductImageDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** AttributeDTO */
export interface AttributeDTO {
  attributeValues?: AttributeValueDTO[];
  /** @format int64 */
  id?: number;
  isRequired?: boolean;
  kind?: "GENERIC" | "MATERIAL" | "COLOR";
  name?: string;
  showFilter?: boolean;
}

/** AttributeWithValueDTO */
export interface AttributeWithValueDTO {
  attribute?: AttributeDTO;
  attributeValue?: AttributeValueDTO;
}

/** AttributeValueDTO */
export interface AttributeValueDTO {
  icon?: string;
  /** @format int64 */
  id: number;
  ofValue?: string;
  pluralGenitiveValue?: string;
  singularGenitiveValue?: string;
  transliterateValue?: string;
  value?: string;
}

/** AdditionalSizeDTO */
export interface AdditionalSizeDTO {
  /** @format int64 */
  id?: number;
  image?: string;
  isRequired?: boolean;
  name?: string;
  transliterateName?: string;
  /** @format int32 */
  value?: number;
}

/** SizeValueDTO */
export interface SizeValueDTO {
  additionalSizeValues?: Record<string, number>;
  categorySizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  categorySizeValue?: string;
  /** @format int32 */
  count?: number;
  /** @format int64 */
  id?: number;
  interestingSizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  interestingSizeValue?: string;
  offer?: OfferDTO;
  productSizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  productSizeValue?: string;
}

/** SizeValueDTORes */
export interface SizeValueDTORes {
  additionalSizeValues?: Record<string, number>;
  categorySizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  categorySizeValue?: string;
  /** @format int32 */
  count?: number;
  /** @format int64 */
  id?: number;
  interestingSizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  interestingSizeValue?: string;
  offer?: OfferDTORes;
  productSizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  productSizeValue?: string;
  productCustomSizeType?: string;
  productCustomSizeValue?: string;
}

/** SizeValueDTOReq */
export interface SizeValueDTOReq {
  additionalSizeValues?: Record<string, number>;
  categorySizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  categorySizeValue?: string;
  /** @format int32 */
  count?: number;
  /** @format int64 */
  id?: number;
  interestingSizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  interestingSizeValue?: string;
  offer?: OfferDTOReq;
  productSizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  productSizeValue?: string;
  productCustomSizeType?: string;
  productCustomSizeValue?: string;
}

/** OfferDTO */
export interface OfferDTO {
  /** @format int64 */
  brandId?: number;
  /** @format int64 */
  categoryId?: number;
  consumed?: boolean;
  /** @format int64 */
  id?: number;
  isSizeAvailable?: boolean;
  /** @format bigdecimal */
  negotiatedPrice?: number;
  offerStatus?: "ACCEPTED" | "PENDING" | "REJECTED";
  /** @format int64 */
  offerorId?: number;
  /** @format bigdecimal */
  price?: number;
  product?: ProductDTO;
  /** @format int64 */
  productId?: number;
  productState?:
    | "BANED"
    | "DELETED"
    | "DRAFT"
    | "HIDDEN"
    | "NEED_MODERATION"
    | "NEED_RETOUCH"
    | "PUBLISHED"
    | "REJECTED"
    | "RETOUCH_DONE"
    | "SECOND_EDITION"
    | "SOLD";
  /** @format int64 */
  sizeId?: number;
}

/** OfferDTORes */
export interface OfferDTORes {
  /** @format int64 */
  brandId?: number;
  /** @format int64 */
  categoryId?: number;
  consumed?: boolean;
  /** @format int64 */
  id?: number;
  isSizeAvailable?: boolean;
  /** @format bigdecimal */
  negotiatedPrice?: number;
  offerStatus?: "ACCEPTED" | "PENDING" | "REJECTED";
  /** @format int64 */
  offerorId?: number;
  /** @format bigdecimal */
  price?: number;
  product?: ProductDTORes;
  /** @format int64 */
  productId?: number;
  productState?:
    | "BANED"
    | "DELETED"
    | "DRAFT"
    | "HIDDEN"
    | "NEED_MODERATION"
    | "NEED_RETOUCH"
    | "PUBLISHED"
    | "REJECTED"
    | "RETOUCH_DONE"
    | "SECOND_EDITION"
    | "SOLD";
  /** @format int64 */
  sizeId?: number;
}

/** OfferDTOReq */
export interface OfferDTOReq {
  /** @format int64 */
  brandId?: number;
  /** @format int64 */
  categoryId?: number;
  consumed?: boolean;
  /** @format int64 */
  id?: number;
  isSizeAvailable?: boolean;
  /** @format bigdecimal */
  negotiatedPrice?: number;
  offerStatus?: "ACCEPTED" | "PENDING" | "REJECTED";
  /** @format int64 */
  offerorId?: number;
  /** @format bigdecimal */
  price?: number;
  product?: ProductDTOReq;
  /** @format int64 */
  productId?: number;
  productState?:
    | "BANED"
    | "DELETED"
    | "DRAFT"
    | "HIDDEN"
    | "NEED_MODERATION"
    | "NEED_RETOUCH"
    | "PUBLISHED"
    | "REJECTED"
    | "RETOUCH_DONE"
    | "SECOND_EDITION"
    | "SOLD";
  /** @format int64 */
  sizeId?: number;
}

/** DescriptionAttributeView */
export interface DescriptionAttributeView {
  /** @format int64 */
  attributeValueId?: number;
  title?: string;
  value?: string;
}

/** CommentDTO */
export interface CommentDTO {
  /** @format int64 */
  id?: number;
  images?: string[];
  /** @format int64 */
  parentCommentId?: number;
  /** @format int64 */
  productId?: number;
  /** @format int64 */
  productRequestId?: number;
  /** @format int64 */
  publishedAtTime?: number;
  publisher?: UserDTO;
  replyTo?: string;
  needsTranslate?: boolean;
  subComments?: CommentDTO[];
  text?: string;
  deletedAtTime?: string;
  editedAtTime?: string;
}

/** CommentView */
export interface CommentView {
  avatar?: string;
  /** @format int64 */
  id?: number;
  images?: string[];
  isAnswer?: boolean;
  publishTime?: string;
  /** @format int64 */
  publishZonedDateTime?: number;
  text?: string;
  user?: string;
  /** @format int64 */
  userId?: number;
}

/** CountryDTO */
export interface CountryDTO {
  currency?: CurrencyDTO;
  name?: string;
  uiCurrencyCode?: string;
  environment?: "RU" | "INT";
  isoCodeAlpha2?: string;
  imageUrl?: string;
  requireZipcode?: boolean;
  /** @format int64 */
  id?: number;
}

/** CountryDTO */
export interface CityDTO {
  name?: string;
  region?: string;
  /** @format int64 */
  id?: number;
}

/** BlankId */
export interface BlankId {
  /** @format int64 */
  id?: number;
}

/** CurrencyDTO */
export interface CurrencyDTO {
  name?: string;
  sign?: string;
  isoCode?: string;
  /** @format int64 */
  isoNumber?: number;
  /** @format int64 */
  id?: number;
  base?: boolean;
  active?: boolean;
  selectedByDefault?: boolean;
}

/** AddressEndpointAggregationDTO */
export interface AddressEndpointAggregationRequestDTO {
  physicalAddress?: AddressEndpointAggRequestDTO;
  billingAddress?: AddressEndpointAggRequestDTO;
  usePhysicalAddressForBilling?: boolean;
}

/** AddressEndpointAggregationDTO */
export interface AddressEndpointAggregationRequestUpdateDTO {
  physicalAddress?: AddressEndpointAggRequestUpdateDTO;
  billingAddress?: AddressEndpointAggRequestUpdateDTO;
  usePhysicalAddressForBilling?: boolean;
}

/** AddressEndpointAggregationDTO */
export interface AddressEndpointAggregationResponseDTO {
  physicalAddress?: AddressEndpointAggResponseDTO;
  billingAddress?: AddressEndpointAggResponseDTO;
  /** @format int64 */
  id?: number;
  usePhysicalAddressForBilling?: boolean;
}

/** AddressDTO */
export interface AddressDTO {
  address?: string;
  addressBreakdown?: AddressBreakdownDTO;
  address2?: string;
  address3?: string;
  city?: string;
  cityData?: CityDTO;
  country?: string;
  countryData?: CountryDTO;
  /** @format int64 */
  id?: number;
  region?: string;
  zipCode?: string;
  fiasId?: string;
  regionFiasId?: string;
  cityFiasId?: string;
  settlementFiasId?: string;
  dadataFullAddress?: string;
  fullAddress?: string;
  checked?: boolean;
  /** @format int64 */
  createTime?: number;
  /** @format int64 */
  deleteTime?: number;
  /** @format int64 */
  changeTime?: number;
}

export interface AddressBreakdownDTO {
  street?: string;
  house?: string;
  flat?: string;
}

/** AddressAggregationRequestDTO */
export interface AddressAggregationRequestDTO {
  address?: string;
  address2?: string;
  address3?: string;
  /** @format int64 */
  city?: number;
  /** @format int64 */
  country?: number;
  zipCode?: string;
}

/** AddressAggregationRequestUpdateDTO */
export interface AddressAggregationRequestUpdateDTO {
  address?: string;
  address2?: string;
  address3?: string;
  /** @format int64 */
  city?: number;
  /** @format int64 */
  country?: number;
  zipCode?: string;
  /** @format int64 */
  id?: number;
}

/** AddressAggregationResponseDTO */
export interface AddressAggregationResponseDTO {
  address?: string;
  address2?: string;
  address3?: string;
  cityData?: CityDTO;
  countryData?: CountryDTO;
  /** @format int64 */
  id?: number;
  zipCode?: string;
}

/** AddressEndpointDTO */
export interface AddressEndpointDTO {
  address?: AddressDTO;
  firstName?: string;
  /** @format int64 */
  id?: number;
  lastName?: string;
  patronymicName?: string;
  phone?: string;
  /** @format bigdecimal */
  deliveryCost?: number;
  /** @format int64 */
  deleteTime?: number;
  /** @format int64 */
  changeTime?: number;
}

/** AddressEndpointDTO */
export interface AddressEndpointAggRequestDTO {
  address?: AddressAggregationRequestDTO;
  firstName?: string;
  lastName?: string;
  patronymicName?: string;
  phone?: string;
}

/** AddressEndpointDTO */
export interface AddressEndpointAggRequestUpdateDTO {
  address?: AddressAggregationRequestUpdateDTO;
  firstName?: string;
  lastName?: string;
  patronymicName?: string;
  phone?: string;
  /** @format int64 */
  id?: number;
}

/** AddressEndpointDTO */
export interface AddressEndpointAggResponseDTO {
  address?: AddressAggregationResponseDTO;
  firstName?: string;
  /** @format int6 */
  id?: number;
  lastName?: string;
  patronymicName?: string;
  phone?: string;
}

/** BreadcrumbDTO */
export interface BreadcrumbDTO {
  name?: string;
  url?: string;
}

/** UserDTO */
export interface UserDTO {
  acceptsReturns?: boolean;
  avatarPath?: string;
  /** @format int64 */
  birthDate?: number;
  /** @format int32 */
  brandLikesCount?: number;
  email?: string;
  firstChar?: string;
  fullName?: string;
  /** @format int64 */
  id?: number;
  isFollowed?: boolean;
  isPro?: boolean;
  isTrusted?: boolean;
  /** @format int32 */
  likesCount?: number;
  name?: string;
  nickname?: string;
  /** @format int32 */
  productLikesCount?: number;
  /** @format int32 */
  productsCount?: number;
  /** @format int64 */
  registrationTime?: number;
  syncAgree?: boolean;
  syncSuccess?: boolean;
  sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
  /** Link to user profile in old admin view */
  adminProfileUrl?: string;
  /** Бейджики пользователя в O!Community */
  communityBadge?: CommunityBadge;
  commonTags?: UserCommonTagDTO[];
}

/** Бейджики пользователя в O!Community */
export interface CommunityBadge {
  tags?: CommunityTag[];
  /** Статус пользователя в O!Community */
  status?: CommunityStatus;
}

/** Статус пользователя в O!Community */
export type CommunityStatus = {
  /** Код */
  code?: string;
  /**
   * Название
   * @example "o!beginner"
   */
  name?: string;
} | null;

/** Список привилегий для уровня статуса */
export type CommunityPrivilegeGroups = CommunityPrivilegeGroup[];

/** Объект привилегий для уровня статуса */
export interface CommunityPrivilegeGroup {
  /**
   * Заголовок группы привилегий
   * @example "Pop-up sale"
   */
  title?: string;
  /**
   * Описание группы привилегий
   * @example "Персональный поп-ап сейл ваших лотов в бутиках OSKELLY"
   */
  description?: string;
  /** Список привилегий для уровня статуса */
  privileges?: CommunityPrivilege[];
  /**
   * true - означает что группа привилегий пока недоступна
   * @example true
   */
  comingSoon?: boolean | null;
}

/** Наименование привилегии */
export interface CommunityPrivilege {
  /**
   * Заголовок класса привилегий
   * @example "Подарок на день рождения"
   */
  title?: string;
  /**
   * Доступна ли привилегия относительно предыдущего уровня
   * @example false
   */
  unlocked?: boolean | null;
  /**
   * true - означает что привилегия пока недоступна
   * @example true
   */
  comingSoon?: boolean | null;
}

/** Информация о статусах, привилегиях и требованиях для получения статуса */
export type CommunityInfos = CommunityInfo[];

/** Информация о статусе, привилегиях и требованиях для получения статуса */
export interface CommunityInfo {
  /** Статус пользователя в O!Community */
  status?: CommunityStatus;
  /** Список привилегий для уровня статуса */
  privilegeGroups?: CommunityPrivilegeGroups;
  /** Требования для получения статуса пользователя в O!Community */
  requirements?: CommunityStatusRequirement;
}

/** Количество покупок и продаж у текущего пользователя */
export interface CommunityIndicator {
  /**
   * Количество покупок у текущего пользователя
   * @example 2
   */
  purchaseCount?: number;
  /**
   * Количество продаж у текущего пользователя
   * @example 1
   */
  saleCount?: number;
}

/** Требования для получения статуса пользователя в O!Community */
export interface CommunityStatusRequirement {
  /**
   * Сумма покупок для получения статуса
   * @example 0
   */
  purchaseRequired?: number | null;
  /**
   * Сумма продаж для получения статуса
   * @example 0
   */
  saleRequired?: number | null;
  /** Тип требования и\или */
  type?: "AND" | "OR";
}

/** Бейджик пользователя в O!Community */
export type CommunityTag = {
  code?: string;
  /**
   * Значок пользователя
   * @example "stylist"
   */
  name?: string;
} | null;

export interface UserCommonTagGroupDTO {
  /** @format int64 */
  id?: number;
  code?: string;
  name?: string;
  /** @format int32 */
  order?: number;
  multiSelect?: boolean;
  tags?: UserCommonTagDTO[];
}

export interface UserCommonTagDTO {
  /** @format int64 */
  id?: number;
  code?: string;
  name?: string;
  extraData?: string;
  enabled?: boolean;
}

/** BrandDTO */
export interface BrandDTO {
  description?: string;
  /** @format int64 */
  id: number;
  isHidden?: boolean;
  isLiked?: boolean;
  name?: string;
  products?: ProductDTO[];
  /** @format int32 */
  productsCount?: number;
  transliterateName?: string;
  urlName?: string;
}

/** BrandDTORes */
export interface BrandDTORes {
  description?: string;
  /** @format int64 */
  id?: number;
  isHidden?: boolean;
  isLiked?: boolean;
  name?: string;
  products?: ProductDTORes[];
  /** @format int32 */
  productsCount?: number;
  transliterateName?: string;
  urlName?: string;
}

/** BrandDTOReq */
export interface BrandDTOReq {
  description?: string;
  /** @format int64 */
  id?: number;
  isHidden?: boolean;
  isLiked?: boolean;
  name?: string;
  products?: ProductDTOReq[];
  /** @format int32 */
  productsCount?: number;
  transliterateName?: string;
  urlName?: string;
}

/** ProductModelDTORes */
export interface ProductModelDTORes {
  /** @format int64 */
  id: number;
  name: string;
  /** @format int64 */
  brandId: number;
}

/** CategoryDTO */
export interface CategoryDTO {
  additionalSizes?: AdditionalSizeDTO[];
  attributes?: AttributeDTO[];
  children?: CategoryDTO[];
  defaultSizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  displayName?: string;
  fullName?: string;
  hasChildren?: boolean;
  icon?: string;
  /** @format int64 */
  id?: number;
  /** @format int32 */
  minPrice?: number;
  pluralName?: string;
  /** @format int32 */
  productsCount?: number;
  singularFullName?: string;
  singularName?: string;
  sizeValues?: SizeValueDTO[];
  url?: string;
}

/** CategoryDTORes */
export interface CategoryDTORes {
  additionalSizes?: AdditionalSizeDTO[];
  attributes?: AttributeDTO[];
  children?: CategoryDTORes[];
  defaultSizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  displayName?: string;
  fullName?: string;
  hasChildren?: boolean;
  icon?: string;
  /** @format int64 */
  id?: number;
  /** @format int32 */
  minPrice?: number;
  pluralName?: string;
  /** @format int32 */
  productsCount?: number;
  singularFullName?: string;
  singularName?: string;
  sizeValues?: SizeValueDTORes[];
  url?: string;
}

/** CategoryDTOReq */
export interface CategoryDTOReq {
  additionalSizes?: AdditionalSizeDTO[];
  attributes?: AttributeDTO[];
  children?: CategoryDTOReq[];
  defaultSizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  displayName?: string;
  fullName?: string;
  hasChildren?: boolean;
  icon?: string;
  /** @format int64 */
  id?: number;
  /** @format int32 */
  minPrice?: number;
  pluralName?: string;
  /** @format int32 */
  productsCount?: number;
  singularFullName?: string;
  singularName?: string;
  sizeValues?: SizeValueDTOReq[];
  url?: string;
}

/** CategoryTree */
export interface CategoryTree {
  rootCategory?: CategoryDTORes;
}

/** ProductDTOLite */
export interface ProductDTOLite {
  brand?: BrandDTO;
  category?: CategoryDTO;
  /** @format int64 */
  conditionId?: number;
  conditionName?: string;
  /** @format int64 */
  productId?: number;
  productState?:
    | "BANED"
    | "DELETED"
    | "DRAFT"
    | "HIDDEN"
    | "NEED_MODERATION"
    | "NEED_RETOUCH"
    | "PUBLISHED"
    | "REJECTED"
    | "RETOUCH_DONE"
    | "SECOND_EDITION"
    | "SOLD";
  url?: string;
}

/** ProductDTO */
export interface ProductDTO {
  attributeValueIds?: number[];
  attributeWithValues?: AttributeWithValueDTO[];
  attributes?: DescriptionAttributeView[];
  brand?: BrandDTO;
  /** @format int64 */
  brandId?: number;
  /** @format int64 */
  productModelId?: number;
  category?: CategoryDTO;
  /** @format int64 */
  categoryId?: number;
  /** @format date-time */
  changeTime?: string;
  /** @format int64 */
  changeTimestamp?: number;
  comments?: CommentView[];
  /** @format int32 */
  commentsCount?: number;
  /** @format bigdecimal */
  commissionProc?: number;
  /** @format int64 */
  conditionId?: number;
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  createTimestamp?: number;
  defectImages?: ProductImageDTO[];
  description?: string;
  /** @format int32 */
  discount?: number;
  fieldsLackingForModeration?: string[];
  sectionsLackingForModeration?: Record<string, string[]>;
  /** @format bigdecimal */
  higherPrice?: number;
  images?: ProductImageDTO[];
  isAtOffice?: boolean;
  isLiked?: boolean;
  isNewCollection?: boolean;
  isOurChoice?: boolean;
  isReadyForModeration?: boolean;
  isVintage?: boolean;
  isUsedInSaleRequest?: boolean;
  /** @format int32 */
  likesCount?: number;
  model?: string;
  /** @format int64 */
  moderationHoursRemains?: number;
  name?: string;
  origin?: string;
  /** @format date-time */
  ourChoiceStatusTime?: string;
  parentCategories?: CategoryDTO[];
  pickupAddressEndpoint?: AddressEndpointDTO;
  /** @format int64 */
  pickupAddressEndpointId?: number;
  pickupAddressEndpointAggregation?: AddressEndpointAggregationResponseDTO;
  /** @format int64 */
  pickupAddressEndpointAggregationId?: number;
  /** @format bigdecimal */
  price?: number;
  /** @format int32 */
  priceUpdateSubscribersCount?: number;
  /** @format bigdecimal */
  priceWithoutCommission?: number;
  primaryImageUrl?: string;
  /** @format int64 */
  productId?: number;
  productState?:
    | "BANED"
    | "DELETED"
    | "DRAFT"
    | "HIDDEN"
    | "NEED_MODERATION"
    | "NEED_RETOUCH"
    | "PUBLISHED"
    | "REJECTED"
    | "RETOUCH_DONE"
    | "SECOND_EDITION"
    | "SOLD";
  /** @format date-time */
  productStateTime?: string;
  /** @format int64 */
  productStateTimestamp?: number;
  productWasPublishedByNewPublisher?: boolean;
  /** @format date-time */
  publishTime?: string;
  /** @format int64 */
  publishTimestamp?: number;
  /** @format bigdecimal */
  purchasePrice?: number;
  /** @format int32 */
  purchaseYear?: number;
  rejectReason?: ProductRejectReasonDTO;
  /** @format bigdecimal */
  rrpPrice?: number;
  seller?: UserDTO;
  /** @format bigdecimal */
  sellerRecievesSum?: number;
  /** @format date-time */
  exclusiveSelectionTime?: string;
  /** @format date-time */
  exclusiveSelectionTimeForLowStatuses?: string;
  /** @format date-time */
  sendToModeratorTime?: string;
  /** @format int64 */
  sentToModeratorTimestamp?: number;
  serialNumber?: string;
  sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
  sizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  sizes?: SizeValueDTO[];
  /** @format bigdecimal */
  startPrice?: number;
  storeCode?: string;
  subscribedOnPriceUpdates?: boolean;
  url?: string;
  vendorCode?: string;
  split?: SplitInfo;
  tabbySplit?: SplitInfo;
  yandexPlus?: YandexPlusInfo;
}

/** ProductDTORes */
export interface ProductDTORes {
  attributeValueIds?: number[];
  attributeWithValues?: AttributeWithValueDTO[];
  attributes?: DescriptionAttributeView[];
  brand?: BrandDTORes;
  /** @format int64 */
  brandId?: number;
  breadcrumbs?: BreadcrumbDTO[];
  /** @format int64 */
  productModelId?: number;
  productModel?: ProductModelDTORes;
  category?: CategoryDTORes;
  /** @format int64 */
  categoryId?: number;
  /** @format date-time */
  exclusiveSelectionTime?: string;
  /** @format date-time */
  exclusiveSelectionTimeForLowStatuses?: string;
  /** @format date-time */
  changeTime?: string;
  /** @format int64 */
  changeTimestamp?: number;
  comments?: CommentView[];
  lastCommentsTree?: CommentDTO[];
  /** @format int32 */
  commentsCount?: number;
  commentPostMode?: "TEXT_AND_PHOTOS" | "TEXT" | "NONE";
  /** @format bigdecimal */
  commissionProc?: number;
  /** @format int64 */
  conditionId?: number;
  conditionName?: string;
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  createTimestamp?: number;
  defectImages?: ProductImageDTO[];
  description?: string;
  /** @format int32 */
  discount?: number;
  fieldsLackingForModeration?: string[];
  sectionsLackingForModeration?: Record<string, string[]>;
  /** @format bigdecimal */
  higherPrice?: number;
  images?: ProductImageDTO[];
  inBoutique?: boolean;
  isAtOffice?: boolean;
  isLiked?: boolean;
  needsTranslateDescription?: boolean;
  isNewCollection?: boolean;
  isOurChoice?: boolean;
  isReadyForModeration?: boolean;
  isVintage?: boolean;
  isSold?: boolean;
  isConcierge?: boolean;
  /** @format int32 */
  likesCount?: number;
  model?: string;
  /** @format int64 */
  moderationHoursRemains?: number;
  name?: string;
  origin?: string;
  /** @format date-time */
  ourChoiceStatusTime?: string;
  parentCategories?: CategoryDTORes[];
  pickupAddressEndpoint?: AddressEndpointDTO;
  /** @format int64 */
  pickupAddressEndpointId?: number;
  /** @format bigdecimal */
  price?: number;
  /** @format int32 */
  priceUpdateSubscribersCount?: number;
  /** @format bigdecimal */
  priceWithoutCommission?: number;
  /** @format bigdecimal */
  currentPriceInCurrency?: number;
  /** @format int64 */
  currentPriceCurrencyId?: number;
  primaryImageUrl?: string;
  /** @format int64 */
  productId?: number;
  productState?:
    | "BANED"
    | "DELETED"
    | "DRAFT"
    | "HIDDEN"
    | "NEED_MODERATION"
    | "NEED_RETOUCH"
    | "PUBLISHED"
    | "REJECTED"
    | "RETOUCH_DONE"
    | "SECOND_EDITION"
    | "SOLD";
  /** @format date-time */
  productStateTime?: string;
  /** @format int64 */
  productStateTimestamp?: number;
  productWasPublishedByNewPublisher?: boolean;
  /** @format date-time */
  publishTime?: string;
  /** @format int64 */
  publishTimestamp?: number;
  /** @format bigdecimal */
  purchasePrice?: number;
  /** @format int32 */
  purchaseYear?: number;
  rejectReason?: ProductRejectReasonDTO;
  /** @format bigdecimal */
  rrpPrice?: number;
  salesChannel?: "WEBSITE" | "BOUTIQUE_AND_WEBSITE" | "STOCK_AND_BOUTIQUE_AND_WEBSITE";
  seller?: UserDTO;
  /** @format bigdecimal */
  sellerRecievesSum?: number;
  /** @format date-time */
  sendToModeratorTime?: string;
  /** @format int64 */
  sentToModeratorTimestamp?: number;
  serialNumber?: string;
  sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
  sizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  sizes?: SizeValueDTORes[];
  /** @format bigdecimal */
  startPrice?: number;
  storeCode?: string;
  subscribedOnPriceUpdates?: boolean;
  url?: string;
  vendorCode?: string;
  sourceLink?: string;
  tags?: ProductTagDTO[];
  shortSeoDescription?: string;
  fullSeoDescription?: string;
}

/** ProductDTOIntegrationLiteRes */
export interface ProductDTOIntegrationLiteRes {
  /** @format int64 */
  brandId?: number;
  /** @format int64 */
  categoryId?: number;
  /** @format int64 */
  conditionId?: number;
  /** @format int64 */
  productId?: number;
  productState?:
    | "BANED"
    | "DELETED"
    | "DRAFT"
    | "HIDDEN"
    | "NEED_MODERATION"
    | "NEED_RETOUCH"
    | "PUBLISHED"
    | "REJECTED"
    | "RETOUCH_DONE"
    | "SECOND_EDITION"
    | "SOLD";
  storeCode?: string;
}

/** ProductDTOReq */
export interface ProductDTOReq {
  attributeValueIds?: number[];
  attributeWithValues?: AttributeWithValueDTO[];
  attributes?: DescriptionAttributeView[];
  brand?: BrandDTOReq;
  /** @format int64 */
  brandId?: number;
  /** @format int64 */
  productModelId?: number;
  productModel?: ProductModelDTORes;
  category?: CategoryDTOReq;
  /** @format int64 */
  categoryId?: number;
  /** @format date-time */
  changeTime?: string;
  /** @format int64 */
  changeTimestamp?: number;
  comments?: CommentView[];
  /** @format int32 */
  commentsCount?: number;
  /** @format bigdecimal */
  commissionProc?: number;
  /** @format int64 */
  conditionId?: number;
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  createTimestamp?: number;
  defectImages?: ProductImageDTO[];
  description?: string;
  /** @format int32 */
  discount?: number;
  fieldsLackingForModeration?: string[];
  sectionsLackingForModeration?: Record<string, string[]>;
  /** @format bigdecimal */
  higherPrice?: number;
  images?: ProductImageDTO[];
  isAtOffice?: boolean;
  isLiked?: boolean;
  isNewCollection?: boolean;
  isOurChoice?: boolean;
  isReadyForModeration?: boolean;
  isVintage?: boolean;
  isConcierge?: boolean;
  /** @format int32 */
  likesCount?: number;
  model?: string;
  /** @format int64 */
  moderationHoursRemains?: number;
  name?: string;
  origin?: string;
  /** @format date-time */
  ourChoiceStatusTime?: string;
  parentCategories?: CategoryDTOReq[];
  pickupAddressEndpoint?: AddressEndpointDTO;
  /** @format int64 */
  pickupAddressEndpointId?: number;
  /** @format bigdecimal */
  price?: number;
  /** @format int32 */
  priceUpdateSubscribersCount?: number;
  /** @format bigdecimal */
  priceWithoutCommission?: number;
  /** @format bigdecimal */
  currentPriceInCurrency?: number;
  /** @format int64 */
  currentPriceCurrencyId?: number;
  /** @format int64 */
  productId?: number;
  productState?:
    | "BANED"
    | "DELETED"
    | "DRAFT"
    | "HIDDEN"
    | "NEED_MODERATION"
    | "NEED_RETOUCH"
    | "PUBLISHED"
    | "REJECTED"
    | "RETOUCH_DONE"
    | "SECOND_EDITION"
    | "SOLD";
  /** @format date-time */
  productStateTime?: string;
  /** @format int64 */
  productStateTimestamp?: number;
  productWasPublishedByNewPublisher?: boolean;
  /** @format date-time */
  publishTime?: string;
  /** @format int64 */
  publishTimestamp?: number;
  /** @format bigdecimal */
  purchasePrice?: number;
  /** @format int32 */
  purchaseYear?: number;
  rejectReason?: ProductRejectReasonDTO;
  /** @format bigdecimal */
  rrpPrice?: number;
  seller?: UserDTO;
  /** @format bigdecimal */
  sellerRecievesSum?: number;
  /** @format date-time */
  sendToModeratorTime?: string;
  /** @format int64 */
  sentToModeratorTimestamp?: number;
  serialNumber?: string;
  sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
  sizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  sizes?: SizeValueDTOReq[];
  /** @format bigdecimal */
  startPrice?: number;
  storeCode?: string;
  subscribedOnPriceUpdates?: boolean;
  url?: string;
  vendorCode?: string;
  sourceLink?: string;
}

/** ProductImageDTO */
export interface ProductImageDTO {
  comment?: string;
  /** @format int64 */
  id?: number;
  /** @format int32 */
  order?: number;
  path?: string;
  alt?: string;
}

/** ProductRejectReasonDTO */
export interface ProductRejectReasonDTO {
  descriptionComment?: string;
  /** @format int64 */
  id?: number;
  imageComment?: string;
  images?: ProductImageDTO[];
  oldDescription?: string;
  /** @format bigdecimal */
  oldPrice?: number;
  otherComment?: string;
  /** @format bigdecimal */
  price?: number;
  priceComment?: string;
  /** @format int64 */
  rejectorId?: number;
  /** @format int64 */
  timestamp?: number;
}

/** PageOfProductDTO */
export interface PageOfProductDTO {
  items?: ProductDTORes[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** PageOfProductDTOIntegrationLite */
export interface PageOfProductDTOIntegrationLite {
  items?: ProductDTOIntegrationLiteRes[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

export enum CounterpartyType {
  PHYS = "PHYS",
  IP = "IP",
  JUR = "JUR",
  CARD = "CARD",
}

export interface AdminCardDetailsView {
  brand?: string;
  bank?: string;
  number?: string;
  expiration?: string;
}

/** PrimaryPageType */
export enum PrimaryPageType {
  MALE = "MALE",
  FEMALE = "FEMALE",
  CHILD = "CHILD",
  LIFESTYLE = "LIFESTYLE",
}

/** PageOfProductRequestDTO */
export interface PageOfProductRequestDTO {
  items?: ProductRequestDTO[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** ProductRequestDTO */
export interface ProductRequestDTO {
  attributes?: AttributeDTO[];
  brands?: BrandDTO[];
  category?: CategoryDTO;
  /** @format int32 */
  commentsCount?: number;
  conditions?: ProductConditionDTO[];
  /** @format date-time */
  createTime?: string;
  currencyCode?: string;
  description?: string;
  fromPrice?: number;
  /** @format int64 */
  id?: number;
  images?: ProductRequestImageDTO[];
  isResponded?: boolean;
  lastCommentsTree?: CommentDTO[];
  like?: Like;
  parentCategory?: CategoryDTO;
  productModels?: ProductModelDTORes[];
  /** @format double */
  progress?: number;
  /** @format int32 */
  responseCount?: number;
  sharingLink?: string;
  sizeType?: SizeTypeLocalized;
  sizes?: SizeValueDTO[];
  state?: "DRAFT" | "PUBLISHED" | "HIDDEN" | "MODERATION" | "REJECTED";
  /** @format date-time */
  stateTime?: string;
  title?: string;
  toPrice?: number;
  user?: UserDTO;
  needsTranslateDescription?: boolean;
}

/** ProductConditionDTO */
export interface ProductConditionDTO {
  /** @format int64 */
  id?: number;
  name?: string;
  description?: string;
}

/** SizeTypeLocalized */
export interface SizeTypeLocalized {
  abbreviation?: string;
  description?: string;
  sizeType?:
    | "RU"
    | "EU"
    | "US"
    | "INT"
    | "UK"
    | "FR"
    | "IT"
    | "DE"
    | "AU"
    | "JPN"
    | "INCHES"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "RING_RUSSIAN"
    | "RING_EUROPEAN"
    | "JEANS"
    | "HEIGHT"
    | "AGE"
    | "NO_SIZE"
    | "BUST";
}

/** Like */
export interface Like {
  /** @format int64 */
  count?: number;
  isLiked?: boolean;
}

/** ProductRequestImageDTO */
export interface ProductRequestImageDTO {
  extension?: string;
  fileName?: string;
  imageURL?: string;
  isPrimary?: boolean;
}

export interface SplitInfoPart {
  /** @format offset-date-time */
  date?: string;
  /** @format bigdecimal */
  value?: number;
}

export interface SplitInfo {
  firstPayment: number;
  remainingPayment: number;
  parts: SplitInfoPart[];
}

export interface YandexPlusInfo {
  /** Количество баллов кэшбэка за товар/заказ */
  points: number;
}

export interface CardOption {
  /** @format int64 */
  id?: number;
  title?: string;
  icon?: string;
}

/** PaymentOption */
export interface PaymentOption {
  type: string;
  title: string;
  icon?: string;
}

export type YandexSplitPaymentOption = PaymentOption & {
  firstPayment: number;
  remainingPayment: number;
  parts: SplitInfoPart[];
};

export type SplitPaymentOption = PaymentOption & {
  firstPayment: number;
  remainingPayment: number;
  parts: SplitInfoPart[];
  description?: string;
};

export type CardPaymentOption = PaymentOption & {
  cards?: CardOption[];
};

export type YandexPayPaymentOption = PaymentOption & {
  yandexPlus: YandexPlusInfo;
};

export interface ProductTagDTO {
  /** @format int64 */
  id: number;
  category: ProductTagCategory;
  shortName: string;
  name: string;
  description?: string;
  bannerInfo?: {
    title?: string;
    description?: string;
  };
}

export enum ProductTagCategory {
  LOCATION = "LOCATION",
}

export enum ProductTagLocationType {
  BOUTIQUE = "BOUTIQUE",
}

/** PageOfOrderDTO */
export interface PageOfOrderDTO {
  items?: OrderDTO1[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** OrderDTO */
export interface OrderDTO {
  adminComment?: string;
  agentReport?: AgentReportDTO;
  agentReportConfirmed?: boolean;
  /** @format int64 */
  agentReportId?: number;
  buyerCounterparty?: CounterpartyDTO;
  clearAmount?: number;
  comment?: string;
  conciergeClientChannel?: any;
  confirmedAmount?: number;
  /** @format int32 */
  count?: number;
  counterpartyRequestType?: "ORDER_CONFIRMATION" | "AGENT_REPORT_CONFIRMATION" | "ORDER_AND_AGENT_REPORT_CONFIRMATION";
  deletable?: boolean;
  deliveryAddressEndpoint?: AddressEndpointDTO;
  deliveryAddressEndpointAggregation?: any;
  deliveryComment?: string;
  deliveryCost?: number;
  deliveryDescription?: string;
  deliveryIcon?: string;
  deliveryInfo?: string;
  deliveryTitle?: string;
  /** @format date-time */
  deliveryToBuyerDateHint?: string;
  discount?: Discount;
  duties?: any[];
  dutiesAmount?: number;
  empty?: boolean;
  faulty?: boolean;
  finalAmount?: number;
  finalAmountWithoutDeliveryCost?: number;
  /** @format int64 */
  id?: number;
  isMadeByNewUser?: boolean;
  items?: OrderPositionDTO[];
  linkedNotification?: NotificationDTO;
  orderCreationProblems?: OrderCreationProblemDTO[];
  orderSource?: "ONLINE" | "BOUTIQUE";
  orderSourceInfo?: OrderSourceInfoDTO;
  orderStateIcon?: string;
  orderStateSuccess?: boolean;
  orderStateTitle?: string;
  orderStatus?:
    | "UNDEFINED"
    | "UNCOMPLETED"
    | "ORDER_CANT_CONFIRM_NO_SELLER_ADDRESS"
    | "ORDER_CONFIRMING"
    | "ORDER_REFUND"
    | "ORDER_CONFIRMED"
    | "CONCIERGE_ITEMS_WAITING_CONFIRMATION"
    | "SELLER_IN_MOSCOW"
    | "EXPECTING_COURIER_TO_SELLER"
    | "OURSELVES_PICKING_UP_FROM_SELLER"
    | "OURSELVES_FROM_SELLER_TO_OFFICE"
    | "LOGIST_ON_WAY_TO_SELLER"
    | "FROM_SELLER_TO_OFFICE"
    | "HAS_CONCIERGE_ITEMS"
    | "EXPERTISE_START"
    | "EXPERTISE_COMPLETED"
    | "CHOOSING_DELIVERY_METHOD_O2B"
    | "HOLD_COMPLETE_REJECTED"
    | "EXPECTING_COURIER_TO_BUYER"
    | "LOGIST_ON_WAY_TO_BUYER"
    | "BUYER_IN_MOSCOW"
    | "OURSELVES_DELIVERY_TO_BUYER"
    | "OURSELVES_FROM_OFFICE_TO_BUYER"
    | "ORDER_DELIVERED"
    | "HAS_DISPUTE"
    | "ORDER_IN_BOUTIQUE"
    | "ORDER_SOLD_IN_BOUTIQUE"
    | "EXPECTING_CONFIRM_AGENT_REPORT"
    | "WAIT_PAYMENT_MONEY_TO_SELLER"
    | "ORDER_COMPLETED"
    | "ORDER_COMPLETED_RETURN"
    | "RETURN_CREATED"
    | "RETURN_ON_WAY_TO_OFFICE"
    | "RETURN_EXPERTISE"
    | "RETURN_COMPLETED"
    | "BOUTIQUE_ORDER_ON_WAY_TO_OFFICE"
    | "BOUTIQUE_ORDER_ON_EXPERTISE"
    | "BOUTIQUE_ORDER_ON_WAY_TO_BOUTIQUE"
    | "BOUTIQUE_ORDER_IN_BOUTIQUE"
    | "BOUTIQUE_ORDER_SOLD_IN_BOUTIQUE"
    | "BOUTIQUE_ORDER_ONLINE_CONFIRM"
    | "BOUTIQUE_ORDER_ONLINE_PICKUP";
  orderStatusTitle?: string;
  orderStepChain?: OrderStepChain;
  ourselvesDeliveries?: OurselvesDeliveryDTO[];
  ourselvesDelivery?: boolean;
  ourselvesDeliveryFromSeller?: OurselvesDeliveryDTO;
  ourselvesDeliveryName?: string;
  ourselvesDeliveryPhone?: string;
  ourselvesDeliveryToBuyer?: OurselvesDeliveryDTO;
  pickupAddressEndpoint?: AddressEndpointDTO;
  pickupAddressEndpointAggregation?: any;
  pickupComment?: string;
  pickupCountry?: CountryDTO;
  /** @format int64 */
  pickupDateFromSeller?: number;
  /** @format int64 */
  pickupDateToBuyer?: number;
  pickupIntervalFromSeller?: string;
  /** @format int64 */
  pickupTimeIntervalId?: number;
  productLocation?: "SELLER" | "BOUTIQUE";
  rrpSum?: number;
  seller?: UserDTO;
  tabbySplit?: SplitInfo;
  sellerConciergeOrder?: boolean;
  counterpartyRequiredOnConfirmation?: boolean;
  counterpartyModificationAllowed?: boolean;
  sellerCounterparty?: CounterpartyDTO;
  sellerReceivesAmount?: number;
  /** @format int32 */
  size?: number;
  state?:
    | "CREATED"
    | "CANCELED"
    | "HOLD_PROCESSING"
    | "HOLD_ERROR"
    | "HOLD"
    | "HOLD_COMPLETED"
    | "HOLD_COMPLETE_REJECTED"
    | "REFUND"
    | "MONEY_TRANSFERRED"
    | "MONEY_PAYMENT_ERROR"
    | "MONEY_PAYMENT_NOT_ENOUGH"
    | "MONEY_PAYMENT_TECHNICAL_ERROR"
    | "MONEY_PAYMENT_WAIT"
    | "SELLER_PAID"
    | "COMPLETED"
    | "RETURN"
    | "DELETED";
  /** @format int64 */
  stateTime?: number;
  trackingUrl?: string;
  vatIncluded?: boolean;
  waybillFromSeller?: WaybillDTO;
  waybillId?: string;
  waybillToBuyer?: WaybillDTO;
  waybills?: WaybillDTO[];
}

/** OrderSourceInfoDTO */
export interface OrderSourceInfoDTO {
  /** @format int64 */
  id?: number;
  type?: "WEB" | "ANDROID" | "IOS" | "ANDROID_WEB" | "IOS_WEB" | "BOUTIQUE" | "UNKNOWN";
  name?: string;
  displayName?: string;
  isHidden?: boolean;
  isMarketplaceLocation?: boolean;
}

/** LegalEntityDTO */
export interface LegalEntityDTO {
  /** @format int64 */
  id?: number;
  onecUuid?: string;
  title?: string;
}

export interface DictionaryDTO {
  /** @format int64 */
  id?: number;
  name?: string;
}

/** Api2ResponseOfPageOfOrderDTO */
export interface Api2ResponseOfPageOfOrderDTO {
  data?: PageOfOrderDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

export interface PromocodeTypeDTO {
  /** @format int32 */
  id: number;
  name?: string;
}

/** Api2ResponseOfListOfPromocodeTypeDTO */
export interface Api2ResponseOfListOfPromocodeTypeDTO {
  data?: PromocodeTypeDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfProductConditionDTO */
export interface Api2ResponseOfListOfProductConditionDTO {
  data?: ProductConditionDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfAddressEndpointDTO */
export interface Api2ResponseOfListOfAddressEndpointDTO {
  data?: AddressEndpointDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

export interface PromocodeRuleLinkInfo {
  /**
   * Link item ID
   * @format int32
   */
  id: number;
  /** Link item name */
  name?: string;
}

export interface PromocodeFilterInfo {
  /**
   * Expected value
   * @format int32
   */
  value: number;
  /** Relation to expected value */
  relation?: "EQUALS" | "EQUALS_OR_LESS" | "LESS" | "EQUALS_OR_GREATER" | "GREATER";
}

export interface Api2ResponsePromocode {
  data: AdminPanelPromocode;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  message?: string;
}

export interface Api2ResponsePromocodes {
  data: Page;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  message?: string;
}

export interface Page {
  items?: AdminPanelPromocode[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

export interface AdminPanelPromocodeBase {
  /** Promocode name */
  code: string;
  /** Promocode description */
  description?: string;
  /**
   * Active from price
   * @format double
   */
  percent?: number;
  /**
   * Active from price
   * @format double
   */
  amount?: number;
  /**
   * Active from price
   * @format double
   */
  beginPrice?: number;
  /** @format date-time */
  startsAt?: string;
  /** @format date-time */
  expiresAt?: string;
  oneTimePromoCode?: boolean;
  type?: PromocodeTypeDTO;
  barter?: boolean;
  brandsList?: PromocodeRuleLinkInfo[];
  categoriesList?: PromocodeRuleLinkInfo[];
  sellersList?: PromocodeRuleLinkInfo[];
  exceptSellersList?: PromocodeRuleLinkInfo[];
  buyersList?: PromocodeRuleLinkInfo[];
  ordersCountFilters?: PromocodeFilterInfo[];
  orderAmountFilters?: PromocodeFilterInfo[];
  ordersAmountSummaryFilters?: PromocodeFilterInfo[];
}

export type AdminPanelPromocode = AdminPanelPromocodeBase & {
  /**
   * Promocode ID
   * @format int32
   */
  id: number;
  /** @format int32 */
  dealsCount?: number;
  createdBy?: UserDTO;
};

/** Api2ResponseOfListOfSizeTypeDTO */
export interface Api2ResponseOfListOfSizeTypeDTO {
  data?: SizeTypeDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfSizeTree */
export interface Api2ResponseOfSizeTree {
  data?: SizeTree;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOflong */
export interface Api2ResponseOfPageOflong {
  data?: PageOflong;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfToggleResult */
export interface Api2ResponseOfToggleResult {
  data?: ToggleResult;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfBoolean */
export interface Api2ResponseOfBoolean {
  data?: boolean;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOfBrandDTO */
export interface Api2ResponseOfPageOfBrandDTO {
  data?: PageOfBrandDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** PageOfBrandDTO */
export interface PageOfBrandDTO {
  items?: BrandDTO[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** Api2ResponseOfBrandDTO */
export interface Api2ResponseOfBrandDTO {
  data?: BrandDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** SizeTree */
export interface SizeTree {
  rootCategory?: CategoryDTO;
}

/** SizeTypeDTO */
export interface SizeTypeDTO {
  isDefault?: boolean;
  sizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  sizeTypeAbbreviation?: string;
  sizeTypeDescription?: string;
  values?: SizeDTO[];
}

/** PageOflong */
export interface PageOflong {
  items?: number[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** ToggleResult */
export interface ToggleResult {
  /** @format int32 */
  actualLikesCount?: number;
  canBeLiked?: boolean;
}

/** SizeDTO */
export interface SizeDTO {
  /** @format int64 */
  id?: number;
  name?: string;
  optionalValuesForAllSizeTypes?: string;
}

/** Api2ResponseOfAvailableFilters */
export interface Api2ResponseOfAvailableFilters {
  data?: AvailableFilters;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfAttributeTree */
export interface Api2ResponseOfAttributeTree {
  data?: AttributeTree;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** AvailableFilters */
export interface AvailableFilters {
  atOffice?: boolean;
  attributeTree?: AttributeTree;
  brand?: number[];
  productModel?: ProductModelDTORes[];
  category?: number[];
  categoryTree?: CategoryTree;
  /** @format bigdecimal */
  endPrice?: number;
  filter?: number[];
  hasOurChoice?: boolean;
  newCollection?: boolean;
  notPro?: boolean;
  onSale?: boolean;
  pro?: boolean;
  productCondition?: number[];
  /** @format int64 */
  productCount?: number;
  size?: number[];
  sizeTree?: SizeTree;
  /** @format bigdecimal */
  startPrice?: number;
  startPriceHigher?: boolean;
  vintage?: boolean;
}

/** AttributeTree */
export interface AttributeTree {
  rootCategory?: CategoryDTO;
}

/** ProductsRequest */
export interface ProductsRequest {
  attributeTreeKeyword?: string;
  /** @format int32 */
  attributeTreeLevelsLimit?: number;
  categoriesIds?: number[];
  currencyCode?: string;
  endPrice?: number;
  exceptCategoriesIds?: number[];
  hasOurChoice?: boolean;
  hasSlides?: boolean;
  interestingAttributeValues?: number[];
  interestingBrands?: number[];
  interestingConditions?: number[];
  interestingProductModels?: number[];
  interestingSizeType?:
    | "RU"
    | "EU"
    | "US"
    | "INT"
    | "UK"
    | "FR"
    | "IT"
    | "DE"
    | "AU"
    | "JPN"
    | "INCHES"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "RING_RUSSIAN"
    | "RING_EUROPEAN"
    | "JEANS"
    | "HEIGHT"
    | "AGE"
    | "NO_SIZE"
    | "BUST";
  interestingSizeTypes?: (
    | "RU"
    | "EU"
    | "US"
    | "INT"
    | "UK"
    | "FR"
    | "IT"
    | "DE"
    | "AU"
    | "JPN"
    | "INCHES"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "RING_RUSSIAN"
    | "RING_EUROPEAN"
    | "JEANS"
    | "HEIGHT"
    | "AGE"
    | "NO_SIZE"
    | "BUST"
  )[];
  interestingSizes?: number[];
  isAtOffice?: boolean;
  isDescriptionExists?: boolean;
  isInBoutique?: boolean;
  isNewCollection?: boolean;
  isOnSale?: boolean;
  isPro?: boolean;
  /** @format int64 */
  isPublishedAfterTimestamp?: number;
  /** @format int64 */
  isPublishedBeforeTimestamp?: number;
  isStartPriceHigher?: boolean;
  isStreetwear?: boolean;
  isVintage?: boolean;
  isVip?: boolean;
  order?: string;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  pageLength?: number;
  productsIds?: number[];
  /** @format int64 */
  retoucherId?: number;
  sellerEmailSubstring?: string;
  /** @format int64 */
  sellerId?: number;
  sellerIds?: number[];
  sex?: "MALE" | "FEMALE" | "BOY" | "GIRL" | "ADULT" | "CHILD";
  sortAttribute?:
    | "ID"
    | "ID_DESC"
    | "PRICE"
    | "PRICE_DESC"
    | "PUBLISH_TIME_DESC"
    | "PROMOTION_TIME_DESC"
    | "PUBLISH_TIME"
    | "PRODUCT_STATE_TIME_DESC"
    | "PRODUCT_STATE_TIME"
    | "CHANGE_TIME_DESC"
    | "CHANGE_TIME"
    | "DISCOUNT_DESC"
    | "DISCOUNT"
    | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC";
  startPrice?: number;
  state?:
    | "DRAFT"
    | "SECOND_EDITION"
    | "NEED_MODERATION"
    | "NEED_RETOUCH"
    | "RETOUCH_DONE"
    | "REJECTED"
    | "PUBLISHED"
    | "HIDDEN"
    | "SOLD"
    | "DELETED"
    | "BANED";
  storeCodeContains?: string;
  useUserSex?: boolean;
  userLikedBrands?: boolean;
  vendorCodeContains?: string;
  viewQualification?: ViewQualification;
  withAttributeTree?: boolean;
  withCategoryTree?: boolean;
  withRrp?: boolean;
  withSizeTree?: boolean;
}

/** BrandsRequest */
export interface BrandsRequest {
  attributeTreeKeyword?: string;
  /** @format int32 */
  attributeTreeLevelsLimit?: number;
  categoriesIds?: number[];
  currencyCode?: string;
  endPrice?: number;
  exceptCategoriesIds?: number[];
  hasOurChoice?: boolean;
  hasSlides?: boolean;
  interestingAttributeValues?: number[];
  interestingBrands?: number[];
  interestingConditions?: number[];
  interestingProductModels?: number[];
  interestingSizeType?:
    | "RU"
    | "EU"
    | "US"
    | "INT"
    | "UK"
    | "FR"
    | "IT"
    | "DE"
    | "AU"
    | "JPN"
    | "INCHES"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "RING_RUSSIAN"
    | "RING_EUROPEAN"
    | "JEANS"
    | "HEIGHT"
    | "AGE"
    | "NO_SIZE"
    | "BUST";
  interestingSizeTypes?: (
    | "RU"
    | "EU"
    | "US"
    | "INT"
    | "UK"
    | "FR"
    | "IT"
    | "DE"
    | "AU"
    | "JPN"
    | "INCHES"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "RING_RUSSIAN"
    | "RING_EUROPEAN"
    | "JEANS"
    | "HEIGHT"
    | "AGE"
    | "NO_SIZE"
    | "BUST"
  )[];
  interestingSizes?: number[];
  isAtOffice?: boolean;
  isDescriptionExists?: boolean;
  isInBoutique?: boolean;
  isNewCollection?: boolean;
  isOnSale?: boolean;
  isPro?: boolean;
  /** @format int64 */
  isPublishedAfterTimestamp?: number;
  /** @format int64 */
  isPublishedBeforeTimestamp?: number;
  isStartPriceHigher?: boolean;
  isStreetwear?: boolean;
  isVintage?: boolean;
  isVip?: boolean;
  order?: string;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  pageLength?: number;
  /** @format int32 */
  productsCount?: number;
  productsIds?: number[];
  /** @format int64 */
  retoucherId?: number;
  sellerEmailSubstring?: string;
  /** @format int64 */
  sellerId?: number;
  sellerIds?: number[];
  sex?: "MALE" | "FEMALE" | "BOY" | "GIRL" | "ADULT" | "CHILD";
  sortAttribute?:
    | "ID"
    | "ID_DESC"
    | "PRICE"
    | "PRICE_DESC"
    | "PUBLISH_TIME_DESC"
    | "PROMOTION_TIME_DESC"
    | "PUBLISH_TIME"
    | "PRODUCT_STATE_TIME_DESC"
    | "PRODUCT_STATE_TIME"
    | "CHANGE_TIME_DESC"
    | "CHANGE_TIME"
    | "DISCOUNT_DESC"
    | "DISCOUNT"
    | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC";
  startPrice?: number;
  state?:
    | "DRAFT"
    | "SECOND_EDITION"
    | "NEED_MODERATION"
    | "NEED_RETOUCH"
    | "RETOUCH_DONE"
    | "REJECTED"
    | "PUBLISHED"
    | "HIDDEN"
    | "SOLD"
    | "DELETED"
    | "BANED";
  storeCodeContains?: string;
  useUserSex?: boolean;
  userLikedBrands?: boolean;
  vendorCodeContains?: string;
  viewQualification?: ViewQualification;
  withAttributeTree?: boolean;
  withCategoryTree?: boolean;
  withDescription?: boolean;
  withProducts?: boolean;
  withRrp?: boolean;
  withSizeTree?: boolean;
}

/** ViewQualification */
export type ViewQualification = object;

export interface Api2Response {
  data: Page1;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

export interface Page1 {
  items?: AdminPanelOrder[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** Информация о заказе для панели администратора */
export interface AdminPanelOrder {
  /**
   * Сумма дохода
   * @format double
   */
  amount: number;
  /**
   * Сумма без учета доставки
   * @format double
   */
  amountWithoutDelivery: number;
  /**
   * Сумма без учета промокода и доставки
   * @format double
   */
  amountWithoutPromoAndWithoutDelivery: number;
  /** Информация о пользователе в список заказов (покупатель / продавец) */
  buyerInfo: AdminPanelOrderUser;
  /**
   * Доход компании
   * @format double
   */
  companyProfit: number;
  /**
   * Количество дней после оплаты
   * @format int64
   */
  holdPeriod?: number;
  /**
   * ID Заказа
   * @format int64
   */
  id: number;
  /**
   * Дата обновления
   * @format date-time
   */
  lastUpdateDateTime: string;
  /** Промокод */
  promoCode: string;
  /**
   * Процент скидки по промокод
   * @format double
   */
  promoPercent?: number;
  /**
   * Скидка по промокоду
   * @format double
   */
  promoValue?: number;
  /** Информация о пользователе в список заказов (покупатель / продавец) */
  sellerInfo: AdminPanelOrderUser;
  /**
   * Доход продавца
   * @format double
   */
  sellerProfit: number;
  /** Статус заказа */
  status:
    | "BUYER_IN_MOSCOW"
    | "EXPECTING_CONFIRM_AGENT_REPORT"
    | "EXPECTING_COURIER_TO_BUYER"
    | "EXPECTING_COURIER_TO_SELLER"
    | "EXPERTISE_START"
    | "FROM_SELLER_TO_OFFICE"
    | "HOLD_COMPLETE_REJECTED"
    | "LOGIST_ON_WAY_TO_BUYER"
    | "LOGIST_ON_WAY_TO_SELLER"
    | "ORDER_CANT_CONFIRM_NO_SELLER_ADDRESS"
    | "ORDER_COMPLETED"
    | "ORDER_COMPLETED_RETURN"
    | "ORDER_CONFIRMED"
    | "ORDER_CONFIRMING"
    | "ORDER_DELIVERED"
    | "ORDER_REFUND"
    | "OURSELVES_DELIVERY_TO_BUYER"
    | "OURSELVES_FROM_OFFICE_TO_BUYER"
    | "OURSELVES_FROM_SELLER_TO_OFFICE"
    | "OURSELVES_PICKING_UP_FROM_SELLER"
    | "RETURN_COMPLETED"
    | "RETURN_CREATED"
    | "RETURN_EXPERTISE"
    | "RETURN_ON_WAY_TO_OFFICE"
    | "SELLER_IN_MOSCOW"
    | "UNCOMPLETED"
    | "UNDEFINED"
    | "WAIT_PAYMENT_MONEY_TO_SELLER";
  /** Тип продавца */
  typeSeller: "ALL" | "BOUTIQUE" | "SIMPLE_SELLER";
}

/** Информация о пользователе в список заказов (покупатель / продавец) */
export interface AdminPanelOrderUser {
  /** ФИО */
  fullName: string;
  /** Адрес электроной почты */
  mailAddr: string;
  /** Псевдоним */
  nickName: string;
}

/** ProStatusRequest */
export interface ProStatusRequest {
  name?: string;
  phone: string;
  description?: string;
}

/** AccountCredsDTO */
export interface AccountCredsDTO {
  apiHashedPassword?: string;
  email?: string;
  hashedPassword?: string;
  /** @format int64 */
  id?: number;
  nickname?: string;
}

/** AccountDTOReq */
export interface AccountDTOReq {
  addressEndpoints?: AddressEndpointDTO[];
  avatarPath?: string;
  /** @format int64 */
  birthDate?: number;
  /** @format int32 */
  brandLikesCount?: number;
  chatToken?: string;
  counterparties?: CounterpartyDTO[];
  email?: string;
  firstChar?: string;
  fullName?: string;
  /** @format int64 */
  id?: number;
  isFollowed?: boolean;
  isNewUser?: boolean;
  isPro?: boolean;
  isTrusted?: boolean;
  /** @format int32 */
  likesCount?: number;
  name?: string;
  nickname?: string;
  phone?: string;
  /** @format int32 */
  productLikesCount?: number;
  /** @format int32 */
  productsCount?: number;
  /** @format int64 */
  registrationTime?: number;
  sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
  userBanDTOList?: UserBanDTOReq[];
}

/** AccountDTORes */
export interface AccountDTORes {
  addressEndpoints?: AddressEndpointDTO[];
  avatarPath?: string;
  /** @format int64 */
  birthDate?: number;
  /** @format int32 */
  brandLikesCount?: number;
  canPublishMultiSizes?: boolean;
  chatToken?: string;
  counterparties?: CounterpartyDTO[];
  email?: string;
  firstChar?: string;
  fullName?: string;
  /** @format int64 */
  id?: number;
  isFollowed?: boolean;
  isNewUser?: boolean;
  isPro?: boolean;
  isTrusted?: boolean;
  isPhoneVerificationRequired?: boolean;
  syncAgree?: boolean;
  syncSuccess?: boolean;
  /** @format int32 */
  likesCount?: number;
  name?: string;
  nickname?: string;
  phone?: string;
  /** @format int32 */
  productLikesCount?: number;
  /** @format int32 */
  productsCount?: number;
  /** @format int64 */
  registrationTime?: number;
  sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
  userBanDTOList?: UserBanDTORes[];
  /** @format int32 */
  cartItemsCount?: number;
  /** Бейджики пользователя в O!Community */
  communityBadge?: CommunityBadge;
}

/** AddressValidatedDTO */
export interface AddressValidatedDTO {
  /** @format int64 */
  addressId?: number;
  dadataFullAddress?: string;
  fiasId?: string;
  isAddressValidated?: boolean;
  /** @format date-time */
  lastAddressValidationTime?: string;
  suggestedAddress?: string;
  settlementFiasId?: string;
  cityFiasId?: string;
}

/**
 * AdminBargainDetailed
 * Полная информация о контрторге для админки
 */
export interface AdminBargainDetailed {
  /**
   * Сколько попыток осталось
   * @format int32
   */
  attemptsLeft: number;
  /**
   * Первоначальная цена на момент создания контрторга
   * @format int32
   */
  basePrice: number;
  /** Покупатель */
  buyer: AdminBargainUser;
  /**
   * Дата обновления
   * @format date-time
   */
  changeTime?: string;
  /**
   * Дата создания
   * @format date-time
   */
  createTime: string;
  /**
   * Разница в цене (скидка абсолютная). Положительное значение
   * @format int32
   */
  discount?: number;
  /**
   * Скидка в %
   * @format int32
   */
  discountProc?: number;
  /**
   * Идентификатор контрторга
   * @format int64
   */
  id: number;
  /**
   * Последняя предложенная цена
   * @format int32
   */
  lastPrice: number;
  /** Сопроводительное сообщение для пользователя */
  message?: BargainMessageDTO;
  /** Товар */
  product: AdminBargainProduct;
  /** Записи по контрторгу */
  records: BargainRecord[];
  /** Продавец */
  seller: AdminBargainUser;
  /**
   * Сумма, которую получит продавец, если сделка состоится
   * @format int32
   */
  sellerReceivesSum?: number;
  /** Размер */
  size: BargainSize;
  /** Статус контрторга */
  state: BargainState;
  /**
   * Сколько времени осталось до принятия решения покупателем или продавцом (секунды)
   * @format int64
   */
  timeLeft?: number;
  /** Действия, которые может выполнить текущий пользователь (продавец или покупатель) */
  userCan: ("ACCEPT" | "CREATION" | "DECLINE" | "OFFER")[];
  /**
   * Если текущий пользователь является покупателем, то это поле имеет значение true
   * @example false
   */
  userIsBuyer: boolean;
}

/**
 * AdminBargainLite
 * Усеченная информация о контрторге для списка в админке
 */
export interface AdminBargainLite {
  /**
   * Сколько попыток осталось
   * @format int32
   */
  attemptsLeft: number;
  /**
   * Первоначальная цена на момент создания контрторга
   * @format int32
   */
  basePrice: number;
  /** Покупатель */
  buyer: AdminBargainUser;
  /**
   * Дата обновления
   * @format date-time
   */
  changeTime?: string;
  /**
   * Дата создания
   * @format date-time
   */
  createTime: string;
  /**
   * Разница в цене (скидка абсолютная). Положительное значение
   * @format int32
   */
  discount?: number;
  /**
   * Скидка в %
   * @format int32
   */
  discountProc?: number;
  /**
   * Идентификатор контрторга
   * @format int64
   */
  id: number;
  /**
   * Последняя предложенная цена
   * @format int32
   */
  lastPrice: number;
  /** Товар */
  product: AdminBargainProduct;
  /** Продавец */
  seller: AdminBargainUser;
  /**
   * Сумма, которую получит продавец, если сделка состоится
   * @format int32
   */
  sellerReceivesSum?: number;
  /** Размер */
  size: BargainSize;
  /** Статус контрторга */
  state: BargainState;
  /**
   * Сколько времени осталось до принятия решения покупателем или продавцом (секунды)
   * @format int64
   */
  timeLeft?: number;
}

/**
 * AdminBargainProduct
 * Товар контрторга
 */
export interface AdminBargainProduct {
  /** Бренд */
  brand: BrandDTO;
  /** Категория */
  category: BargainCategory;
  /** Цвет */
  color?: AttributeValueDTO;
  /**
   * Количество комментариев
   * @format int32
   */
  commentsCount: number;
  /**
   * Идентификатор товара
   * @format int64
   */
  id: number;
  /** Изображение товара */
  image: string;
  /**
   * Лайкнут текущим пользователем
   * @example false
   */
  isLiked: boolean;
  /**
   * Наличие подписки от текущего пользователя
   * @example false
   */
  isSubscribed: boolean;
  /**
   * Количество лайков
   * @format int32
   */
  likesCount: number;
  /**
   * Количество подписчиков
   * @format int32
   */
  subscribersCount: number;
  /** Название товара */
  title: string;
  /**
   * С биркой
   * @example false
   */
  withBadge: boolean;
}

/**
 * AdminBargainUser
 * Участник контрторга (продавец или покупатель) для админа
 */
export interface AdminBargainUser {
  /** Аватар */
  avatar?: string;
  /** E-mail */
  email: string;
  /**
   * Идентификатор пользователя
   * @format int64
   */
  id: number;
  /**
   * Является бутиком (PRO-продавцом)
   * @example false
   */
  isPro: boolean;
  /** Никнейм */
  nickname: string;
  /** Телефон */
  phone?: string;
  /** Тип продавца для отображения, н.п. Бутик */
  type: string;
}

/** AgentReportDTO */
export interface AgentReportDTO {
  /** @format bigdecimal */
  baseAmount?: number;
  bik?: string;
  cardBrand?: string;
  cardHolder?: string;
  cardNumber?: string;
  cardRefId?: string;
  /** @format bigdecimal */
  cleaningAmount?: number;
  /** @format int64 */
  createTime?: number;
  /** @format int64 */
  dateContract?: number;
  /** @format bigdecimal */
  defectsDiscountAmount?: number;
  firstName?: string;
  /** @format int64 */
  id?: number;
  inn?: string;
  isCard?: boolean;
  isConfirmed?: boolean;
  kpp?: string;
  name?: string;
  numberContract?: string;
  order?: OrderDTO1;
  patronymic?: string;
  paymentAccount?: string;
  /** @format bigdecimal */
  paymentAmount?: number;
  paymentDetails?: string;
  secondName?: string;
  userType?: "IP" | "OOO" | "SIMPLE_USER";
}

/** AlertDTO */
export interface AlertDTO {
  dtype?: string;
  /** @format int64 */
  id?: number;
}

/** Api2Response */
export interface Api2Response1 {
  data?: object;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfAccountCredsDTO */
export interface Api2ResponseOfAccountCredsDTO {
  data?: AccountCredsDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfAccountDTO */
export interface Api2ResponseOfAccountDTO {
  data?: AccountDTORes;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfAdminBargainDetailed */
export interface Api2ResponseOfAdminBargainDetailed {
  /** Полная информация о контрторге для админки */
  data?: AdminBargainDetailed;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfBargainBubbles */
export interface Api2ResponseOfBargainBubbles {
  /** Набор счетчиков по контрторгам */
  data?: BargainBubbles;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfBargainDetailed */
export interface Api2ResponseOfBargainDetailed {
  /** Детализированная информация о контрторге */
  data?: BargainDetailed;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfBargainSettings */
export interface Api2ResponseOfBargainSettings {
  /** Настройки контрторгов (базовая информация и константы) */
  data?: BargainSettings;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfBubblesDTO */
export interface Api2ResponseOfBubblesDTO {
  data?: BubblesDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfConversion */
export interface Api2ResponseOfConversion {
  data?: Conversion;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfCounterpartyDTO */
export interface Api2ResponseOfCounterpartyDTO {
  data?: CounterpartyDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfDeeplinkResolveResultOfobject */
export interface Api2ResponseOfDeeplinkResolveResultOfobject {
  data?: DeeplinkResolveResultOfobject;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfGroupedCart */
export interface Api2ResponseOfGroupedCart {
  data?: GroupedCart;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfIdDTO */
export interface Api2ResponseOfIdDTO {
  data?: IdDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfIdAndJwtDTO */
export interface Api2ResponseOfIdAndJwtDTO {
  data?: IdAndJwtDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfSimpleString */
export interface Api2ResponseOfSimpleString {
  data?: string;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPreFilledRegisterFormAndOptions */
export interface Api2ResponseOfPreFilledRegisterFormAndOptions {
  data?: {
    registerForm?: PreFilledRegisterFormResponse;
    registrationOptions?: RegistrationOptionsResponse;
  };
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfInitOrderResult */
export interface Api2ResponseOfInitOrderResult {
  data?: InitOrderResult;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfInstagramAccountDTO */
export interface Api2ResponseOfInstagramAccountDTO {
  data?: InstagramAccountDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfAdditionalSizeDTO */
export interface Api2ResponseOfListOfAdditionalSizeDTO {
  data?: AdditionalSizeDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfAddressDTO */
export interface Api2ResponseOfListOfAddressDTO {
  data?: AddressDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfAgentReportDTO */
export interface Api2ResponseOfListOfAgentReportDTO {
  data?: AgentReportDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOfAgentReportDTO */
export interface Api2ResponseOfPageOfAgentReportDTO {
  data?: PageOfAgentReportDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfBankOperationDTO */
export interface Api2ResponseOfListOfBankOperationDTO {
  data?: BankOperationDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfBannerDirect */
export interface Api2ResponseOfListOfBannerDirect {
  data?: BannerDirect[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfBannerSHOW_ONLY_APIView */
export interface Api2ResponseOfListOfBannerSHOWONLYAPIView {
  data?: BannerSHOWONLYAPIView[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfBlog */
export interface Api2ResponseOfListOfBlog {
  data?: Blog[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfDocumentLinkDTO */
export interface Api2ResponseOfListOfDocumentLinkDTO {
  data?: DocumentLinkDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfMedia */
export interface Api2ResponseOfListOfMedia {
  data?: Media[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfNotificationGroupDTO */
export interface Api2ResponseOfListOfNotificationGroupDTO {
  data?: NotificationGroupDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfOfferResponseItem */
export interface Api2ResponseOfListOfOfferResponseItem {
  data?: OfferResponseItem[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfOrderDTO */
export interface Api2ResponseOfListOfOrderDTO {
  data?: OrderDTO1[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfOskellyChoice */
export interface Api2ResponseOfListOfOskellyChoice {
  data?: OskellyChoice[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfPrimaryAPISHOW_ONLY_APIView */
export interface Api2ResponseOfListOfPrimaryAPISHOWONLYAPIView {
  data?: PrimaryAPISHOWONLYAPIView[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfPrimarySets */
export interface Api2ResponseOfListOfPrimarySets {
  data?: PrimarySets[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfReturnReasonDTO */
export interface Api2ResponseOfListOfReturnReasonDTO {
  data?: ReturnReasonDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfSizeTypeDTO */
export interface Api2ResponseOfListOfSizeTypeDTO1 {
  data?: SizeTypeDTO1[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfSocialAccountDTO */
export interface Api2ResponseOfListOfSocialAccountDTO {
  data?: SocialAccountDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfStepInfo */
export interface Api2ResponseOfListOfStepInfo {
  data?: StepInfo[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfTimeInterval */
export interface Api2ResponseOfListOfTimeInterval {
  data?: TimeInterval[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfUserDTO */
export interface Api2ResponseOfListOfUserDTO {
  data?: UserDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfWorldTrends */
export interface Api2ResponseOfListOfWorldTrends {
  data?: WorldTrends[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfListOfProductConditionResponseDTOV3 */
export interface ApiResponseOfListOfProductConditionResponseDTOV3 {
  data?: ProductConditionResponseDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfListOfSizeTypeResponseDTOV3 */
export interface ApiResponseOfListOfSizeTypeResponseDTOV3 {
  data?: SizeTypeResponseDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfstring */
export interface Api2ResponseOfListOfstring {
  data?: string[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfLogisticDetailsDTO */
export interface Api2ResponseOfLogisticDetailsDTO {
  data?: LogisticDetailsDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfOrderTrackingDTO */
export interface Api2ResponseOfOrderTrackingDTO {
  data?: OrderTrackingDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfMapOfintAndint */
export interface Api2ResponseOfMapOfintAndint {
  data?: Record<string, number>;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfMapOfstringAndint */
export interface Api2ResponseOfMapOfstringAndint {
  data?: Record<string, number>;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfMapOfstringAndobjectSHOW_ONLY_APIView */
export interface Api2ResponseOfMapOfstringAndobjectSHOWONLYAPIView {
  data?: object;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfMapOfstringAndstring */
export interface Api2ResponseOfMapOfstringAndstring {
  data?: Record<string, string>;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfNotificationBubbles */
export interface Api2ResponseOfNotificationBubbles {
  data?: NotificationBubbles;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfNotificationDTO */
export interface Api2ResponseOfNotificationDTO {
  data?: NotificationDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfOfferDetails */
export interface Api2ResponseOfOfferDetails {
  data?: OfferDetails;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfOrderDTO */
export interface Api2ResponseOfOrderDTO {
  data?: OrderDTO1;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOfAdminBargainLite */
export interface Api2ResponseOfPageOfAdminBargainLite {
  data?: PageOfAdminBargainLite;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfProductTranslated */
export interface Api2ResponseOfProductTranslated {
  data?: ProductTranslatedDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfProductRequestTranslated */
export interface Api2ResponseOfProductRequestTranslated {
  data?: ProductRequestTranslatedDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfProductTranslated */
export interface Api2ResponseOfCommentTranslated {
  data?: CommentTranslatedDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOfBargainLite */
export interface Api2ResponseOfPageOfBargainLite {
  data?: PageOfBargainLite;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOfNotificationDTO */
export interface Api2ResponseOfPageOfNotificationDTO {
  data?: PageOfNotificationDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOfOrderDTO */
export interface Api2ResponseOfPageOfOrderDTO1 {
  data?: PageOfOrderDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOfReturnInfoDTO */
export interface Api2ResponseOfPageOfReturnInfoDTO {
  data?: PageOfReturnInfoDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPageOfUserDTO */
export interface Api2ResponseOfPageOfUserDTO {
  data?: PageOfUserDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfPageOfProductDTOV3 */
export interface ApiResponseOfPageOfProductDTOV3 {
  data?: PageOfProductDTOV3;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfProductDTOV3 */
export interface ApiResponseOfProductDTOV3 {
  data?: ProductDTOV3;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfImageUploadResultV3 */
export interface ApiResponseOfImageUploadResultV3 {
  data?: ImageUploadResultV3;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfSeoDataDTO */
export interface ApiResponseOfSeoDataDTO {
  data?: SeoDataDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfPageOfBrandDTOV3 */
export interface ApiResponseOfPageOfBrandDTOV3 {
  data?: PageOfBrandDTOV3;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfPageOfUserDTOV3 */
export interface ApiResponseOfPageOfUserDTOV3 {
  data?: PageOfUserDTOV3;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfProductCountDTOV3 */
export interface ApiResponseOfProductCountDTOV3 {
  /** @format int64 */
  data?: number;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfPageOfProductModelDTOV3 */
export interface ApiResponseOfPageOfProductModelDTOV3 {
  data?: PageOfProductModelDTOV3;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponseOfListOfEnumLabelPairDTOV3 */
export interface ApiResponseOfListOfEnumLabelPairDTOV3 {
  data?: EnumLabelPairDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** SeoDataRequest */
export interface SeoDataRequest {
  /** @format int64 */
  categoryId?: number;
  /** @format int64 */
  brandId?: number;
  variablesMap?: Record<string, string>;
}

/** BrandReq */
export interface BrandReq {
  ids?: number[];
  idsExclude?: number[];
  name?: string;
  url?: string;
  isHidden?: boolean;
  title?: string;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  rowsPerPage?: number;
  sortBy?: string;
  descending?: boolean;
}

/** ProductsRequestV3 */
export interface ProductsRequestV3 {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  createTimeSince?: string;
  /** @format date-time */
  createTimeTill?: string;
  /** @format date-time */
  sendToModeratorTimeSince?: string;
  /** @format date-time */
  sendToModeratorTimeTill?: string;
  /** @format date-time */
  productStateTimeSince?: string;
  /** @format date-time */
  productStateTimeTill?: string;
  /** @format date-time */
  changeTimeSince?: string;
  /** @format date-time */
  changeTimeTill?: string;
  brandIds?: number[];
  brandIdsExclude?: number[];
  productModelIds?: number[];
  productModelIdsExclude?: number[];
  categoryIds?: number[];
  categoryIdsExclude?: number[];
  sellerIds?: number[];
  sellerIdsExclude?: number[];
  retoucherIds?: number[];
  retoucherIdsExclude?: number[];
  changeUserIds?: number[];
  changeUserIdsExclude?: number[];
  productConditionIds?: number[];
  productConditionIdsExclude?: number[];
  name?: string;
  productStates?: ProductState[];
  productStatesExclude?: ProductState[];
  sizeTypes?: SizeType[];
  sizeTypesExclude?: SizeType[];
  salesChannels?: SalesChannel[];
  salesChannelsExclude?: SalesChannel[];
  description?: string;
  idContains?: string;
  vendorCode?: string;
  storeCode?: string;
  paymentDescription?: string;
  deliveryDescription?: string;
  origin?: string;
  /** @format bigdecimal */
  purchasePriceFrom?: number;
  /** @format bigdecimal */
  purchasePriceTo?: number;
  /** @format bigdecimal */
  currentPriceFrom?: number;
  /** @format bigdecimal */
  currentPriceTo?: number;
  /** @format int64 */
  purchaseYearFrom?: number;
  /** @format int64 */
  purchaseYearTo?: number;
  vintage?: boolean;
  isTurbo?: boolean;
  isNewCollection?: boolean;
  isAtOffice?: boolean;
  /** @format int64 */
  page?: number;
  /** @format int64 */
  rowsPerPage?: number;
  sortBy?: string;
  descending?: boolean;
  customFilter?: string;
  selfPredicateType?: PredicateType;
  combinedPredicateType?: PredicateType;
  subPredicates?: ProductsRequestV3[];
  sellerProStatusTimeIsNotNull?: boolean;
  sellerTypes?: SellerType[];
  sellerTypesExclude?: SellerType[];
  publishTimeIsNotNull?: boolean;
  beegzTimeIsNotNull?: boolean;
  forMerchandisingTimeIsNotNull?: boolean;
  ourChoiceStatusTimeIsNotNull?: boolean;
  selectedConciergeTimeIsNotNull?: boolean;
  showAsConciergeTimeIsNotNull?: boolean;
  exclusiveSelectionTimeIsNotNull?: boolean;
  usedInSaleRequestTimeIsNotNull?: boolean;
  rrpPriceIsNotNull?: boolean;
  /** @format bigdecimal */
  rrpPriceFrom?: number;
  inWishListOfUsers?: number[];
  inCartOfUsers?: number[];
  tags?: string[];
}

/** UsersReq */
export interface UsersReq {
  ids?: number[];
  idsExclude?: number[];
  email?: string;
  phone?: string;
  nickname?: string;
  /** @format date-time */
  registrationTimeSince?: string;
  /** @format date-time */
  registrationTimeTill?: string;
  /** @format date-time */
  activationTimeSince?: string;
  /** @format date-time */
  activationTimeTill?: string;
  sexes?: Sex[];
  /** @format date-time */
  birthDateSince?: string;
  /** @format date-time */
  birthDateTill?: string;
  isTrusted?: boolean;
  /** @format int64 */
  countryId?: number;
  /** @format int64 */
  pickupCountryId?: number;
  userTypes?: UserType[];
  position?: string;
  sellerTypes?: SellerType[];
  authorities?: AuthorityName[];
  /** @format int64 */
  page?: number;
  /** @format int64 */
  rowsPerPage?: number;
  sortBy?: string;
  descending?: boolean;
}

/** ImagesUploadRequestV3 */
export interface ImagesUploadRequestV3 {
  images?: ImageFileDTO[];
  /** @format int64 */
  productId?: number;
}

/** ImageChangeRequestV3 */
export interface ImageChangeRequestV3 {
  image?: ImageFileDTO;
  /** @format int64 */
  productId?: number;
  /** @format int64 */
  oldImageId?: number;
}

/** ImageFileDTO */
export interface ImageFileDTO {
  base64Content?: string;
  name?: string;
  defectComment?: string;
}

/** ImageUploadResultV3 */
export interface ImageUploadResultV3 {
  uploadErrors?: Record<string, string>;
  product?: ProductDTOV3;
}

/** ProductModelsReq */
export interface ProductModelsReq {
  ids?: number[];
  idsExclude?: number[];
  name?: string;
  /** @format int64 */
  brandId?: number;
  brandIds?: number[];
  /** @format int64 */
  page?: number;
  /** @format int64 */
  rowsPerPage?: number;
  sortBy?: string;
  descending?: boolean;
}

/** Api2ResponseOfPageOfUserfileDTO */
export interface Api2ResponseOfPageOfUserfileDTO {
  data?: PageOfUserfileDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfProductImageDTO */
export interface Api2ResponseOfProductImageDTO {
  data?: ProductImageDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPublicProfileDTO */
export interface Api2ResponseOfPublicProfileDTO {
  data?: PublicProfileDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfReturnInfoDTO */
export interface Api2ResponseOfReturnInfoDTO {
  data?: ReturnInfoDTORes;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfSettings */
export interface Api2ResponseOfSettings {
  data?: Settings;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfStepInfo */
export interface Api2ResponseOfStepInfo {
  data?: StepInfo;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfUserAuthInfoDTO */
export interface Api2ResponseOfUserAuthInfoDTO {
  data?: UserAuthInfoDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfUserfileDTO */
export interface Api2ResponseOfUserfileDTO {
  data?: UserfileDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfUserIdToAvatarPathMap */
export interface Api2ResponseOfUserIdToAvatarPathMap {
  data?: Record<string, string>[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfbigdecimal */
export interface Api2ResponseOfbigdecimal {
  /** @format bigdecimal */
  data?: number;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfdouble */
export interface Api2ResponseOfdouble {
  /** @format double */
  data?: number;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfint */
export interface Api2ResponseOfint {
  /** @format int32 */
  data?: number;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOflong */
export interface Api2ResponseOflong {
  /** @format int64 */
  data?: number;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfString */
export interface Api2ResponseOfString {
  data?: "ACCEPTED" | "PENDING" | "REJECTED";
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** AppsflyerConversion */
export interface AppsflyerConversion {
  adset?: string;
  appsflyerId?: string;
  campaign?: string;
  channel?: string;
  city?: string;
  /** @format date-time */
  createTime?: string;
  guestToken?: string;
  /** @format int64 */
  id?: number;
  /** @format date-time */
  installTime?: string;
  keywords?: string;
  mediaSource?: string;
  partner?: string;
  siteId?: string;
  /** @format int64 */
  userId?: number;
}

/** BankBalanceDTO */
export interface BankBalanceDTO {
  /** @format bigdecimal */
  balance?: number;
}

/** BankMoneyTransferDTO */
export interface BankMoneyTransferDTO {
  /** @format bigdecimal */
  amount?: number;
  ordersWithMoneyTransferredIds?: number[];
  /** @format date-time */
  periodEnd?: string;
  /** @format date-time */
  periodStart?: string;
}

/** BankOperationDTO */
export interface BankOperationDTO {
  /** @format bigdecimal */
  amount?: number;
  bankOperationId?: string;
  /** @format date-time */
  cardBindTime?: string;
  cardBrand?: string;
  cardHolder?: string;
  cardNumber?: string;
  cardRefId?: string;
  /** @format int64 */
  counterpartyId?: number;
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  fee?: number;
  /** @format int64 */
  id?: number;
  operationType?:
    | "CARD_BIND"
    | "CARD_UNBIND"
    | "HOLD"
    | "HOLD_COMPLETE"
    | "HOLD_REVERSE"
    | "OSKELLY_PAYOUT"
    | "PARTIAL_REFUND"
    | "REFUND"
    | "SELLER_PAYOUT";
  comment?: string;
  /** @format int64 */
  orderId?: number;
  paymentAccount?: string;
  paymentSystem?: string;
  paymentSystemCode?: string;
  /** @format date-time */
  paymentSystemTime?: string;
  rrn?: string;
  state?: "CANCELED" | "DONE" | "ERROR" | "INPROGRESS" | "PREPARED";
  /** @format int64 */
  userId?: number;
  currency?: CurrencyDTO;
  cardDetails?: AdminCardDetailsView;
}

/** BankPaymentDTO */
export interface BankPaymentDTO {
  /** @format int64 */
  amountSentViaApi?: number;
  bank?: string;
  bankTransactionId?: string;
  /** @format int64 */
  fee?: number;
  /** @format int64 */
  orderId?: number;
  orderState?:
    | "CANCELED"
    | "COMPLETED"
    | "CREATED"
    | "DELETED"
    | "HOLD"
    | "HOLD_COMPLETED"
    | "HOLD_COMPLETE_REJECTED"
    | "HOLD_ERROR"
    | "HOLD_PROCESSING"
    | "MONEY_PAYMENT_ERROR"
    | "MONEY_PAYMENT_NOT_ENOUGH"
    | "MONEY_PAYMENT_TECHNICAL_ERROR"
    | "MONEY_PAYMENT_WAIT"
    | "MONEY_TRANSFERRED"
    | "REFUND"
    | "RETURN"
    | "SELLER_PAID";
  /** @format int64 */
  paymentId?: number;
  paymentState?: "CANCELED" | "DONE" | "ERROR" | "INPROGRESS" | "PREPARED";
  paymentVersion?: string;
}

/** BannerDirect */
export interface BannerDirect {
  bannerType?: BannerDirectType;
  base?: PrimaryCategory;
  brand?: PrimaryBrand;
  category?: PrimaryCategory;
  id?: string;
  image?: string;
}

/** BannerDirectType */
export interface BannerDirectType {
  title?: string;
}

/** BannerSHOW_ONLY_APIView */
export interface BannerSHOWONLYAPIView {
  bannerType?:
    | "BANNER_1"
    | "BANNER_2"
    | "BANNER_3"
    | "BANNER_4"
    | "BANNER_ACTUAL_1"
    | "BANNER_ACTUAL_2"
    | "BANNER_ACTUAL_3";
  base?: PrimaryCategorySHOWONLYAPIView;
  id?: string;
  imageMobile?: string;
  imageWeb?: string;
  products?: PrimaryProductSHOWONLYAPIView[];
  /** @format int64 */
  productsTotal?: number;
  subtitle?: string;
  title?: string;
}

/**
 * BargainBubbles
 * Набор счетчиков по контрторгам
 */
export interface BargainBubbles {
  /**
   * Кол-во активных входящих контрторгов всего
   * @format int32
   */
  incomingActive: number;
  /**
   * Кол-во завершенных входящих контрторгов всего
   * @format int32
   */
  incomingFinished: number;
  /**
   * Кол-во входящих контрторгов всего (активных и завершенных)
   * @format int32
   */
  incomingTotal: number;
  /**
   * Кол-во активных исходящих контрторгов всего
   * @format int32
   */
  outgoingActive: number;
  /**
   * Кол-во завершенных исходящих контрторгов всего
   * @format int32
   */
  outgoingFinished: number;
  /**
   * Кол-во исходящих контрторгов всего (активных и завершенных)
   * @format int32
   */
  outgoingTotal: number;
  /**
   * Кол-во контрторгов всего (сходящих и исходящих, активных и завершенных)
   * @format int32
   */
  total: number;
}

/**
 * BargainCategory
 * Категория товар контрторга
 */
export interface BargainCategory {
  /**
   * Идентификатор категории
   * @format int64
   */
  id: number;
  /** Название категории */
  title: string;
}

/**
 * BargainDetailed
 * Детализированная информация о контрторге
 */
export interface BargainDetailed {
  /**
   * Сколько попыток осталось
   * @format int32
   */
  attemptsLeft: number;
  /**
   * Первоначальная цена на момент создания контрторга
   * @format int32
   */
  basePrice: number;
  /** Покупатель */
  buyer: BargainUser;
  /**
   * Дата обновления
   * @format date-time
   */
  changeTime?: string;
  /**
   * Дата создания
   * @format date-time
   */
  createTime: string;
  /**
   * Идентификатор контрторга
   * @format int64
   */
  id: number;
  /**
   * Последняя предложенная цена
   * @format int32
   */
  lastPrice: number;
  /** Сопроводительное сообщение для пользователя */
  message?: BargainMessageDTO;
  /** Товар */
  product: BargainProduct;
  /** Записи по контрторгу */
  records: BargainRecord[];
  /** Продавец */
  seller: BargainUser;
  /**
   * Сумма, которую получит продавец, если сделка состоится. Данное поле доступно только для продавца
   * @format int32
   */
  sellerReceivesSum?: number;
  /** Размер */
  size: BargainSize;
  /** Статус контрторга */
  state: BargainState;
  /**
   * Сколько времени осталось до принятия решения покупателем или продавцом (секунды)
   * @format int64
   */
  timeLeft?: number;
  /** Действия, которые может выполнить текущий пользователь (продавец или покупатель) */
  userCan: ("ACCEPT" | "CREATION" | "DECLINE" | "OFFER")[];
  /**
   * Если текущий пользователь является покупателем, то это поле имеет значение true
   * @example false
   */
  userIsBuyer: boolean;
}

/**
 * BargainLite
 * Сокращенная информация о контрторге
 */
export interface BargainLite {
  /**
   * Сколько попыток осталось
   * @format int32
   */
  attemptsLeft: number;
  /**
   * Первоначальная цена на момент создания контрторга
   * @format int32
   */
  basePrice: number;
  /** Покупатель */
  buyer: BargainUser;
  /**
   * Дата обновления
   * @format date-time
   */
  changeTime?: string;
  /**
   * Дата создания
   * @format date-time
   */
  createTime: string;
  /**
   * Идентификатор контрторга
   * @format int64
   */
  id: number;
  /**
   * Последняя предложенная цена
   * @format int32
   */
  lastPrice: number;
  /** Товар */
  product: BargainProduct;
  /** Продавец */
  seller: BargainUser;
  /** Размер */
  size: BargainSize;
  /** Статус контрторга */
  state: BargainState;
  /**
   * Сколько времени осталось до принятия решения покупателем или продавцом (секунды)
   * @format int64
   */
  timeLeft?: number;
}

/**
 * BargainMessageDTO
 * Сообщение (памятка)
 */
export interface BargainMessageDTO {
  /** Декорация заголовка */
  decoration: "NEGATIVE" | "NEUTRAL" | "POSITIVE";
  /** Текст сообщения */
  message: string;
  /** Заголовок */
  title: string;
}

/**
 * BargainProduct
 * Товар контрторга
 */
export interface BargainProduct {
  /** Категория */
  category: BargainCategory;
  /**
   * Количество комментариев
   * @format int32
   */
  commentsCount: number;
  /**
   * Идентификатор товара
   * @format int64
   */
  id: number;
  /** Изображение товара */
  image: string;
  /**
   * Товар находится в бутике
   * @example false
   */
  inBoutique: boolean;
  /**
   * Лайкнут текущим пользователем
   * @example false
   */
  isLiked: boolean;
  /**
   * Наличие подписки от текущего пользователя
   * @example false
   */
  isSubscribed: boolean;
  /**
   * Количество лайков
   * @format int32
   */
  likesCount: number;
  /**
   * Количество подписчиков
   * @format int32
   */
  subscribersCount: number;
  /** Название товара */
  title: string;
  /**
   * С биркой
   * @example false
   */
  withBadge: boolean;
}

/**
 * BargainRecord
 * Запись контрторга (отдельное предложение)
 */
export interface BargainRecord {
  /** Тип источника записи контрторга */
  from: BargainRecordFromType;
  /**
   * Идентификатор записи контрторга
   * @format int64
   */
  id?: number;
  /** Сопутствующее сообщение */
  message: string;
  /**
   * Предложенная в записи стоимость
   * @format int32
   */
  price?: number;
  /**
   * Сумма, которую получит продавец, если сделка состоится. Данное поле доступно только для продавца
   * @format int32
   */
  sellerReceivesSum?: number;
  /** Сообщение о сумме, которую получит продавец, если сделка состоится. Данное поле доступно только для продавца */
  sellerReceivesSumMessage?: string;
  /**
   * Время публикации записи контрторга в секундах
   * @format date-time
   */
  time: string;
  /** Тип записи контрторга */
  type: BargainRecordType;
}

/**
 * BargainRecordFromType
 * Тип источника контрторга
 */
export interface BargainRecordFromType {
  /** Источник записи (предложения) по контрторгу. BUYER - покупатель, SELLER - продавец, ADMIN - администратор, SYSTEM - система (автоматическое) */
  name: "ADMIN" | "BUYER" | "SELLER" | "SYSTEM";
  /** Человекочитаемое название источника */
  title: string;
}

/**
 * BargainRecordType
 * Тип записи контрторга
 */
export interface BargainRecordType {
  /** Тип записи (предложения) по контрторгу. WELCOME - до создания, приветствие от продавца, CREATION - создание контрторга покупателем, OFFER - очкркдное предложение от покупателя или продавца, ACCEPT - принятие, DECLINE - отклонение */
  name: "ACCEPT" | "CREATION" | "DECLINE" | "OFFER";
  /** Человекочитаемый тип записи */
  title: string;
}

/**
 * BargainSettings
 * Настройки контрторгов (базовая информация и константы)
 */
export interface BargainSettings {
  /** Сообщение покупателю об отклонении контрторга продавцом */
  buyerBargainDeclinedMessage: BargainMessageDTO;
  /** Сообщение покупателю об истечении срока действия контрторга */
  buyerBargainExpiredByBuyerMessage: BargainMessageDTO;
  /** Сообщение покупателю о том, что продавец не успел принять решение */
  buyerBargainExpiredBySellerMessage: BargainMessageDTO;
  /** Первое имитируемое сообщение покупателю от продавца */
  buyerBargainStartMessage: BargainMessageDTO;
  /** Сообщение покупателю об удачной покупке по выгодной цене */
  buyerBargainSuccessfulMessage: BargainMessageDTO;
  /** Памятка покупателю перед открытием контрторга */
  buyerBeforeBargainMessage: BargainMessageDTO;
  /** Сообщение покупателю об отсутствии активных контрторгов */
  buyerNoActiveBargainsMessage: BargainMessageDTO;
  /** Сообщение покупателю об отсутствии завершенных контрторгов */
  buyerNoFinishedBargainsMessage: BargainMessageDTO;
  /** Сообщение покупателю о том, что товар был продан до покупки */
  buyerProductSoldBeforeYouBuyMessage: BargainMessageDTO;
  /** Сообщение покупателю о том, сколько у продавца времени, чтобы ответить */
  buyerSellerHasTimeToAnswerMessage: BargainMessageDTO;
  /** Сообщение покупателю об ожидании ответа */
  buyerWaitingForAnswerTimeMessage: BargainMessageDTO;
  /** Константы */
  constants?: Constants;
  /** Сообщение продавцу об отклонении контрторга */
  sellerBargainDeclinedMessage: BargainMessageDTO;
  /** Сообщение продавцу о том, что покупатель не успел купить товар по новой цене */
  sellerBargainExpiredByBuyerMessage: BargainMessageDTO;
  /** Сообщение продавцу о том, что он не успел ответить на предложение */
  sellerBargainExpiredBySellerMessage: BargainMessageDTO;
  /** Сообщение продавцу об удачной продаже по выгодной цене */
  sellerBargainSuccessfulMessage: BargainMessageDTO;
  /** Памятка продавцу перед ответом на контрторг */
  sellerBeforeAnswerMessage: BargainMessageDTO;
  /** Сообщение продавцу о том, сколько у покупателя времени, чтобы купить товар по новой цене */
  sellerBuyerHasTimeToBuyYourProductByNewPriceMessage: BargainMessageDTO;
  /** Сообщение продавцу о том, что товар был продан до ответа покупателя */
  sellerProductSoldBeforeBuyerAnswerMessage: BargainMessageDTO;
  /** Сообщение продавцу о том, что товар был продан до ответа продавца */
  sellerProductSoldBeforeSellerAnswerMessage: BargainMessageDTO;
  /** Сообщение продавцу об ожидании ответа */
  sellerWaitingForAnswerTimeMessage: BargainMessageDTO;
  /** Базовая информация о контрторгах */
  welcomePage: WelcomePage;
}

/**
 * BargainSize
 * Размер товара
 */
export interface BargainSize {
  /**
   * Идентификатор размера
   * @format int64
   */
  id: number;
  /** Название размера */
  title: string;
}

/**
 * BargainState
 * Статус контрторга
 */
export interface BargainState {
  /** Декорация статуса */
  decoration: "NEGATIVE" | "NEUTRAL" | "POSITIVE";
  /** Статус контрторга. OFFER/COUNTER_OFFER - активный, DECLINED - отклоненный, CONFIRMED - подтвержденный, EXPIRED - просроченный */
  name: "CONFIRMED" | "CONSUMED" | "COUNTER_OFFER" | "DECLINED" | "EXPIRED" | "INITIAL" | "OFFER" | "SOLD";
  /** Человекочитаемое название статуса */
  title: string;
}

/**
 * BargainUser
 * Участник контрторга (продавец или покупатель)
 */
export interface BargainUser {
  /** Аватар */
  avatar?: string;
  /**
   * Идентификатор пользователя
   * @format int64
   */
  id: number;
  /**
   * Является бутиком (PRO-продавцом)
   * @example false
   */
  isPro: boolean;
  /** Никнейм */
  nickname: string;
  /** Тип продавца для отображения, н.п. Бутик */
  type: string;
}

/** Blog */
export interface Blog {
  base?: PrimaryCategory;
  id?: string;
  image?: string;
  link?: string;
  title?: string;
}

/** BubblesDTO */
export interface BubblesDTO {
  /** @format int32 */
  boutiqueSalesAgentReports?: number;
  /** @format int32 */
  boutiqueSalesAwaitingConfirmation?: number;
  /** @format int32 */
  boutiqueSalesFinished?: number;
  /** @format int32 */
  boutiqueSalesReturned?: number;
  /** @format int32 */
  boutiqueSalesInProcess?: number;
  /** @format int32 */
  myAddressEndpointsCount?: number;
  /** @format int32 */
  myAddressEndpointsAggregationCount?: number;
  /** @format int32 */
  myCardsCount?: number;
  /** @format int32 */
  myCounterpartiesCount?: number;
  myInfirmatioNotFull?: boolean;
  myPaymentDetailsNotFull?: boolean;
  myPromoCodeCreated?: boolean;
  /** @format int32 */
  myPromoCodesCount?: number;
  mySizeNotSet?: boolean;
  /** @format int32 */
  offersAcceptedWaiting?: number;
  /** @format int32 */
  offersProposedByMe?: number;
  /** @format int32 */
  offersProposedByMeAccepting?: number;
  /** @format int32 */
  offersProposedByMeRejecting?: number;
  /** @format int32 */
  offersProposedByMeWaiting?: number;
  /** @format int32 */
  offersProposedToMe?: number;
  /** @format int32 */
  offersProposedToMeAccepting?: number;
  /** @format int32 */
  offersProposedToMeRejecting?: number;
  /** @format int32 */
  offersProposedToMeWaiting?: number;
  /** @format int32 */
  ordersFinished?: number;
  /** @format int32 */
  ordersInProcess?: number;
  /** @format int32 */
  ordersReturns?: number;
  /** @format int32 */
  productsAlerts?: number;
  /** @format int32 */
  productsDrafts?: number;
  /** @format int32 */
  productsHidden?: number;
  /** @format int32 */
  productsOnModeration?: number;
  /** @format int32 */
  productsOnSecondEdition?: number;
  /** @format int32 */
  productsPriceFollowing?: number;
  /** @format int32 */
  productsPublished?: number;
  /** @format int32 */
  productsRejected?: number;
  /** @format int32 */
  productsSold?: number;
  /** @format int32 */
  productsWishlist?: number;
  /** @format int32 */
  salesAgentReports?: number;
  /** @format int32 */
  salesAwaitingConfirmation?: number;
  /** @format int32 */
  salesFinished?: number;
  /** @format int32 */
  salesInProcess?: number;
  /** @format int32 */
  total?: number;
  /** @format int32 */
  totalGrey?: number;
  /** @format int32 */
  totalRed?: number;
  /** @format int64 */
  saleRequestsTotal?: number;
}

/** BuyerCheckDTO */
export interface BuyerCheckDTO {
  buyerCheckJson?: string;
  /** @format int64 */
  orderId?: number;
}

/** BuyerCheckRequest */
export interface BuyerCheckRequest {
  /** @format int64 */
  advancePayment?: number;
  /** @format int64 */
  cash?: number;
  clientId?: string;
  /** @format int64 */
  consideration?: number;
  correction?: boolean;
  /** @format byte */
  correctionType?: string;
  /** @format int64 */
  credit?: number;
  device?: string;
  /** @format int32 */
  documentType?: number;
  fullResponse?: boolean;
  lines?: Line[];
  /** @format int32 */
  maxDocumentsInTurn?: number;
  nonCash?: number[];
  /** @format int32 */
  password?: number;
  phoneOrEmail?: string;
  place?: string;
  reason?: Reason;
  requestId?: string;
  tax?: number[];
  /** @format int32 */
  taxMode?: number;
}

/** Charset */
export interface Charset {
  registered?: boolean;
}

/** CityValidatedDTO */
export interface CityValidatedDTO {
  /** @format int64 */
  addressId?: number;
  isCityValidated?: boolean;
  /** @format date-time */
  lastCityValidationTime?: string;
  validatedCity?: string;
}

/** ProductCommentBase64ImagesDataDTO */
export interface ProductCommentBase64ImagesDataDTO {
  imagesBase64?: string[];
  /** @format int64 */
  parentCommentId?: number;
  /** @format int64 */
  productId?: number;
  /** @format int64 */
  productRequestId?: number;
  text?: string;
}

/**
 * Constants
 * Константы
 */
export interface Constants {
  /** Активные статусы торгов (торг еще не завершен) */
  activeBargainStates: BargainState[];
  /** Варианты сортировки */
  bargainSorts: BargainState[];
  /**
   * Максимальное ко-во часов для разышления покупателю
   * @format int32
   */
  buyerMaxDecisionTimeHours: number;
  /** Вариант сортировк по умолчанию */
  defaultBargainSort: BargainState;
  /**
   * Размер страницы по умолчанию
   * @format int32
   */
  defaultPageLength: number;
  /** Завершенные статусы торгов (торг завершен) */
  finishedBargainStates: BargainState[];
  /**
   * Максимальное кол-во попыток для одного участника, н.п. покупателя
   * @format int32
   */
  maxAttemptsCount: number;
  /**
   * Максимальное ко-во торгов для пользователя по одному товару/размеру
   * @format int32
   */
  maxBargainsCountForUserProductSize: number;
  /**
   * Минимальная цена торгов (ниже предложить нельзя)
   * @format int32
   */
  minBargainPrice: number;
  /**
   * Минимальный коэфициент изменения цены. 0.5 =  50%
   * @format double
   */
  minPriceKoef: number;
  /**
   * Максимальное кол-во часов для размышления продавцу
   * @format int32
   */
  sellerMaxDecisionTimeHours: number;
}

/** Conversion */
export interface Conversion {
  /** @format double */
  commission?: number;
  /** @format bigdecimal */
  commissionScaled?: number;
  explanation?: string;
  /** @format bigdecimal */
  priceWithCommission?: number;
  /** @format bigdecimal */
  priceWithoutCommission?: number;
  /** @format bigdecimal */
  fixedAmount?: number;
}

/** CounterpartyDTO */
export interface CounterpartyDTO {
  bik?: string;
  cardBindBank?: string;
  /** @format date-time */
  cardBindTime?: string;
  cardBrand?: string;
  cardHolder?: string;
  cardNumber?: string;
  cardRefId?: string;
  /** @format date-time */
  cardUnbindTime?: string;
  companyForm?: string;
  companyName?: string;
  correspondentAccount?: string;
  /** @format int64 */
  createTiestamp?: number;
  firstName?: string;
  /** @format int64 */
  id?: number;
  inn?: string;
  isActive?: boolean;
  isCard?: boolean;
  jurAddress?: AddressDTO;
  kpp?: string;
  lastName?: string;
  orgn?: string;
  passport?: string;
  patronymicName?: string;
  paymentAccount?: string;
  phone?: string;
  physAddress?: AddressDTO;
  type?: "CARD" | "IP" | "JUR" | "PHYS";
  /** @format int64 */
  userId?: number;
  counterpartyImage?: string;
  contractNumber?: string;
  /** @format date-time */
  contractDate?: string;
}

/** CustomerChatDTO */
export interface CustomerChatDTO {
  /** @format date-time */
  chatSyncTime?: string;
  chatToken?: string;
  /** @format int64 */
  id?: number;
}

/** Date */
export interface Date {
  /** @format int32 */
  day?: number;
  /** @format int32 */
  month?: number;
  /** @format int32 */
  year?: number;
}

/** DeeplinkResolveResultOfobject */
export interface DeeplinkResolveResultOfobject {
  catalogFilter?: Record<string, FilterValue>;
  object?: object;
  brand?: object;
  title?: string;
  relCanonicalPath?: string;
  brandInPath?: boolean;
  type?: string;
}

/** DeliveryStatusDTO */
export interface DeliveryStatusDTO {
  deliveryCompany?: string;
  waybillExternalId?: string;
  waybillOrderExternalId?: string;
  /** @format date-time */
  statusTime?: string;
  trackingState?: string;
  trackingComment?: string;
  /** @format date-time */
  trackingTime?: string;
}

/** DeliveryHintDTO */
export interface DeliveryHintDTO {
  deliveryHints?: string;
  preferCompany?: string;
}

/** Discount */
export interface Discount {
  /** @format int64 */
  baseAmount?: number;
  code?: string;
  /** @format bigdecimal */
  discountProc?: number;
  /** @format bigdecimal */
  discountValue?: number;
  isValidYet?: boolean;
  optionalText?: string;
  /** @format int64 */
  resultAmount?: number;
  /** @format int64 */
  resultAmountWithDeliveryCost?: number;
  /** @format int64 */
  savingsValue?: number;
  type?: string;
}

/** DocumentLinkDTO */
export interface DocumentLinkDTO {
  type?:
    | "CERTIFICATE"
    | "OSKELLY_WAYBILL_FROM_SELLER"
    | "OSKELLY_WAYBILL_TO_BUYER"
    | "TK_WAYBILL_FROM_SELLER"
    | "TK_WAYBILL_TO_BUYER"
    | "TK_STICKERS_TO_BUYER"
    | "DOC_ORDER_LABELS";
  description?: string;
  url?: string;
  /** @format int32 */
  sequenceNumber?: number;
}

/** ExpertiseDTO */
export interface ExpertiseDTO {
  /** @format bigdecimal */
  cleaningPrice?: number;
  /** @format int64 */
  createTime?: number;
  defectComment?: string;
  /** @format bigdecimal */
  defectDiscount?: number;
  /** @format bigdecimal */
  defectDiscountPrice?: number;
  /** @format int64 */
  id?: number;
  isApproved?: boolean;
  pickupFrom?: "BUYER" | "OFFICE" | "SELLER";
  rejectionReason?: string;
}

/** ExpiredOfferDTO */
export interface ExpiredOfferDTO {
  consumedByBuyer?: boolean;
  /** @format int64 */
  offerId?: number;
  rejected?: boolean;
}

/** GroupedCart */
export interface GroupedCart {
  /** @format int32 */
  count?: number;
  groups?: OrderDTO1[];
  /** @format int32 */
  size?: number;
}

/** HelpcrunchAppSettings */
export interface HelpcrunchAppSettings {
  /** @format int64 */
  appId?: number;
  secret?: string;
}

/** HelpcrunchSettings */
export interface HelpcrunchSettings {
  android?: HelpcrunchAppSettings;
  ios?: HelpcrunchAppSettings;
  organization?: string;
}

/** PhoneVerificationSettings */
export interface PhoneVerificationSettings {
  /** @format int32 */
  attemptsCount?: number;
  /** @format int32 */
  attemptTimeout?: number;
  popup?: PhonePopupMobileSettings;
  webPopup?: PhonePopupWebSettings;
}

/** ExclusiveSelectionBannerSettings */
export interface ExclusiveSelectionBannerSettings {
  exclusiveSelectionMaleBannerLink?: string;
  exclusiveSelectionFemaleBannerLink?: string;
}

/** PhonePopupMobileSettings */
export interface PhonePopupMobileSettings {
  addToFavorites?: boolean;
  makeOrder?: boolean;
  productSceneContactSeller?: boolean;
  productSceneOfferNewPrice?: boolean;
  productSceneSubscribe?: boolean;
  publishProduct?: boolean;
  purchaseRequest?: boolean;
}

/** PhonePopupWebSettings */
export interface PhonePopupWebSettings {
  addToFavorites?: boolean;
  makeOrder?: boolean;
  productSceneContactSeller?: boolean;
  productSceneSubscribe?: boolean;
  publishProduct?: boolean;
  blackStick?: boolean;
}

/** PriceConversionItemDTO */
export interface PriceConversionItemDTO {
  correlationId?: string;
  prices: number[];
}

/** PriceConversionRequestDTO */
export interface PriceConversionRequestDTO {
  /** @format int64 */
  currencyId: number;
  items?: PriceConversionItemDTO[];
}

/** PriceConversionResponseDTO */
export interface PriceConversionResponseDTO {
  /** @format int64 */
  currencyId?: number;
  items?: PriceConversionItemDTO[];
}

/** IdDTO */
export interface IdDTO {
  /** @format int64 */
  id?: number;
}

/** IdAndJwtDTO */
export interface IdAndJwtDTO {
  /** @format int64 */
  id?: number;
  jwt?: string;
}

/** IdWithName */
export interface IdWithName {
  /** @format int64 */
  id?: number;
  name?: string;
}

export interface PhoneNumberAuthorizationRequest {
  phoneNumber: string;
  /** JWT token after phone number verify */
  jwtToken: string;
}

export interface PhoneNumberReAuthorizationRequest {
  phoneNumber: string;
  /** JWT token after phone number auth */
  jwtToken: string;
}

export interface GoogleRegistrationRequest {
  googleIdToken: string;
  /** JWT token after phone number verify */
  phoneNumberVerifyJwtToken?: string;
}

export interface GoogleAuthorizationRequest {
  googleIdToken: string;
}

export interface YandexRegistrationRequest {
  yandexIdToken: string;
  /** JWT token after phone number verify */
  phoneNumberVerifyJwtToken?: string;
}

export interface YandexAuthorizationRequest {
  yandexIdToken: string;
}

export interface AppleRegistrationRequest {
  appleIdToken: string;
  /** JWT token after phone number verify */
  phoneNumberVerifyJwtToken?: string;
}

export interface AppleAuthorizationRequest {
  appleIdToken: string;
}

export interface SocialReAuthorizationRequest {
  email: string;
  socialReAuthJwtToken: string;
}

export interface RegisterFormRequest {
  /**
   * User's email address. Must be a valid email format.
   * @format email
   * @example "user@example.com"
   */
  email: string;
  phone: string;
  nickname: string;
  avatarUrl?: string;
  avatarBase64?: string;
  subscriptionApprove?: boolean;
}

export interface PreFilledRegisterFormResponse {
  /**
   * User's email address. Must be a valid email format.
   * @format email
   * @example "user@example.com"
   */
  email?: string;
  phone?: string;
  nickname?: string;
  avatarUrl?: string;
  avatarBase64?: string;
  subscriptionApprove?: boolean;
}

export interface RegistrationOptionsResponse {
  emailEditDisabled?: boolean;
  phoneNumberVerificationNeed?: boolean;
  phoneNumberEditDisabled?: boolean;
}

/** InitOrderResult */
export interface InitOrderResult {
  bank_url?: string;
  /** @format int64 */
  orderId?: number;
  paymentSystem?: string;
}

/** InstagramAccountDTO */
export interface InstagramAccountDTO {
  instagramAvatar?: string;
  instagramId?: string;
  instagramImages?: SocialAccountPostImageDTO[];
  instagramOptions?: SocialAccountOptionDTO[];
  instagramPublicationsCount?: string;
  instagramSubscribersCount?: string;
  instagramUrl?: string;
}

/** Likes */
export interface Likes {
  /** @format int32 */
  count?: number;
  liked?: boolean;
}

/** Line */
export interface Line {
  description?: string;
  /** @format int32 */
  payAttribute?: number;
  /** @format int64 */
  price?: number;
  /** @format int64 */
  qty?: number;
  /** @format int32 */
  taxId?: number;
}

/** LogisticDetailsDTO */
export interface LogisticDetailsDTO {
  deliveryS2OHints?: DeliveryHintDTO;
  deliveryO2BHints?: DeliveryHintDTO;
  fromSellerDeliveryChanges?: DeliveryStatusDTO[];
  toBuyerDeliveryChanges?: DeliveryStatusDTO[];
}

/** OrderTrackingDTO */
export interface OrderTrackingDTO {
  o2bTrackingUrl?: string;
  s2oTrackingUrl?: string;
}

/** LogisticStateDeliveryDTO */
export interface LogisticStateDeliveryDTO {
  deliveryState?:
    | "DELIVERED_FROM_BUYER_TO_OFFICE"
    | "DELIVERED_FROM_SELLER_TO_OFFICE"
    | "DELIVERED_TO_BUYER"
    | "DELIVERED_TO_SELLER"
    | "DELIVERY_IN_MOSCOW"
    | "DELIVERY_TODAY_TO_BUYER"
    | "FROM_BUYER_TO_OFFICE"
    | "FROM_OFFICE_TO_BUYER"
    | "FROM_OFFICE_TO_SELLER"
    | "FROM_SELLER_TO_OFFICE"
    | "JUST_CREATED"
    | "JUST_CREATED_TO_BUYER"
    | "OURSELVES_DELIVERY_TODAY_TO_BUYER"
    | "OURSELVES_DELIVERY_TO_BUYER"
    | "OURSELVES_FROM_BUYER_TO_OFFICE"
    | "OURSELVES_FROM_OFFICE_TO_BUYER"
    | "OURSELVES_FROM_OFFICE_TO_SELLER"
    | "OURSELVES_FROM_SELLER_TO_OFFICE"
    | "OURSELVES_PICKING_UP_FROM_BUYER"
    | "OURSELVES_PICKING_UP_FROM_OFFICE"
    | "OURSELVES_PICKING_UP_FROM_SELLER"
    | "PICKING_UP_FROM_BUYER"
    | "PICKING_UP_FROM_OFFICE"
    | "PICKING_UP_FROM_SELLER"
    | "PICKUP_DECLINED"
    | "PICKUP_IN_MOSCOW";
  /** @format int64 */
  waybillId?: number;
  waybillInnerStatusDescription?: string;
  /** @format int32 */
  waybillInnerStatusId?: number;
}

/** Media */
export interface Media {
  id?: string;
  image?: string;
  link?: string;
  publishDate?: string;
  title?: string;
}

/** MediaType */
export interface MediaType {
  charset?: Charset;
  concrete?: boolean;
  parameters?: Record<string, string>;
  /** @format double */
  qualityValue?: number;
  subtype?: string;
  type?: string;
  wildcardSubtype?: boolean;
  wildcardType?: boolean;
}

/** Metadata */
export interface Metadata {
  positiveCase?: boolean;
  priceDescription?: string;
  status?: string;
}

/** ModificationMetadata */
export interface ModificationMetadata {
  acceptMediaType?: MediaType;
  acceptMethod?: "DELETE" | "GET" | "HEAD" | "OPTIONS" | "PATCH" | "POST" | "PUT" | "TRACE";
  acceptParamFormat?: string;
  acceptParamName?: string;
  acceptUrl?: string;
  currentValue?: object;
  modifiable?: boolean;
  title?: string;
}

/** NotificationBubbles */
export interface NotificationBubbles {
  /** @format int64 */
  commentsNeedActionNotCompleted?: number;
  /** @format int64 */
  commentsNeedNoActionNotRead?: number;
  /** @format int64 */
  commentsNotRead?: number;
  /** @format int64 */
  commentsNotReadNoCommentsNeedActionNotCompleted?: number;
  /** @format int64 */
  commentsTotal?: number;
  /** @format int64 */
  noCommentsNeedActionNotCompleted?: number;
  /** @format int64 */
  noCommentsNeedActionNotCompletedWithNoCommentsNeedNoActionNotRead?: number;
  /** @format int64 */
  noCommentsNeedActionNotCompletedWithNoCommentsNeedNoActionNotReadWithCommentsNotRead?: number;
  /** @format int64 */
  noCommentsNeedNoActionNotRead?: number;
  /** @format int64 */
  noCommentsNotRead?: number;
  /** @format int64 */
  noCommentsTotal?: number;
  /** @format int64 */
  total?: number;
}

/** NotificationDTO */
export interface NotificationDTO {
  actionCompleted?: boolean;
  /** @format date-time */
  actionCompletedTime?: string;
  /** @format date-time */
  createTime?: string;
  deleted?: boolean;
  guestToken?: string;
  /** @format int64 */
  id?: number;
  images?: string[];
  initiator?: UserDTO;
  mainIcon?: string;
  message?: string;
  metadata?: object;
  modificationMetadata?: ModificationMetadata;
  needAction?: boolean;
  read?: boolean;
  /** @format date-time */
  readTime?: string;
  shortMessage?: string;
  subTitle?: string;
  targetObject?: object;
  /** @format int64 */
  targetObjectId?: number;
  targetObjectImage?: string;
  targetObjectImageHint?: string;
  targetObjectType?: string;
  targetObjectUrl?: string;
  targetUser?: UserDTO;
  tinyIcon?: string;
  title?: string;
  type?: string;
}

/** NotificationDeliveryResult */
export interface NotificationDeliveryResult {
  channel?: string;
  metadata?: string;
  /** @format int64 */
  notificationId?: number;
  successfullySent?: boolean;
  /** @format date-time */
  time?: string;
}

/** NotificationGroupDTO */
export interface NotificationGroupDTO {
  description?: string;
  hint?: string;
  /** @format int64 */
  id?: number;
  isGeneral?: boolean;
  isSelected?: boolean;
  name?: string;
  /** @format int32 */
  sortOrder?: number;
}

/** OfferDetails */
export interface OfferDetails {
  buyer?: User;
  canNegotiate?: boolean;
  history?: OfferHistoryItem[];
  /** @format int64 */
  offerEndTime?: number;
  product?: Product;
  seller?: User;
  /** @format bigdecimal */
  sellerRecievesSum?: number;
  /** @format int64 */
  timeRemains?: number;
}

/** OfferHistoryItem */
export interface OfferHistoryItem {
  /** @format int64 */
  closedAt?: number;
  consumedByBuyer?: boolean;
  /** @format int64 */
  expiresAt?: number;
  /** @format int64 */
  offerEndTime?: number;
  /** @format int64 */
  offerId?: number;
  /** @format bigdecimal */
  offeredPrice?: number;
  /** @format bigdecimal */
  sellerRecievesSum?: number;
  /** @format int32 */
  status?: number;
  /** @format int64 */
  timeRemains?: number;
}

/** OfferRequest */
export interface OfferRequest {
  /** @format bigdecimal */
  price: number;
  /** @format int64 */
  productId: number;
  /** @format int64 */
  sizeId: number;
}

/** OfferResponseItem */
export interface OfferResponseItem {
  /** @format int64 */
  buyerId?: number;
  /** @format int64 */
  closedAt?: number;
  consumedByBuyer?: boolean;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  offerEndTime?: number;
  /** @format int64 */
  offeredAt?: number;
  product?: Product;
  seller?: Seller;
  /** @format bigdecimal */
  sellerRecievesSum?: number;
  /** @format int32 */
  status?: number;
  /** @format int64 */
  timeRemains?: number;
}

/** OrderCreationProblemDTO */
export interface OrderCreationProblemDTO {
  data?: object;
  type?: "NOT_ENOUGH_AMOUNT";
  description?: string;
}

/** OrderDTO */
export interface OrderDTO1 {
  agentReport?: AgentReportDTO;
  agentReportConfirmed?: boolean;
  /** @format int64 */
  agentReportId?: number;
  buyerCounterparty?: CounterpartyDTO;
  /** @format bigdecimal */
  clearAmount?: number;
  comment?: string;
  /** @format bigdecimal */
  confirmedAmount?: number;
  /** @format int32 */
  count?: number;
  deletable?: boolean;
  deliveryAddressEndpoint?: AddressEndpointDTO;
  deliveryAddressEndpointAggregation?: AddressEndpointAggregationResponseDTO;
  deliveryComment?: string;
  /** @format bigdecimal */
  deliveryCost?: number;
  deliveryDescription?: string;
  deliveryIcon?: string;
  deliveryInfo?: string;
  deliveryTitle?: string;
  discount?: Discount;
  empty?: boolean;
  faulty?: boolean;
  /** @format bigdecimal */
  finalAmount?: number;
  /** @format int64 */
  id?: number;
  isMadeByNewUser?: boolean;
  items?: OrderPositionDTORes[];
  linkedNotification?: NotificationDTO;
  orderCreationProblems?: OrderCreationProblemDTO[];
  orderSource?: "BOUTIQUE" | "ONLINE";
  orderStateIcon?: string;
  orderStateSuccess?: boolean;
  orderStateTitle?: string;
  orderStatus?:
    | "BUYER_IN_MOSCOW"
    | "EXPECTING_CONFIRM_AGENT_REPORT"
    | "EXPECTING_COURIER_TO_BUYER"
    | "EXPECTING_COURIER_TO_SELLER"
    | "EXPERTISE_START"
    | "FROM_SELLER_TO_OFFICE"
    | "HOLD_COMPLETE_REJECTED"
    | "LOGIST_ON_WAY_TO_BUYER"
    | "LOGIST_ON_WAY_TO_SELLER"
    | "ORDER_CANT_CONFIRM_NO_SELLER_ADDRESS"
    | "ORDER_COMPLETED"
    | "ORDER_COMPLETED_RETURN"
    | "ORDER_CONFIRMED"
    | "ORDER_CONFIRMING"
    | "ORDER_DELIVERED"
    | "ORDER_REFUND"
    | "OURSELVES_DELIVERY_TO_BUYER"
    | "OURSELVES_FROM_OFFICE_TO_BUYER"
    | "OURSELVES_FROM_SELLER_TO_OFFICE"
    | "OURSELVES_PICKING_UP_FROM_SELLER"
    | "RETURN_COMPLETED"
    | "RETURN_CREATED"
    | "RETURN_EXPERTISE"
    | "RETURN_ON_WAY_TO_OFFICE"
    | "SELLER_IN_MOSCOW"
    | "UNCOMPLETED"
    | "UNDEFINED"
    | "WAIT_PAYMENT_MONEY_TO_SELLER";
  orderStatusTitle?: string;
  orderStepChain?: OrderStepChain;
  ourselvesDeliveries?: OurselvesDeliveryDTO[];
  ourselvesDelivery?: boolean;
  ourselvesDeliveryFromSeller?: OurselvesDeliveryDTO;
  ourselvesDeliveryName?: string;
  ourselvesDeliveryPhone?: string;
  ourselvesDeliveryToBuyer?: OurselvesDeliveryDTO;
  payment?: PaymentDTO;
  pickupAddressEndpoint?: AddressEndpointDTO;
  pickupAddressEndpointAggregation?: AddressEndpointAggregationResponseDTO;
  pickupComment?: string;
  /** @format int64 */
  pickupDateFromSeller?: number;
  /** @format int64 */
  pickupDateToBuyer?: number;
  pickupIntervalFromSeller?: string;
  /** @format int64 */
  pickupTimeIntervalId?: number;
  productLocation?: "BOUTIQUE" | "SELLER";
  /** @format bigdecimal */
  rrpSum?: number;
  seller?: UserDTO;
  sellerCounterparty?: CounterpartyDTO;
  /** @format bigdecimal */
  sellerReceivesAmount?: number;
  /** @format int32 */
  size?: number;
  state?:
    | "CANCELED"
    | "COMPLETED"
    | "CREATED"
    | "DELETED"
    | "HOLD"
    | "HOLD_COMPLETED"
    | "HOLD_COMPLETE_REJECTED"
    | "HOLD_ERROR"
    | "HOLD_PROCESSING"
    | "MONEY_PAYMENT_ERROR"
    | "MONEY_PAYMENT_NOT_ENOUGH"
    | "MONEY_PAYMENT_TECHNICAL_ERROR"
    | "MONEY_PAYMENT_WAIT"
    | "MONEY_TRANSFERRED"
    | "REFUND"
    | "RETURN"
    | "SELLER_PAID";
  /** @format int64 */
  stateTime?: number;
  trackingUrl?: string;
  waybillFromSeller?: WaybillDTO;
  waybillId?: string;
  waybillToBuyer?: WaybillDTO;
  waybills?: WaybillDTO[];
  uiTexts?: OrderUITextsDTO;
}

/** PaymentDTO */
export interface PaymentDTO {
  /** @format int64 */
  id?: number;
  paymentVersion?: string;
  paymentMethod?: string;
}

/** OrderPositionDTO */
export interface OrderPositionDTO {
  afterAcceptedOffer?: boolean;
  /** @format bigdecimal */
  amount?: number;
  available?: boolean;
  availableSizes?: Size[];
  brandName?: string;
  /** @format int32 */
  buyerCommentsCount?: number;
  buyerFollowsSeller?: boolean;
  /** @format int64 */
  categoryId?: number;
  /** @format bigdecimal */
  commission?: number;
  /** @format int32 */
  count?: number;
  /** @format int32 */
  discount?: number;
  description?: string;
  expertisePass?: boolean;
  expertises?: ExpertiseDTO[];
  /** @format bigdecimal */
  finalAmount?: number;
  /** @format int64 */
  id?: number;
  imageUrl?: string;
  isConfirmed?: boolean;
  isLiked?: boolean;
  isMoneyReturned?: boolean;
  metadata?: Metadata;
  productCondition?: string;
  productHasDiscount?: boolean;
  /** @format int64 */
  productId?: number;
  productModel?: OrderPositionProductModelDTO;
  productName?: string;
  /** @format bigdecimal */
  rrp?: number;
  /** @format bigdecimal */
  sellerReceivesAmount?: number;
  size?: Size;
  sizes?: SizeValueDTO[];
  state?:
    | "CREATE_WAYBILL_TO_BUYER"
    | "HQ_WAREHOUSE"
    | "INITIAL"
    | "ON_VERIFICATION"
    | "PICKUP_DECLINED"
    | "PURCHASE_REQUEST"
    | "READY_TO_SHIP"
    | "REJECTED_AFTER_VERIFICATION"
    | "REQUESTED_TO_RETURN"
    | "RETURN_ACCEPTED"
    | "RETURN_DECLINED"
    | "RETURN_VERIFICATION_OK"
    | "RETURN_VERIFICATION_REJECTED"
    | "SALE_CONFIRMED"
    | "SALE_REJECTED"
    | "SHIPPED_TO_CLIENT"
    | "VERIFICATION_BAD_STATE"
    | "VERIFICATION_NEED_CLEANING"
    | "VERIFICATION_OK";
}

/** OrderPositionDTOReq */
export interface OrderPositionDTOReq {
  afterAcceptedOffer?: boolean;
  /** @format bigdecimal */
  amount?: number;
  available?: boolean;
  availableSizes?: Size[];
  brandName?: string;
  /** @format int32 */
  buyerCommentsCount?: number;
  buyerFollowsSeller?: boolean;
  /** @format int64 */
  categoryId?: number;
  /** @format bigdecimal */
  commission?: number;
  /** @format int32 */
  count?: number;
  /** @format int32 */
  discount?: number;
  description?: string;
  expertisePass?: boolean;
  expertises?: ExpertiseDTO[];
  /** @format bigdecimal */
  finalAmount?: number;
  /** @format int64 */
  id?: number;
  imageUrl?: string;
  isConfirmed?: boolean;
  isLiked?: boolean;
  isMoneyReturned?: boolean;
  metadata?: Metadata;
  productCondition?: string;
  productHasDiscount?: boolean;
  /** @format int64 */
  productId?: number;
  productModel?: OrderPositionProductModelDTO;
  productName?: string;
  /** @format bigdecimal */
  rrp?: number;
  /** @format bigdecimal */
  sellerReceivesAmount?: number;
  size?: Size;
  sizes?: SizeValueDTOReq[];
  state?:
    | "CREATE_WAYBILL_TO_BUYER"
    | "HQ_WAREHOUSE"
    | "INITIAL"
    | "ON_VERIFICATION"
    | "PICKUP_DECLINED"
    | "PURCHASE_REQUEST"
    | "READY_TO_SHIP"
    | "REJECTED_AFTER_VERIFICATION"
    | "REQUESTED_TO_RETURN"
    | "RETURN_ACCEPTED"
    | "RETURN_DECLINED"
    | "RETURN_VERIFICATION_OK"
    | "RETURN_VERIFICATION_REJECTED"
    | "SALE_CONFIRMED"
    | "SALE_REJECTED"
    | "SHIPPED_TO_CLIENT"
    | "VERIFICATION_BAD_STATE"
    | "VERIFICATION_NEED_CLEANING"
    | "VERIFICATION_OK";
}

/** OrderPositionDTORes */
export interface OrderPositionDTORes {
  afterAcceptedOffer?: boolean;
  /** @format bigdecimal */
  amount?: number;
  available?: boolean;
  availableSizes?: Size[];
  brandName?: string;
  /** @format int32 */
  buyerCommentsCount?: number;
  buyerFollowsSeller?: boolean;
  /** @format int64 */
  categoryId?: number;
  /** @format bigdecimal */
  commission?: number;
  /** @format int32 */
  count?: number;
  /** @format int32 */
  discount?: number;
  description?: string;
  expertisePass?: boolean;
  expertises?: ExpertiseDTO[];
  /** @format bigdecimal */
  finalAmount?: number;
  /** @format int64 */
  id?: number;
  imageUrl?: string;
  isConfirmed?: boolean;
  isLiked?: boolean;
  isMoneyReturned?: boolean;
  metadata?: Metadata;
  productCondition?: string;
  productHasDiscount?: boolean;
  /** @format int64 */
  productId?: number;
  productModel?: OrderPositionProductModelDTO;
  productName?: string;
  /** @format bigdecimal */
  rrp?: number;
  /** @format bigdecimal */
  sellerReceivesAmount?: number;
  size?: Size;
  sizes?: SizeValueDTORes[];
  state?:
    | "CREATE_WAYBILL_TO_BUYER"
    | "HQ_WAREHOUSE"
    | "INITIAL"
    | "ON_VERIFICATION"
    | "PICKUP_DECLINED"
    | "PURCHASE_REQUEST"
    | "READY_TO_SHIP"
    | "REJECTED_AFTER_VERIFICATION"
    | "REQUESTED_TO_RETURN"
    | "RETURN_ACCEPTED"
    | "RETURN_DECLINED"
    | "RETURN_VERIFICATION_OK"
    | "RETURN_VERIFICATION_REJECTED"
    | "SALE_CONFIRMED"
    | "SALE_REJECTED"
    | "SHIPPED_TO_CLIENT"
    | "VERIFICATION_BAD_STATE"
    | "VERIFICATION_NEED_CLEANING"
    | "VERIFICATION_OK";
}

/** OrderPositionProductModelDTO */
export interface OrderPositionProductModelDTO {
  /** @format int64 */
  id?: number;
  name?: string;
}

/** OrderStepChain */
export interface OrderStepChain {
  steps?: OrderStepDTO[];
}

/** OrderStepDTO */
export interface OrderStepDTO {
  description?: string;
  descriptionItemIds?: number[];
  descriptionTitle?: string;
  itemDescriptions?: Record<string, string>;
  message?: string;
  temporaryMessage?: string;
  /** @format int64 */
  time?: number;
  title?: string;
  type?: "COMPLETE" | "CONFIRMATION" | "DELIVERING" | "DISABLED" | "EXPERTISE" | "FAILED" | "WAITING";
  typeIcon?: string;
  warning?: string;
  warningIcon?: string;
}

/** OrderUITextsDTO */
export interface OrderUITextsDTO {
  pickupFromSellerDateAndTime?: string;
  deliverySectionTitle?: string;
  estimatedDeliveryDate?: string;
  stateTitle?: string;
}

/** OskellyChoice */
export interface OskellyChoice {
  id?: string;
  link?: string;
}

/** OurselvesDeliveryDTO */
export interface OurselvesDeliveryDTO {
  courierName?: string;
  courierPhone?: string;
}

/** PageOfAdminBargainLite */
export interface PageOfAdminBargainLite {
  items?: AdminBargainLite[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** PageOfBargainLite */
export interface PageOfBargainLite {
  items?: BargainLite[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** PageOfNotificationDTO */
export interface PageOfNotificationDTO {
  items?: NotificationDTO[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** PageOfAgentReportDTO */
export interface PageOfAgentReportDTO {
  items?: AgentReportDTO[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** PageOfReturnInfoDTO */
export interface PageOfReturnInfoDTO {
  items?: ReturnInfoDTORes[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** PageOfUserDTO */
export interface PageOfUserDTO {
  items?: UserDTO[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** PageOfUserfileDTO */
export interface PageOfUserfileDTO {
  items?: UserfileDTO[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** PageOfProductDTOV3 */
export interface PageOfProductDTOV3 {
  items: ProductDTOV3[];
  /** @format int32 */
  itemsCount: number;
  /** @format int64 */
  totalAmount: number;
  /** @format int32 */
  totalPages: number;
}

/** PageOfBrandDTOV3 */
export interface PageOfBrandDTOV3 {
  items?: BrandDTOV3[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** PageOfUserDTOV3 */
export interface PageOfUserDTOV3 {
  items?: UserDTOV3[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** PageOfProductModelDTOV3 */
export interface PageOfProductModelDTOV3 {
  items?: ProductModelDTOV3[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** ProductTranslatedDTO */
export interface ProductTranslatedDTO {
  /** @format int64 */
  id?: number;
  description?: string;
  product?: BlankId;
}

/** ProductRequestTranslatedDTO */
export interface ProductRequestTranslatedDTO {
  /** @format int64 */
  id?: number;
  description?: string;
  productRequest?: BlankId;
}

/** CommentTranslatedDTO */
export interface CommentTranslatedDTO {
  /** @format int64 */
  id?: number;
  text?: string;
  product?: BlankId;
}

/** Price */
export interface Price {
  /** @format bigdecimal */
  current?: number;
  /** @format bigdecimal */
  proposed?: number;
}

/** PrimaryAPISHOW_ONLY_APIView */
export type PrimaryAPISHOWONLYAPIView = object;

/** PrimaryAttributeValue */
export interface PrimaryAttributeValue {
  /** @format int64 */
  attributeId?: number;
  attributeName?: string;
  /** @format int64 */
  id?: number;
  value?: string;
}

/** blankId */
export interface BlankId {
  /** @format int64 */
  id?: number;
}

/** PrimaryBrand */
export interface PrimaryBrand {
  /** @format int64 */
  id?: number;
  name?: string;
}

/** PrimaryBrandSHOW_ONLY_APIView */
export interface PrimaryBrandSHOWONLYAPIView {
  /** @format int64 */
  id?: number;
  name?: string;
}

/** PrimaryCategory */
export interface PrimaryCategory {
  /** @format int64 */
  id?: number;
  name?: string;
  url?: string;
}

/** PrimaryCategorySHOW_ONLY_APIView */
export interface PrimaryCategorySHOWONLYAPIView {
  /** @format int64 */
  id?: number;
  name?: string;
  url?: string;
}

/** PrimaryProduct */
export interface PrimaryProduct {
  base?: PrimaryCategory;
  brand?: PrimaryBrand;
  category?: PrimaryCategory;
  /** @format bigdecimal */
  cost?: number;
  /** @format int32 */
  discount?: number;
  /** @format int64 */
  id?: number;
  image?: string;
  /** @format int32 */
  likes?: number;
  /** @format bigdecimal */
  rrpPrice?: number;
  /** @format bigdecimal */
  startPrice?: number;
}

/** PrimaryProductSHOW_ONLY_APIView */
export interface PrimaryProductSHOWONLYAPIView {
  base?: PrimaryCategorySHOWONLYAPIView;
  brand?: PrimaryBrandSHOWONLYAPIView;
  category?: PrimaryCategorySHOWONLYAPIView;
  /** @format bigdecimal */
  cost?: number;
  /** @format int32 */
  discount?: number;
  /** @format int64 */
  id?: number;
  image?: string;
  /** @format int32 */
  likes?: number;
  /** @format bigdecimal */
  rrpPrice?: number;
  /** @format bigdecimal */
  startPrice?: number;
}

/** PrimarySets */
export interface PrimarySets {
  _links?: string[];
  id?: string;
  products?: PrimaryProduct[];
  /** @format int32 */
  productsTotal?: number;
  title?: string;
}

/** Product */
export interface Product {
  brand?: IdWithName;
  category?: IdWithName;
  /** @format int64 */
  condition?: number;
  /** @format int64 */
  id?: number;
  imagePath?: string;
  inCart?: boolean;
  likes?: Likes;
  price?: Price;
  size?: Size;
}

/** PublicProfileDTO */
export interface PublicProfileDTO {
  acceptsReturns?: boolean;
  avatarPath?: string;
  /** @format int64 */
  birthDate?: number;
  /** @format int32 */
  brandLikesCount?: number;
  city?: string;
  email?: string;
  firstChar?: string;
  followers?: UserDTO[];
  /** @format int32 */
  followersCount?: number;
  followings?: UserDTO[];
  /** @format int32 */
  followingsCount?: number;
  fullName?: string;
  /** @format int64 */
  id?: number;
  isFollowed?: boolean;
  isMyProfile?: boolean;
  isPro?: boolean;
  isTrusted?: boolean;
  /** @format int32 */
  likesCount?: number;
  name?: string;
  nickname?: string;
  /** @format int32 */
  productLikesCount?: number;
  /** @format int32 */
  productsCount?: number;
  /** @format int64 */
  registrationTime?: number;
  sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
  /** Бейджики пользователя в O!Community */
  communityBadge?: CommunityBadge;
  socialAccount?: UserSocialAccountDTO;
}

/** Reason */
export interface Reason {
  date?: Date;
  name?: string;
  number?: string;
}

/** ReturnInfoDTOReq */
export interface ReturnInfoDTOReq {
  buyerAddressEndpoint?: AddressEndpointDTO;
  /** @format int64 */
  buyerAddressEndpointId?: number;
  buyerCounterparty?: CounterpartyDTO;
  /** @format int64 */
  buyerCounterpartyId?: number;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  orderId?: number;
  passportPhoto?: UserfileDTO;
  /** @format int64 */
  passportPhotoId?: number;
  returnIcon?: string;
  returnPositions?: ReturnPositionDTOReq[];
  returnState?: ReturnStateDTO;
  returnStepChain?: ReturnStepChainReq;
  seller?: UserDTO;
}

/** ReturnInfoDTORes */
export interface ReturnInfoDTORes {
  buyerAddressEndpoint?: AddressEndpointDTO;
  /** @format int64 */
  buyerAddressEndpointId?: number;
  buyerCounterparty?: CounterpartyDTO;
  /** @format int64 */
  buyerCounterpartyId?: number;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  orderId?: number;
  passportPhoto?: UserfileDTO;
  /** @format int64 */
  passportPhotoId?: number;
  returnIcon?: string;
  returnPositions?: ReturnPositionDTORes[];
  returnState?: ReturnStateDTO;
  returnStepChain?: ReturnStepChainRes;
  seller?: UserDTO;
}

/** ReturnPositionDTOReq */
export interface ReturnPositionDTOReq {
  orderPosition?: OrderPositionDTOReq;
  /** @format int64 */
  orderPositionId?: number;
  reasonDescription?: string;
  returnReasonIds?: number[];
  tagPhoto?: UserfileDTO;
  /** @format int64 */
  tagPhotoId?: number;
}

/** ReturnPositionDTORes */
export interface ReturnPositionDTORes {
  orderPosition?: OrderPositionDTORes;
  /** @format int64 */
  orderPositionId?: number;
  reasonDescription?: string;
  returnReasonIds?: number[];
  tagPhoto?: UserfileDTO;
  /** @format int64 */
  tagPhotoId?: number;
}

/** ReturnReasonDTO */
export interface ReturnReasonDTO {
  /** @format int64 */
  id?: number;
  title?: string;
}

/** ReturnStateDTO */
export interface ReturnStateDTO {
  icon?: string;
  /** @format int64 */
  id?: number;
  isNegative?: boolean;
  title?: string;
}

/** ReturnStepChainReq */
export interface ReturnStepChainReq {
  steps?: ReturnStepDTOReq[];
}

/** ReturnStepChainRes */
export interface ReturnStepChainRes {
  steps?: ReturnStepDTORes[];
}

/** ReturnStepDTOReq */
export interface ReturnStepDTOReq {
  description?: string;
  descriptionItemIds?: number[];
  descriptionTitle?: string;
  itemDescriptions?: Record<string, string>;
  message?: string;
  /** @format int64 */
  time?: number;
  title?: string;
  type?: "COMPLETE" | "DISABLED" | "FAILED" | "WAITING";
}

/** ReturnStepDTORes */
export interface ReturnStepDTORes {
  description?: string;
  descriptionItemIds?: number[];
  descriptionTitle?: string;
  itemDescriptions?: Record<string, string>;
  message?: string;
  /** @format int64 */
  time?: number;
  title?: string;
  type?: "COMPLETE" | "DISABLED" | "FAILED" | "WAITING";
  typeIcon?: string;
}

/** Seller */
export interface Seller {
  avatarPath?: string;
  /** @format int64 */
  id?: number;
  nickname?: string;
  type?: string;
}

/** Settings */
export interface Settings {
  apiVersion?: string;
  experiments?: ExperimentDTO[];
  helpcrunchSettings?: HelpcrunchSettings;
  phoneVerificationSettings?: PhoneVerificationSettings;
  exclusiveSelectionBannerSettings?: ExclusiveSelectionBannerSettings;
  indexPageProductsSetExceptCategoryIds?: number[];
  isBargainsEnabled?: boolean;
  /** PromocodeSettings */
  promoCodeSettings?: {
    defaultExceptSellers?: number[];
  };
  country?: CountryDTO;
  /** BargainDisabledSellersSettings */
  bargainDisabledSellersSettings?: {
    bargainDisabledSellers?: number[];
  };
  searchHost?: string;
  sexList?: SexDTO[];
  staticServerUrl?: string;
  storiesIsActive?: boolean;
  applicationFeatureFlags?: ApplicationFeatureFlags;
}

/** ApplicationFeatureFlags */
export interface ApplicationFeatureFlags {
  /** SupportChannel */
  supportChannel?: "HELPCRUNCH" | "USEDESK";
  enableCardBinding?: boolean;
  enablePayoutToBoundCard?: boolean;
  enablePaymentFromBoundCard?: boolean;
  enableStories?: boolean;
  enableBargains?: boolean;
  enablePayoutToBankAccountForIndividuals?: boolean;
  enableStreamsale?: boolean;
  enableNewHome?: boolean;
  enableAndroidNewHome?: boolean;
  enableConcierge?: boolean;
  enableFilterOnTop?: boolean;
  enableNewProduct?: boolean;
  enableNewProductCell?: boolean;
  enableNewOnboarding?: boolean;
  enableNewPayoutDesign?: boolean;
  enable12Storeez?: boolean;
  enableNewSetPrice?: boolean;
  enableProductPublication?: boolean;
  enableNewProductFiltration?: boolean;
  enableNewHomeDesign?: boolean;
  enableNewCatalogMenuDesign?: boolean;
  enableBoutiqueCommissionConfirmationAgreement?: boolean;
  enableCheckoutPromocode?: boolean;
  enableReturnedBoutiqueProductsMenuChapter?: boolean;
}

/** ExperimentDTO */
export interface ExperimentDTO {
  /** @format int64 */
  id?: number;
  key?: string;
  /** @format int64 */
  valueId?: number;
  valueKey?: string;
  valueAttachment?: object;
}

/** SexDTO */
export interface SexDTO {
  sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
  title?: string;
}

/** Size */
export interface Size {
  /** @format int64 */
  id?: number;
  value?: string;
}

/** SizeTypeDTO */
export interface SizeTypeDTO1 {
  isDefault?: boolean;
  sizeType?:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  sizeTypeAbbreviation?: string;
  sizeTypeDescription?: string;
  values?: SizeDTO[];
}

/** SocialAccountDTO */
export interface SocialAccountDTO {
  avatarPath?: string;
  description?: string;
  isFollowed?: boolean;
  isPro?: boolean;
  isTrusted?: boolean;
  name?: string;
  nickname?: string;
  /** @format int32 */
  productsCount?: number;
  subscribersCount?: string;
  url?: string;
  /** @format int64 */
  userId?: number;
  /** Бейджики пользователя в O!Community */
  communityBadge?: CommunityBadge;
}

/** UserSocialAccountDTO */
export interface UserSocialAccountDTO {
  description?: string;
  name?: string;
  subscribersCount?: number;
  subscribersCountStr?: string;
}

/** SocialAccountOptionDTO */
export interface SocialAccountOptionDTO {
  name?: string;
}

/** SocialAccountPostImageDTO */
export interface SocialAccountPostImageDTO {
  url?: string;
}

/** SocialLoginRequest */
export interface SocialLoginRequest {
  apple_authorization_code?: string;
  apple_kid?: string;
  fb_token?: string;
  vk_token?: string;
  google_id_token?: string;
  yandex_id_token?: string;
}

/** SocialRegistrationRequest */
export interface SocialRegistrationRequest {
  apple_authorization_code?: string;
  apple_kid?: string;
  apple_user_id?: string;
  email?: string;
  fb_token?: string;
  nickname?: string;
  vk_token?: string;
}

/** StepInfo */
export interface StepInfo {
  /** @format int64 */
  orderId?: number;
  /** @format int32 */
  stepNumber?: number;
}

/** TextNotificationDTO */
export interface TextNotificationDTO {
  className?: string;
  guestToken?: string;
  needAction?: boolean;
  txt?: string;
  /** @format int64 */
  userId?: number;
}

/** TimeInterval */
export interface TimeInterval {
  description?: string;
  /** @format int64 */
  id?: number;
}

/** User */
export interface User {
  avatarPath?: string;
  /** @format int64 */
  id?: number;
  nickname?: string;
  type?: string;
}

/** UserAuthInfoDTO */
export interface UserAuthInfoDTO {
  authorities?: (
    | "ADMIN"
    | "PRODUCT_MODERATION"
    | "AUTHORITY_MODERATION"
    | "ORDER_MODERATION"
    | "CONTENT_CREATE"
    | "CONTENT_DELETE"
    | "USER_MODERATION"
    | "CAN_VIEW_ALL_PRODUCTS"
    | "OFFER_MODERATION"
    | "RETOUCHING_MODERATION"
    | "COMMENT_MODERATION"
    | "PROMOCODES_MODERATION"
    | "MASTER_USER"
    | "CONTENT_MODERATION"
    | "USERFILE_MODERATION"
    | "STORY_MODERATION"
    | "ORDER_RETURNS"
    | "ORDER_PAYOUTS"
    | "ORDER_CONCIERGE_PAYOUTS"
    | "STREAM_MODERATION"
    | "EXPERTISE"
    | "ORDER_PREPAYMENTS"
    | "ORDER_RETURN_COMPLETED"
    | "CURRENCY_RATE_MODERATION"
    | "ORDER_RESOLVE_DISPUTE"
    | "ORDER_EXPERTISE_FINISH_ANY_DEFECTS_NEGOTIATION"
    | "BOUTIQUE_SALES"
    | "ORDER_MARKING_CODES"
    | "PAYOUT_BY_CASH"
    | "CUSTOM_COMMISSION"
    | "LOGISTIC"
    | "PRODUCTS_BULK_EDIT"
    | "SALE_REQUEST_MODERATION"
    | "LOGISTIC_SEND_DELIVERY_COMPANY_MAIL_REQUEST"
    | "ORDER_REFUND_AMOUNT"
    | "ORDER_MANUAL_TRANSFER"
    | "SPLIT_ORDER"
    | "MARK_ORDER_AS_PREPARATION_FOR_PUBLICATION_REQUIRED"
    | "ORDER_VIEW_ALL_ORDER_SOURCES"
    | "ORDER_BOUTIQUE_0ST_ACTION"
    | "ORDER_BOUTIQUE_1ST_ACTION"
    | "ORDER_BOUTIQUE_2ND_ACTION"
    | "ORDER_VIEW_LIST_BY_LOCATION"
    | "ORDER_MANUAL_CHANGE_DELIVERY_STATE"
    | "ORDER_MANUAL_CANCEL"
    | "FAST_AUTO_SHIPMENT_TO_BOUTIQUE"
    | "ORDER_BOUTIQUE_PACKING_STEP"
    | "TEST_AUTHORITY_00"
    | "ORDER_SELLER_CONCIERGE_MOVE_TO_BOUTIQUE"
    | "USER_CHANGE_MAIL"
    | "USER_DELETE"
    | "COMMISSION_MODERATION"
    | "GAZPROM_BONUS"
  )[];
  avatarPath?: string;
  bans?: UserBanDTORes[];
  canPublishMultiSizes?: boolean;
  email?: string;
  firstChar?: string;
  fullName?: string;
  /** @format int64 */
  id?: number;
  isFollowed?: boolean;
  isPro?: boolean;
  isTrusted?: boolean;
  isNew?: boolean;
  name?: string;
  nickname?: string;
  sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
}

/** UserBanDTOReq */
export interface UserBanDTOReq {
  banType?: "COMMENT_BAN" | "PUBLISH_BAN" | "STORIES_BAN" | "USER_BAN" | "WARNING";
  description?: string;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  timeToEndBan?: number;
  title?: string;
}

/** UserBanDTORes */
export interface UserBanDTORes {
  banType?: "COMMENT_BAN" | "PUBLISH_BAN" | "STORIES_BAN" | "USER_BAN" | "WARNING";
  banned?: boolean;
  description?: string;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  timeToEndBan?: number;
  title?: string;
}

/** UserfileDTO */
export interface UserfileDTO {
  /** @format int64 */
  id?: number;
  originalFilename?: string;
  path?: string;
  title?: string;
}

/** CategoryDTOV3 */
export interface CategoryDTOV3 {
  /** @format int64 */
  id?: number;
  path?: string[];
  displayName?: string;
}

/** SizeValueDTOV3 */
export interface SizeValueDTOV3 {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  value?: number;
  name?: string;
}

/** ProductSizeDTOV3 */
export interface ProductSizeDTOV3 {
  /** @format int64 */
  id?: number;
  additionalSizeValues?: SizeValueDTOV3[];
  /** @format int32 */
  count?: number;
  name?: string;
}

/** ProductDTOV3 */
export interface ProductDTOV3 {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createTime?: string;
  /** @format date-time */
  exclusiveSelectionTime?: string;
  /** @format date-time */
  sendToModeratorTime?: string;
  /** @format date-time */
  productStateTime?: string;
  /** @format date-time */
  changeTime?: string;
  brand: BrandDTOV3;
  image?: ProductImageV3;
  /** @format int64 */
  categoryId: number;
  seller?: UserDTOV3;
  retoucher?: UserDTOV3;
  /** @format int64 */
  productConditionId?: number;
  name?: string;
  productState: ProductState;
  sizeType?: SizeType;
  salesChannel?: SalesChannel;
  description?: string;
  paymentDescription?: string;
  deliveryDescription?: string;
  origin?: string;
  /** @format bigdecimal */
  purchasePrice?: number;
  /** @format int64 */
  purchaseYear?: number;
  /** @format date-time */
  publishTime?: string;
  /** @format date-time */
  promotionTime?: string;
  /** @format date-time */
  ourChoiceStatusTime?: string;
  /** @format date-time */
  beegzStatusTime?: string;
  /** @format date-time */
  selectedConciergeTime?: string;
  /** @format date-time */
  showAsConciergeTime?: string;
  /** @format date-time */
  forMerchandisingTime?: string;
  vintage?: boolean;
  model?: string;
  turbo?: boolean;
  newCollection?: boolean;
  atOffice?: boolean;
  /** @format int64 */
  slidesCount?: number;
  sourceLink?: string;
  /** @format bigdecimal */
  rrpPrice?: number;
  /** @format bigdecimal */
  startPrice?: number;
  /** @format bigdecimal */
  currentPrice?: number;
  /** @format bigdecimal */
  currentPriceInCurrency?: number;
  /** @format int64 */
  currentPriceCurrencyId?: number;
  /** @format date-time */
  lastPriceConvertTime?: string;
  /** @format bigdecimal */
  currentPriceWithoutCommission?: number;
  /** @format bigdecimal */
  fixedCommissionAmount?: number;
  category?: CategoryDTOV3;
  vendorCode?: string;
  serialNumber?: string;
  storeCode?: string;
  customCommission?: boolean;
  slaFlag?: boolean;
  sizeList?: ProductSizeDTOV3[];
  images?: ProductImageV3[];
  attributes?: AttributeDTOV3[];
  defectImages?: ProductDefectImageV3[];
  rejectReason?: RejectReasonDTOV3;
  productModel?: ProductModelDTOV3;
  bitrixDealId?: string;
  /** @format date-time */
  usedInSaleRequestTime?: string;
  discountPossible?: boolean;
  activeDiscount?: boolean;
  commission?: CommissionDTOV3;
  commissionGrid?: CommissionGridDTOV3;
}

/** ProductHistoryDTOV3 */
export interface ProductHistoryDTOV3 {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  createTime?: string;
  /** @format date-time */
  exclusiveSelectionTime?: string;
  /** @format date-time */
  sendToModeratorTime?: string;
  /** @format date-time */
  productStateTime?: string;
  /** @format date-time */
  changeTime?: string;
  /** @format int64 */
  brandId?: number;
  /** @format int64 */
  productModelId?: number;
  /** @format int64 */
  imageId?: number;
  /** @format int64 */
  categoryId?: number;
  /** @format int64 */
  sellerId?: number;
  /** @format int64 */
  retoucherId?: number;
  /** @format int64 */
  productConditionId?: number;
  name?: string;
  productState?: ProductState;
  sizeType?: SizeType;
  salesChannel?: SalesChannel;
  description?: string;
  paymentDescription?: string;
  deliveryDescription?: string;
  origin?: string;
  /** @format bigdecimal */
  purchasePrice?: number;
  /** @format int64 */
  purchaseYear?: number;
  /** @format date-time */
  publishTime?: string;
  /** @format date-time */
  promotionTime?: string;
  /** @format date-time */
  ourChoiceStatusTime?: string;
  /** @format date-time */
  beegzStatusTime?: string;
  /** @format date-time */
  selectedConciergeTime?: string;
  /** @format date-time */
  showAsConciergeTime?: string;
  /** @format date-time */
  forMerchandisingTime?: string;
  vintage?: boolean;
  model?: string;
  turbo?: boolean;
  newCollection?: boolean;
  atOffice?: boolean;
  /** @format int64 */
  slidesCount?: number;
  sourceLink?: string;
  /** @format bigdecimal */
  rrpPrice?: number;
  /** @format bigdecimal */
  startPrice?: number;
  /** @format bigdecimal */
  currentPrice?: number;
  /** @format bigdecimal */
  currentPriceInCurrency?: number;
  /** @format int64 */
  currentPriceCurrencyId?: number;
  /** @format date-time */
  lastPriceConvertTime?: string;
  /** @format bigdecimal */
  fixedCommissionAmount?: number;
  /** @format bigdecimal */
  currentPriceWithoutCommission?: number;
  vendorCode?: string;
  serialNumber?: string;
  storeCode?: string;
  customCommission?: boolean;
  slaFlag?: boolean;
  sizeList?: ProductSizeDTOV3[];
  images?: ProductImageV3[];
  defectImages?: ProductDefectImageV3[];
  attributeValues?: AttributeValueHistoryDTOV3[];
  productItems?: ProductItemDTOV3[];
  commission?: CommissionDTOV3;
  categoryPath?: string;
  productConditionName?: string;
  brandName?: string;
  productModelName?: string;
  productStateName?: string;
  salesChannelName?: string;
  currencyName?: string;
  sizeTypeName?: string;
  /** @format date-time */
  usedInSaleRequestTime?: string;
}

/** ProductEditRequestV3 */
export interface ProductEditRequestV3 {
  /** @format int64 */
  id: number;
  /** @format int64 */
  brandId?: number;
  /** @format int64 */
  categoryId?: number;
  /** @format int64 */
  sellerId?: number;
  /** @format int64 */
  retoucherId?: number;
  /** @format int64 */
  productConditionId?: number;
  name?: string;
  productState?: ProductState;
  sizeType?: SizeType;
  description?: string;
  paymentDescription?: string;
  deliveryDescription?: string;
  origin?: string;
  /** @format bigdecimal */
  purchasePrice?: number;
  /** @format int64 */
  purchaseYear?: number;
  vintage?: boolean;
  model?: string;
  turbo?: boolean;
  newCollection?: boolean;
  atOffice?: boolean;
  /** @format int64 */
  slidesCount?: number;
  sourceLink?: string;
  /** @format bigdecimal */
  rrpPrice?: number;
  /** @format bigdecimal */
  startPrice?: number;
  ourChoice?: boolean;
  beegz?: boolean;
  selectedForConcierge?: boolean;
  showAsConcierge?: boolean;
  forMerchandising?: boolean;
  vendorCode?: string;
  serialNumber?: string;
  storeCode?: string;
  sizeCounts?: Record<string, number>;
  additionalSizeValues?: Record<string, Record<string, number>>;
  imageOrderIds?: number[];
  defectImagesOrders?: ProductImageDTO[];
  attributes?: ProductAttributeValue[];
  rejectReason?: SaveProductRejectReasonRequest;
  /** @format int64 */
  productModelId?: number;
  productPriceContainer?: ProductPriceDTOV3;
  bitrixDealId?: string;
  exclusiveSelection?: boolean;
  discountSwitcher?: boolean;
}

/** ProductPriceDTOV3 */
export interface ProductPriceDTOV3 {
  salesChannel?: SalesChannel;
  /** @format bigdecimal */
  currentPrice?: number;
  /** @format bigdecimal */
  sellerPrice?: number;
  customCommission?: boolean;
  /** @format bigdecimal */
  currentPriceInCurrency?: number;
  /** @format int64 */
  currentPriceCurrencyId?: number;
}

/** ProductState */
export enum ProductState {
  BANED = "BANED",
  DELETED = "DELETED",
  DRAFT = "DRAFT",
  HIDDEN = "HIDDEN",
  NEED_MODERATION = "NEED_MODERATION",
  NEED_RETOUCH = "NEED_RETOUCH",
  PUBLISHED = "PUBLISHED",
  REJECTED = "REJECTED",
  RETOUCH_DONE = "RETOUCH_DONE",
  SECOND_EDITION = "SECOND_EDITION",
  SOLD = "SOLD",
}

/** SalesChannel */
export enum SalesChannel {
  WEBSITE = "WEBSITE",
  BOUTIQUE_AND_WEBSITE = "BOUTIQUE_AND_WEBSITE",
  STOCK_AND_BOUTIQUE_AND_WEBSITE = "STOCK_AND_BOUTIQUE_AND_WEBSITE",
}

/** SizeType */
export enum SizeType {
  AGE = "AGE",
  AU = "AU",
  CENTIMETERS = "CENTIMETERS",
  COLLAR_CENTIMETERS = "COLLAR_CENTIMETERS",
  COLLAR_INCHES = "COLLAR_INCHES",
  DE = "DE",
  EU = "EU",
  FR = "FR",
  HEIGHT = "HEIGHT",
  INCHES = "INCHES",
  INT = "INT",
  IT = "IT",
  JEANS = "JEANS",
  JPN = "JPN",
  NO_SIZE = "NO_SIZE",
  RING_EUROPEAN = "RING_EUROPEAN",
  RING_RUSSIAN = "RING_RUSSIAN",
  RU = "RU",
  UK = "UK",
  US = "US",
}

/** ProductAttributeValue */
export interface ProductAttributeValue {
  /** @format int64 */
  attributeId?: number;
  /** @format int64 */
  attributeValueId?: number;
}

/** ProductAttributeValue */
export interface SaveProductRejectReasonRequest {
  /** @format bigdecimal */
  price?: number;
  priceComment?: string;
  imageIds?: number[];
  imageComment?: string;
  descriptionComment?: string;
  otherComment?: string;
  rejectionComment?: string;
}

/** ProductImage */
export interface ProductImage {
  /** @format int64 */
  id?: number;
  url?: string;
  smallImageUrl?: string;
  largeImageUrl?: string;
  isPrimary?: boolean;
  originalImageUrl?: string;
  /** @format int64 */
  photoOrder?: number;
  comment?: string;
}

/** ProductDefectImageV3 */
export interface ProductDefectImageV3 {
  /** @format int64 */
  id?: number;
  url?: string;
  smallImageUrl?: string;
  largeImageUrl?: string;
  primary?: boolean;
  originalImageUrl?: string;
  /** @format int64 */
  photoOrder?: number;
  comment?: string;
  thumbnailUrl?: string;
}

/** RejectReasonDTOV3 */
export interface RejectReasonDTOV3 {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  rejectorId?: number;
  /** @format bigdecimal */
  price?: number;
  /** @format bigdecimal */
  oldPrice?: number;
  priceComment?: string;
  imageComment?: string;
  oldDescription?: string;
  descriptionComment?: string;
  otherComment?: string;
  images?: ProductImageV3[];
}

/** ProductImageV3 */
export interface ProductImageV3 {
  /** @format int64 */
  id?: number;
  url?: string;
  smallImageUrl?: string;
  largeImageUrl?: string;
  primary?: boolean;
  originalImageUrl?: string;
  /** @format int64 */
  photoOrder?: number;
  comment?: string;
  thumbnailUrl?: string;
}

/** AttributeValueHistoryDTOV3 */
export interface AttributeValueHistoryDTOV3 {
  /** @format int64 */
  id?: number;
  value?: string;
  /** @format int64 */
  attributeId?: number;
}

/** ProductItemDTOV3 */
export interface ProductItemDTOV3 {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  sizeId?: number;
  /** @format date-time */
  deleteTime?: string;
  /** @format int32 */
  count?: number;
  isHidden?: boolean;
  /** @format date-time */
  inBoutique?: string;
  barcode?: string;
  additionalSizeValues?: ProductItemAdditionalSizeValueDTOV3[];
}

/** CommissionDTOV3 */
export interface CommissionDTOV3 {
  /** @format int64 */
  id?: number;
  /** @format bigdecimal */
  publicPrice?: number;
  /** @format bigdecimal */
  value?: number;
  /** @format bigdecimal */
  boutiqueValue?: number;
  /** @format bigdecimal */
  valueScaled?: number;
  /** @format bigdecimal */
  boutiqueValueScaled?: number;
  /** @format int64 */
  commissionGridId?: number;
}

/** ProductItemAdditionalSizeValueDTOV3 */
export interface ProductItemAdditionalSizeValueDTOV3 {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  additionalSizeId?: number;
  /** @format int32 */
  value?: number;
}

/** BrandDTOV3 */
export interface BrandDTOV3 {
  /** @format int64 */
  id?: number;
  name?: string;
  url?: string;
  infoText?: string;
  isHidden?: boolean;
  transliterateName?: string;
  title?: string;
  hiddenDescription?: string;
}

/** CommissionGridDTOV3 */
export interface CommissionGridDTOV3 {
  /** @format int64 */
  id?: number;
  name?: string;
  type?: Type;
  commissions?: CommissionDTOV3[];
  /** @format date-time */
  deleteTime?: string;
  /** @format double */
  fixedAmount?: number;
}

export enum Type {
  DEFAULT = "DEFAULT",
  PRO = "PRO",
  CUSTOM = "CUSTOM",
}

/** UserDTOV3 */
export interface UserDTOV3 {
  /** @format int64 */
  id?: number;
  email?: string;
  phone?: string;
  nickname?: string;
  /** @format date-time */
  registrationTime?: string;
  /** @format date-time */
  activationTime?: string;
  sex?: Sex;
  /** @format date-time */
  birthDate?: string;
  isTrusted?: boolean;
  /** @format int64 */
  countryId?: number;
  /** @format int64 */
  pickupCountryId?: number;
  userType?: UserType;
  position?: string;
  sellerType?: SellerType;
  celebrity?: boolean;
  bestFriend?: boolean;
  legalEntity?: boolean;
  vip?: boolean;
  pro?: boolean;
  banned?: boolean;
  firstName?: string;
  lastName?: string;
  admin?: boolean;
  agentSeller?: boolean;
  deleted?: boolean;
  moderator?: boolean;
  avatarPath?: string;
  tags?: string[];
  commissionGrid?: CommissionGridDTOV3;
}

/** AttributeDTOV3 */
export interface AttributeDTOV3 {
  /** @format int64 */
  id?: number;
  name?: string;
  values?: AttributeValueDTOV3[];
}

/** KafkaProductHistoryMessage */
export interface KafkaProductHistoryMessage {
  /** @format uuid */
  uuid?: string;
  /** @format int64 */
  productId?: number;
  /** @format date-time */
  editDateTime?: string;
  old?: ProductHistoryDTOV3;
  current?: ProductHistoryDTOV3;
  /** @format int64 */
  user?: number;
}

/** AttributeValueDTOV3 */
export interface AttributeValueDTOV3 {
  /** @format int64 */
  id?: number;
  value?: string;
  icon?: string;
  active?: boolean;
}

/** PredicateType */
export enum PredicateType {
  AND = "AND",
  OR = "OR",
}

/** Sex */
export enum Sex {
  ADULT = "ADULT",
  BOY = "BOY",
  CHILD = "CHILD",
  FEMALE = "FEMALE",
  GIRL = "GIRL",
  MALE = "MALE",
}

/** UserType */
export enum UserType {
  SIMPLE_USER = "SIMPLE_USER",
  IP = "IP",
  OOO = "OOO",
}

/** SellerType */
export enum SellerType {
  CONSIGNMENT_SHOP = "CONSIGNMENT_SHOP",
  INDIVIDUAL = "INDIVIDUAL",
  MULTI_BRAND = "MULTI_BRAND",
  MONO_BRAND = "MONO_BRAND",
  BUYER = "BUYER",
  RESELLER = "RESELLER",
}

/** AuthorityName */
export enum AuthorityName {
  ADMIN = "ADMIN",
  PRODUCT_MODERATION = "PRODUCT_MODERATION",
  USER_MODERATION = "USER_MODERATION",
  USERFILE_MODERATION = "USERFILE_MODERATION",
  AUTHORITY_MODERATION = "AUTHORITY_MODERATION",
  CONTENT_CREATE = "CONTENT_CREATE",
  CONTENT_DELETE = "CONTENT_DELETE",
  ORDER_MODERATION = "ORDER_MODERATION",
  CAN_VIEW_ALL_PRODUCTS = "CAN_VIEW_ALL_PRODUCTS",
  OFFER_MODERATION = "OFFER_MODERATION",
  RETOUCHING_MODERATION = "RETOUCHING_MODERATION",
  COMMENT_MODERATION = "COMMENT_MODERATION",
  PROMOCODES_MODERATION = "PROMOCODES_MODERATION",
  CURRENCY_RATE_MODERATION = "CURRENCY_RATE_MODERATION",
  CONTENT_MODERATION = "CONTENT_MODERATION",
  MASTER_USER = "MASTER_USER",
  STORY_MODERATION = "STORY_MODERATION",
  ORDER_RETURNS = "ORDER_RETURNS",
  ORDER_PAYOUTS = "ORDER_PAYOUTS",
  ORDER_CONCIERGE_PAYOUTS = "ORDER_CONCIERGE_PAYOUTS",
  STREAM_MODERATION = "STREAM_MODERATION",
  EXPERTISE = "EXPERTISE",
  ORDER_PREPAYMENTS = "ORDER_PREPAYMENTS",
  ORDER_RETURN_COMPLETED = "ORDER_RETURN_COMPLETED",
  ORDER_RESOLVE_DISPUTE = "ORDER_RESOLVE_DISPUTE",
  ORDER_EXPERTISE_FINISH_ANY_DEFECTS_NEGOTIATION = "ORDER_EXPERTISE_FINISH_ANY_DEFECTS_NEGOTIATION",
  BOUTIQUE_SALES = "BOUTIQUE_SALES",
  ORDER_MARKING_CODES = "ORDER_MARKING_CODES",
  USER_BALANCE_CHANGE = "USER_BALANCE_CHANGE",
  PAYOUT_BY_CASH = "PAYOUT_BY_CASH",
  CUSTOM_COMMISSION = "CUSTOM_COMMISSION",
  LOGISTIC = "LOGISTIC",
  SALE_REQUEST_MODERATION = "SALE_REQUEST_MODERATION",
  PRODUCTS_BULK_EDIT = "PRODUCTS_BULK_EDIT",
  LOGISTIC_SEND_DELIVERY_COMPANY_MAIL_REQUEST = "LOGISTIC_SEND_DELIVERY_COMPANY_MAIL_REQUEST",
}

/** ProductModelDTOV3 */
export interface ProductModelDTOV3 {
  /** @format int64 */
  id?: number;
  name?: string;
}

/** SeoDataDTO */
export interface SeoDataDTO {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  categoryId?: number;
  /** @format int64 */
  brandId?: number;
  textContent?: string;
  headerContent?: string;
  linkGroups?: LinkGroupDTO[];
}

/** LinkGroupDTO */
export interface LinkGroupDTO {
  /** @format int64 */
  id?: number;
  name?: string;
  links?: LinkDTO[];
}

/** LinkDTO */
export interface LinkDTO {
  /** @format int64 */
  id?: number;
  linkUrl?: string;
  linkContent?: string;
}

/** EnumLabelPairDTO */
export interface EnumLabelPairDTO {
  value?: string;
  label?: string;
}

/** ProductConditionResponseDTO */
export interface ProductConditionResponseDTO {
  /** @format int64 */
  id?: number;
  name?: string;
  /** @format int64 */
  sortOrder?: number;
}

/** SizeTypeResponseDTO */
export interface SizeTypeResponseDTO {
  code?: string;
  comment?: string;
  isSizable?: boolean;
  name?: string;
}

/** IdLabelPairDTO */
export interface IdLabelPairDTO {
  /** @format int32 */
  id?: number;
  label?: string;
}

/** WaybillDTO */
export interface WaybillDTO {
  deliveryDestinationType?: "BUYER" | "OFFICE" | "SELLER";
  externalSystemId?: string;
  /** @format int64 */
  id?: number;
  /** @format date-time */
  pickupDate?: string;
  pickupDestinationType?: "BUYER" | "OFFICE" | "SELLER";
  pickupInterval?: string;
}

/**
 * WelcomePage
 * Базовая информация о контрторгах
 */
export interface WelcomePage {
  /** Пункты (параграфы) */
  items: BargainMessageDTO[];
  /** Заголовок */
  title: string;
}

/** WorldTrends */
export interface WorldTrends {
  base?: PrimaryCategory;
  filter?: WorldTrendsFilter;
  id?: string;
  image?: string;
  /** @format int64 */
  productsTotal?: number;
}

/** WorldTrendsFilter */
export interface WorldTrendsFilter {
  attributes?: Record<string, object>[];
  attributesValuesIds?: number[];
  brand?: PrimaryBrand;
  category?: PrimaryCategory;
  color?: PrimaryAttributeValue;
  material?: PrimaryAttributeValue;
  parentIds?: number[];
  podcategory?: PrimaryCategory;
  subcategory?: PrimaryCategory;
}

/** Api2ResponseOfListOfProductConditionDTO */
export interface Api2ResponseOfListOfProductConditionDTO1 {
  data?: ProductConditionDTO1[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfPriceConversionResponseDTO */
export interface Api2ResponseOfPriceConversionResponseDTO {
  data?: PriceConversionResponseDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ProductConditionDTO */
export interface ProductConditionDTO1 {
  /** @format int64 */
  id?: number;
  name?: string;
}

/** BonusDto */
export interface BonusDto {
  /** @format bigdecimal */
  amount?: number;
  phone?: string;
  bonusVendor?: string;
}

/** Api2ResponseOfAddressEndpointAggregationListResult */
export interface Api2ResponseOfAddressEndpointAggregationListResult {
  data?: AddressEndpointAggregationResponseDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfAddressEndpointAggregationResult */
export interface Api2ResponseOfAddressEndpointAggregationResult {
  data?: AddressEndpointAggregationResponseDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfCounterpartyDTO */
export interface Api2ResponseOfListOfCounterpartyDTO {
  data?: CounterpartyDTO[];
}

/** Api2ResponseOfCountriesDTO */
export interface Api2ResponseOfCountriesDTO {
  data?: CountryDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfCountryDTO */
export interface Api2ResponseOfListOfCountriesDTO {
  data?: CountryDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfCityDTO */
export interface Api2ResponseOfListOfCityDTO {
  data?: CityDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfGetAllCurrencyResult */
export interface Api2ResponseOfGetAllCurrencyResult {
  data?: CurrencyDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfGetAllCurrencyRatesResult */
export interface Api2ResponseOfGetAllCurrencyRatesResult {
  data?: CurrencyRateDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfListOfDateWithIntervalsDTO */
export interface Api2ResponseOfListOfDateWithIntervalsDTO {
  data?: DateWithIntervalsDTO[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** DateWithIntervalsDTO */
export interface DateWithIntervalsDTO {
  /** @format date-time */
  date?: string;
  timeIntervals?: TimeIntervalDTO[];
}

/** CurrencyRateDTO */
export interface CurrencyRateDTO {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  currencyId?: number;
  /** @format bigdecimal */
  rateValue?: number;
  /** @format bigdecimal */
  commission?: number;
  /** @format date-time */
  lastRateUpdateTime?: string;
}

/** DateWithIntervalsDTO */
export interface TimeIntervalDTO {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  fromHour?: number;
  /** @format int32 */
  toHour?: number;
  displayName?: string;
}

/** OrderTrackDTO */
export interface OrderTrackDTO {
  orderStages?: OrderStageDTO[];
}

/** OrderStageDTO */
export interface OrderStageDTO {
  /** determines what should be done with an order during the stage (a set of actions which should be applied to an order), a logic */
  type:
    | "SELLER_CONFIRMATION"
    | "DELIVERY_TO_OFFICE"
    | "EXPERTISE"
    | "DELIVERY_TO_BUYER"
    | "DELIVERY_TO_BOUTIQUE"
    | "BUYER_CONFIRMATION"
    | "SOLD_IN_BOUTIQUE"
    | "PAYOUT"
    | "RETURNED_TO_SELLER";
  /** shows the progress of the stage */
  progressState: "UPCOMING" | "IN_PROGRESS" | "COMPLETE";
  /** what the stage ended up, can be filled after the stage is complete */
  successState?: "SUCCEEDED" | "PARTIALLY_SUCCEEDED" | "FAILED";
  /** the name of the stage a user sees */
  title: string;
  /** describes the actions have being acted with the order at the moment (regular font, a text under the title) */
  description?: string;
  /** a list of highlighted positions after confirmation/expertises stage, sometime user can do smth with them */
  positions?: PositionDetailsDTO[];
  /** Something to be highlighted to the user or the expected date of completion (Grey font, a text under the title) */
  comment?: string;
  /**
   * the date-time of completion an order
   * @format date-time
   */
  updatedAt?: string;
  /**
   * the expected date-time of completion an order
   * @format date-time
   */
  estimatedDateTime?: string;
  /** Order action associated with the stage */
  action?: OrderTrackActionDTO;
}

/** OrderTrackActionDTO */
export interface OrderTrackActionDTO {
  /** Action type */
  type:
    | "CHANGE_PICKUP_DATE"
    | "PAY_OSKELLY_SERVICE"
    | "GO_TO_NEGOTIATION"
    | "GO_TO_DETAILS_INFO"
    | "CHANGE_DELIVERY_DATE"
    | "CONFIRM_DELIVERY"
    | "CONFIRM_PAYOUT_DETAILS";
  /** Action display name (e.g. caption on the button for the action) */
  title: string;
}

/** PositionDetailsDTO */
export interface PositionDetailsDTO {
  text?: string;
  /** @format int64 */
  positionId?: number;
  /** Action on order position */
  action?: OrderTrackActionDTO;
}

/** ProductsSalesRequest */
export interface ProductsSalesRequest {
  salesRequestId?: string;
  productIds?: number[];
}

/**
 * FilterValue
 * Значение фильтра для запроса сортировки
 */
export type FilterValue = boolean | number[] | string | string[] | PriceValue;

/**
 * PriceValue
 * Значение фильтра по цене для запроса сортировки
 */
export interface PriceValue {
  lower?: number;
  upper?: number;
}

/** Api2Response«List«UserDTO»» */
export interface Api2ResponseOfListOfUserDTO1 {
  data?: UserDTO1[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** UserDTO */
export interface UserDTO1 {
  avatarPath?: string;
  /** @format int64 */
  birthDate?: number;
  /** @format int32 */
  brandLikesCount?: number;
  email?: string;
  firstChar?: string;
  fullName?: string;
  /** @format int64 */
  id?: number;
  isFollowed?: boolean;
  isPro?: boolean;
  isTrusted?: boolean;
  /** @format int32 */
  likesCount?: number;
  name?: string;
  nickname?: string;
  /** @format int32 */
  productLikesCount?: number;
  /** @format int32 */
  productsCount?: number;
  /** @format int64 */
  registrationTime?: number;
  sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
  commonTags?: UserCommonTagDTO[];
}

/** Api2_1ResponseOfMyStreamListResponseDTO */
export interface Api21ResponseOfMyStreamListResponseDTO {
  data?: MyStreamListResponseDTO;
  error?: ErrorOfMyStreamListResponseDTO;
  /** @format int64 */
  executionTimeMillis?: number;
  /** @format int64 */
  timestamp?: number;
}

/** Api2_1ResponseOfPageOfStreamItemDTO */
export interface Api21ResponseOfPageOfStreamItemDTO {
  data?: PageOfStreamItemDTO;
  error?: ErrorOfPageOfStreamItemDTO;
  /** @format int64 */
  executionTimeMillis?: number;
  /** @format int64 */
  timestamp?: number;
}

/** Api2_1ResponseOfStreamAdminPageDetailedResponseDTO */
export interface Api21ResponseOfStreamAdminPageDetailedResponseDTO {
  data?: StreamAdminPageDetailedResponseDTO;
  error?: ErrorOfStreamAdminPageDetailedResponseDTO;
  /** @format int64 */
  executionTimeMillis?: number;
  /** @format int64 */
  timestamp?: number;
}

/** Api2_1ResponseOfStreamDetailedResponseDTO */
export interface Api21ResponseOfStreamDetailedResponseDTO {
  data?: StreamDetailedResponseDTO;
  error?: ErrorOfStreamDetailedResponseDTO;
  /** @format int64 */
  executionTimeMillis?: number;
  /** @format int64 */
  timestamp?: number;
}

/** Api2_1ResponseOfboolean */
export interface Api21ResponseOfboolean {
  data?: boolean;
  error?: ErrorOfboolean;
  /** @format int64 */
  executionTimeMillis?: number;
  /** @format int64 */
  timestamp?: number;
}

/** Api2_1ResponseOflong */
export interface Api21ResponseOflong {
  /** @format int64 */
  data?: number;
  error?: ErrorOflong;
  /** @format int64 */
  executionTimeMillis?: number;
  /** @format int64 */
  timestamp?: number;
}

/** Available */
export interface Available {
  description?: string;
  howItWorksUrl?: string;
  title?: string;
}

/** ErrorOfMyStreamListResponseDTO */
export interface ErrorOfMyStreamListResponseDTO {
  code?: "BAN" | "LOGIC" | "VALIDATION";
  data?: MyStreamListResponseDTO;
  humanMessage?: string;
}

/** ErrorOfPageOfStreamItemDTO */
export interface ErrorOfPageOfStreamItemDTO {
  code?: "BAN" | "LOGIC" | "VALIDATION";
  data?: PageOfStreamItemDTO;
  humanMessage?: string;
}

/** ErrorOfStreamAdminPageDetailedResponseDTO */
export interface ErrorOfStreamAdminPageDetailedResponseDTO {
  code?: "BAN" | "LOGIC" | "VALIDATION";
  data?: StreamAdminPageDetailedResponseDTO;
  humanMessage?: string;
}

/** ErrorOfStreamDetailedResponseDTO */
export interface ErrorOfStreamDetailedResponseDTO {
  code?: "BAN" | "LOGIC" | "VALIDATION";
  data?: StreamDetailedResponseDTO;
  humanMessage?: string;
}

/** ErrorOfboolean */
export interface ErrorOfboolean {
  code?: "BAN" | "LOGIC" | "VALIDATION";
  data?: boolean;
  humanMessage?: string;
}

/** ErrorOflong */
export interface ErrorOflong {
  code?: "BAN" | "LOGIC" | "VALIDATION";
  /** @format int64 */
  data?: number;
  humanMessage?: string;
}

/** MyStreamListResponseDTO */
export interface MyStreamListResponseDTO {
  availability?: "AVAILABLE" | "UNAVAILABLE";
  available?: Available;
  items?: PageOfStreamItemDTO;
  unavailable?: Unavailable;
}

/** PageOfStreamItemDTO */
export interface PageOfStreamItemDTO {
  items?: StreamItemDTO[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
}

/** Payload */
export interface Payload {
  author?: string;
  /** @format int64 */
  created?: number;
  customData?: string;
  /** @format int32 */
  height?: number;
  id?: string;
  ingestChannel?: string;
  /** @format double */
  lat?: number;
  /** @format int32 */
  length?: number;
  /** @format double */
  lon?: number;
  /** @format int32 */
  positionAccuracy?: number;
  positionType?: string;
  preview?: string;
  resourceUri?: string;
  tags?: object[];
  title?: string;
  type?: string;
  /** @format int32 */
  width?: number;
}

/** StreamAdminPageDetailedResponseDTO */
export interface StreamAdminPageDetailedResponseDTO {
  /** Информация об авторе эфира */
  author: StreamAuthorDTO;
  /** Тип обложки эфира */
  cover: "COVER_1" | "COVER_2" | "COVER_3" | "COVER_4";
  /** Описание эфира */
  description: string;
  /**
   * Первичный ключ эфира
   * @format int64
   */
  id: number;
  /**
   * Список товаров которые указанны в эфире
   * @uniqueItems true
   */
  products: StreamAdminPageProductDTO[];
  /** URI для просмотра эфира */
  resourceURI?: string;
  /**
   * Дата начала прямого эфира
   * @format date-time
   */
  startingDate: string;
  /** Статус эфира */
  status: "ANNOUNCE" | "ARCHIVED" | "BANNED" | "LIVE";
  /** Заголовок эфира */
  title: string;
}

/** StreamAdminPageProductBrandDTO */
export interface StreamAdminPageProductBrandDTO {
  /**
   * Первичный ключ бренда
   * @format int64
   */
  id: number;
  /** Наименование бренда */
  name: string;
}

/** StreamAdminPageProductCategoryDTO */
export interface StreamAdminPageProductCategoryDTO {
  /** Наименование категории */
  displayName: string;
  /**
   * Первичный ключ категории
   * @format int64
   */
  id: number;
}

/** StreamAdminPageProductDTO */
export interface StreamAdminPageProductDTO {
  /** Данные о бренде товар */
  brand: StreamAdminPageProductBrandDTO;
  /** Данные о категории товар */
  category: StreamAdminPageProductCategoryDTO;
  /**
   * С биркой ли товар
   * @format int64
   */
  conditionId: number;
  /**
   * Скидка в процентах на товар
   * @format int32
   */
  discount?: number;
  /**
   * Старая цена товара
   * @format int32
   */
  higherPrice?: number;
  /**
   * Первичный ключ товара
   * @format int64
   */
  id: number;
  /**
   * Количество лайков к данному товару
   * @format int32
   */
  likesCount: number;
  /**
   * Цена товара
   * @format int32
   */
  price: number;
  /** Путь к фотографии товара */
  primaryImageUrl: string;
  /** Данные о размерах товар */
  sizes: StreamAdminPageProductSizeDTO[];
}

/** StreamAdminPageProductSizeDTO */
export interface StreamAdminPageProductSizeDTO {
  /**
   * Первичный ключ размера товара
   * @format int64
   */
  id: number;
  /** Тип размера товара */
  productSizeType:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  /** Размер товара */
  productSizeValue: string;
  /** Статус товара по данному размеру */
  purchaseStatus: "AVAILABLE" | "IN_CART" | "OUT_OF_STOCK" | "PURCHASED";
}

/** StreamAuthorDTO */
export interface StreamAuthorDTO {
  /** Путь к фотографии пользователя */
  avatarPath: string;
  /**
   * Первичный ключ пользователя
   * @format int64
   */
  id: number;
  /**
   * Подписан ли пользователь, на данного пользователя
   * @example false
   */
  isSubscribed: boolean;
  /**
   * Доверенный ли пользователь
   * @example false
   */
  isTrusted: boolean;
  /** Ник нейм пользователя */
  nickName: string;
  /** Тип пользователя / Бутик/Частный продавец */
  type: string;
}

/** StreamCreateOrUpdateRequestDTO */
export interface StreamCreateOrUpdateRequestDTO {
  /** Тип обложки эфира */
  cover: "COVER_1" | "COVER_2" | "COVER_3" | "COVER_4";
  /** Описание эфира */
  description: string;
  /** Первичные ключи продуктов указанные в эфире */
  productIds: number[];
  /**
   * Дата начала прямого эфира
   * @format date-time
   */
  startingDate?: string;
  /** Заголовок эфира */
  title: string;
}

/** StreamDetailedResponseDTO */
export interface StreamDetailedResponseDTO {
  /** Информация об авторе эфира */
  author: StreamAuthorDTO;
  /**
   * Может ли пользователь удалить эфир, для пользователя который создал эфир
   * @example false
   */
  canDelete?: boolean;
  /**
   * Может ли пользователь начать эфир, для пользователя который создал эфир
   * @example false
   */
  canStart?: boolean;
  /**
   * Может ли пользователь обновить эфир, для пользователя который создал эфир
   * @example false
   */
  canUpdate?: boolean;
  /** Тип обложки эфира */
  cover: "COVER_1" | "COVER_2" | "COVER_3" | "COVER_4";
  /** Описание эфира */
  description: string;
  /**
   * Первичный ключ эфира
   * @format int64
   */
  id: number;
  /**
   * Подписан ли пользователь на данный эфир
   * @example false
   */
  isSubscribed?: boolean;
  /**
   * Список товаров которые указанны в эфире
   * @uniqueItems true
   */
  products: StreamProductDTO[];
  /** URI для просмотра эфира */
  resourceURI: string;
  /**
   * Дата начала прямого эфира
   * @format date-time
   */
  startingDate: string;
  /** Статус эфира */
  status: "ANNOUNCE" | "ARCHIVED" | "BANNED" | "LIVE";
  /** Заголовок эфира */
  title: string;
}

/** StreamItemDTO */
export interface StreamItemDTO {
  /** Информация об авторе эфира */
  author: StreamAuthorDTO;
  /**
   * Список брендов указанных в эфире
   * @uniqueItems true
   */
  brands: StreamProductBrandDTO[];
  /**
   * Может ли пользователь удалить эфир
   * @example false
   */
  canDelete: boolean;
  /**
   * Может ли пользователь обновить эфир
   * @example false
   */
  canUpdate: boolean;
  /** Тип обложки эфира */
  cover: "COVER_1" | "COVER_2" | "COVER_3" | "COVER_4";
  /** Описание эфира */
  description: string;
  /**
   * Первичный ключ эфира
   * @format int64
   */
  id: number;
  /**
   * Подписан ли пользователь на данный эфир
   * @example false
   */
  isSubscribed?: boolean;
  /**
   * Количество продуктов указанных в эфире
   * @format int32
   */
  productsTotalCount: number;
  /**
   * Дата начала прямого эфира
   * @format date-time
   */
  startingDate: string;
  /** Статус эфира */
  status: "ANNOUNCE" | "ARCHIVED" | "BANNED" | "LIVE";
  /** Заголовок эфира */
  title: string;
}

/** StreamProductBrandDTO */
export interface StreamProductBrandDTO {
  /**
   * Первичный ключ бренда
   * @format int64
   */
  id: number;
  /** Наименование бренда */
  title: string;
}

/** StreamProductCategoryDTO */
export interface StreamProductCategoryDTO {
  /**
   * Первичный ключ категории
   * @format int64
   */
  id: number;
  /** Наименование категории */
  title: string;
}

/** StreamProductDTO */
export interface StreamProductDTO {
  /** Данные о бренде товар */
  brand: StreamProductBrandDTO;
  /** Данные о категории товар */
  category: StreamProductCategoryDTO;
  /**
   * Количество комментариев к данному товару
   * @format int32
   */
  commentsCount: number;
  /**
   * Скидка в процентах на товар
   * @format int32
   */
  discountInPercent?: number;
  /**
   * Первичный ключ товара
   * @format int64
   */
  id: number;
  /** Путь к фотографии товара */
  imagePath: string;
  /**
   * Количество лайков к данному товару
   * @format int32
   */
  likesCount: number;
  /**
   * Старая цена товара
   * @format int32
   */
  oldPrice?: number;
  /**
   * Цена товара
   * @format int32
   */
  price: number;
  /**
   * Количество пользователей подписанные на смену цены данного товара
   * @format int32
   */
  priceUpdateSubscribersCount: number;
  /**
   * Сумма которую получит продавец
   * @format int32
   */
  sellerRecievesSum?: number;
  /** Тип размера товар */
  sizeType:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  /** Данные о размерах товар */
  sizes: StreamProductSizeDTO[];
  /** Наименование товара */
  title: string;
  /**
   * С биркой ли товар
   * @example false
   */
  withBadge: boolean;
}

/** StreamProductSizeDTO */
export interface StreamProductSizeDTO {
  /**
   * Первичный ключ размера товара
   * @format int64
   */
  id: number;
  /**
   * Есть ли размер у товара
   * @example false
   */
  isNoSize: boolean;
  /** Статус товара по данному размеру */
  purchaseStatus: "AVAILABLE" | "IN_CART" | "OUT_OF_STOCK" | "PURCHASED";
  /** Тип размера товара */
  sizeType:
    | "AGE"
    | "AU"
    | "CENTIMETERS"
    | "COLLAR_CENTIMETERS"
    | "COLLAR_INCHES"
    | "DE"
    | "EU"
    | "FR"
    | "HEIGHT"
    | "INCHES"
    | "INT"
    | "IT"
    | "JEANS"
    | "JPN"
    | "NO_SIZE"
    | "BUST"
    | "RING_EUROPEAN"
    | "RING_RUSSIAN"
    | "RU"
    | "UK"
    | "US";
  /** Размер товара */
  title: string;
}

/** Unavailable */
export interface Unavailable {
  description?: string;
  /** @format int32 */
  publishedProductsLeft?: number;
  /** @format int32 */
  successfulTradesLeft?: number;
  title?: string;
}

/** WebHookDTO */
export interface WebHookDTO {
  action?: string;
  eventId?: string;
  payload?: Payload;
}

export interface AdminPanelUserBanDTO {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  userId?: number;
  banType?: BanType;
  description?: string;
  title?: string;
  /** @format int64 */
  createDate?: number;
  /** @format int64 */
  startDate?: number;
  /** @format int64 */
  endDate?: number;
  isBaned?: boolean;
  isDeleted?: boolean;
  /** @format int64 */
  statusChangedUserId?: number;
  adminComment?: string;
}

export enum BanType {
  USER_BAN = "USER_BAN",
  COMMENT_BAN = "COMMENT_BAN",
  PUBLISH_BAN = "PUBLISH_BAN",
  STORIES_BAN = "STORIES_BAN",
  BARGAIN_BAN = "BARGAIN_BAN",
  STREAM_BAN = "STREAM_BAN",
  WARNING = "WARNING",
}

/** AdminPanelBanSearchFilerDTO */
export interface AdminPanelBanSearchFilerDTO {
  banIds?: number[];
  banTypes?: BanType[];
  isBaned?: boolean;
  isDeleted?: boolean;
  userIds?: number[];
}

/** DefaultUserItem */
export interface DefaultUserItem {
  city?: string;
  completeProfile?: boolean;
  email?: string;
  fullName?: string;
  /** @format int64 */
  id?: number;
  isBaned?: boolean;
  nickName?: string;
  /** @format int32 */
  ordersCount?: number;
  phoneNumber?: string;
  /** @format int32 */
  productsCount?: number;
  role?: string;
  /** @format int32 */
  salesCount?: number;
  trusted?: boolean;
  type?: string;
}

/** Pageable */
export interface Pageable {
  /** @format int64 */
  offset?: number;
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  paged?: boolean;
  sort?: Sort;
  unpaged?: boolean;
}

/** PageOfDefaultUserIte */
export interface PageOfDefaultUserItem {
  content?: DefaultUserItem[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: Pageable;
  /** @format int32 */
  size?: number;
  sort?: Sort;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}

/** Sort */
export interface Sort {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}

/** UserBanActionDTO */
export interface UserBanActionDTO {
  banType?: BanType;
  description?: string;
  /** @format date-time */
  endDate?: string;
  /** @format int64 */
  userId?: number;
  adminComment?: string;
}

/** Api2ResponseOfProductAuthenticityOpinionDTO */
export interface Api2ResponseOfProductAuthenticityOpinionDTO {
  data?: ProductAuthenticityOpinionDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ProductAuthenticityOpinionDTO */
export interface ProductAuthenticityOpinionDTO {
  /** @format int64 */
  id?: number;
  /** @format uuid */
  uuid?: string;
  product?: ProductDTO;
  customerFullName?: string;
  opinionText?: string;
  /** @format date-time */
  date?: string;
}

/** ApiResponse */
export interface ApiResponse {
  data?: ProductModelDTO[];
}

/** ProductModelDTO */
export interface ProductModelDTO {
  /** @format int64 */
  id: number;
  name: string;
  /** @format int64 */
  brandId: number;
}

/** Api2ResponseOfSubmitApplication */
export type Api2ResponseOfSubmitApplication = string;

export interface SubmitApplicationDTO {
  clientName: string;
  phoneNumber: string;
  reference?: string;
  comment?: string;
  imagesReferences?: string[];
  clientChannel?: string;
}

/** Api2ResponseOfImageUpload */
export type Api2ResponseOfImageUpload = string[];

/** @format binary */
export type ImageUploadDTO = File;

/** BaseBannerLink */
export interface BaseBannerLink {
  type: "brands" | "category" | "deeplink" | "new" | "choice" | "stock" | "pro" | "sale";
}

/** BaseCatalogElement */
export interface BaseCatalogElement {
  type: "banner" | "banner_grid" | "banner_mini" | "list" | "stream";
}

/** BanneredLinkData */
export interface BanneredLinkData {
  title?: string;
  description?: string;
  image?: string;
  imageWebp?: string;
}

export type BanneredLink = BaseBannerLink & {
  categoryId?: number;
  bannerData?: BanneredLinkData;
};

export type BrandsLink = BaseBannerLink & {
  categoryId?: number;
};

export type CategoryLink = BaseBannerLink & {
  categoryId?: number;
  parentCategoryId?: number;
};

export type ChoiceLink = BanneredLink;

export type StockLink = BanneredLink;

export type DeepLink = BaseBannerLink & {
  deeplink?: string;
};

export type NewLink = BaseBannerLink & {
  categoryId?: number;
};

export type ProLink = BaseBannerLink & {
  categoryId?: number;
};

export type SaleLink = BaseBannerLink & {
  categoryId?: number;
};

/** BannerData */
export interface BannerData {
  title?: string;
  badge?: string;
  description?: string;
  image?: string;
  imageWebp?: string;
  link?: BannerLink;
}

/** PlainTextData */
export interface PlainTextData {
  title?: string;
  link?: BannerLink;
}

export type BlockElement = BaseCatalogElement & {
  blockId?: string;
};

export type BannerElement = BlockElement & {
  data?: BannerData;
};

export type PlainTextElement = BlockElement & {
  data?: PlainTextData;
};

export type BannerMiniElement = BannerElement;

export type StreamElement = BlockElement;

/** BannerGridData */
export interface BannerGridData {
  children?: CatalogElement[];
}

export type BannerGridElement = BaseCatalogElement & {
  data?: BannerGridData;
};

/** ListData */
export interface ListData {
  title?: string;
  children?: CatalogElement[];
}

export type ListElement = BaseCatalogElement & {
  data?: ListData;
};

/** ItemData */
export interface ItemData {
  children?: CatalogElement[];
}

/** MenuItem */
export interface MenuItem {
  name?: string;
  item_id?: string;
  data?: ItemData;
}

/** CatalogMenu */
export interface CatalogMenu {
  version?: string;
  description?: string;
  catalog?: MenuItem[];
}

/** ApiV2ResponseOfCatalogMenu */
export interface ApiV2ResponseOfCatalogMenu {
  data?: CatalogMenu;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

export type CatalogElement =
  | BannerElement
  | BannerGridElement
  | BannerMiniElement
  | ListElement
  | StreamElement
  | PlainTextElement;

export type BannerLink = BrandsLink | CategoryLink | DeepLink | NewLink | ChoiceLink | StockLink | ProLink | SaleLink;

/**
 * ProductFilterInfoRequest
 * Запрос на получение доступных значений заданного фильтра
 */
export interface ProductFilterInfoRequest {
  /**
   * Коллекция фильтров со значениями. Доступные среди прочих фильтры:
   * * offlineOnly: true | false - выдать только товары, находящиеся offline | все товары
   */
  filters?: Record<string, FilterValue>;
  /**
   * Коллекция пресетов со значениями. Доступные среди прочих пресеты:
   * * offlineOnly: true | false - выдать только товары, находящиеся offline | все товары
   */
  presets?: Record<string, FilterValue>;
  baseCategory?: number;
  currencyCode?: string;
  contexts?: ClientProductFiltrationContext[];
}

/**
 * ProductFilterItemsRequest
 * Запрос на получение списка товаров, удовлетворяющих условиям фильтрации
 */
export type ProductFilterItemsRequest = ProductFilterInfoRequest & {
  /** @default "NEW" */
  sorting?: string;
  /** @default 1 */
  page?: number;
  /** @default 60 */
  pageLength?: number;
};

/**
 * ProductFilterRequest
 * Запрос на получение доступных значений фильтра и товаров по фильтрам
 */
export type ProductFilterRequest = ProductFilterItemsRequest & {
  /** @default false */
  withAvailableValues?: boolean;
  /** @default false */
  withItems?: boolean;
};

/** ProductSorting */
export interface ProductSorting {
  code: string;
  name: string;
  isSelected: boolean;
}

/**
 * AbstractProductFilter
 * Базовая схема доступного фильтра
 */
export interface AbstractProductFilter {
  code: string;
  type: string;
  name: string;
  description?: string;
  descriptions?: FilterDescription[];
}

/**
 * PriceFilter
 * Доступный фильтр по цене
 */
export type PriceFilter = AbstractProductFilter & {
  type: "PRICE";
  lower?: number;
  upper?: number;
  selectedLower?: number;
  selectedUpper?: number;
};

/**
 * BooleanFilter
 * Доступный фильтр по true/false значениям
 */
export type BooleanFilter = AbstractProductFilter & {
  type: "BOOLEAN";
  value: boolean;
  isEnabled: boolean;
};

/**
 * ListValue
 * Значение из списка
 */
export interface ListValue {
  id: number;
  value: string;
}

/**
 * ListHotValue
 * Значение из списка значений быстрого выбора
 */
export type ListHotValue = ListValue & {
  isSelected: boolean;
};

/**
 * ListSectionEntry
 * Значение из набора элементов секции списка
 */
export interface ListSectionEntry {
  id: number;
  value: string;
  description?: string;
  isSelected: boolean;
  image?: string;
}

/**
 * ListSection
 * Секция списка
 */
export interface ListSection {
  name: string;
  entries: ListSectionEntry[];
}

/**
 * MultiListFilter
 * Доступный фильтр по списочным значениям
 */
export type MultiListFilter = AbstractProductFilter & {
  type: "MULTI_LIST";
  values?: ListSection[];
  hotValues?: ListHotValue[];
  selectedValues?: ListValue[];
  searchableSections: string[];
  hasMoreValues: boolean;
};

/**
 * CategoryTreeValue
 * Значение из фильтра с типом дерева категорий
 */
export interface CategoryTreeValue {
  id: number;
  name: string;
  selectedValues?: ListValue[];
  isSelected: boolean;
  children?: CategoryTreeValue[];
}

/**
 * CategoryTreeFilter
 * Доступный фильтр по значению категории
 */
export type CategoryTreeFilter = AbstractProductFilter & {
  type: "CATEGORY_TREE";
  values?: CategoryTreeValue[];
  selectedValues?: ListValue[];
  hasMoreValues: boolean;
};

/**
 * ChartValue
 * Значение из сетки размеров
 */
export interface ChartValue {
  code: string;
  name: string;
  value: string;
}

/**
 * SizeValue
 * Значение доступного размера
 */
export interface SizeValue {
  id: number;
  name: string;
  isSelected: boolean;
  charts?: ChartValue[];
}

/**
 * CategorySizeValue
 * Доступные размеры в сетке категории
 */
export interface CategorySizeValue {
  code: string;
  name: string;
  values?: SizeValue[];
}

/**
 * CategorySize
 * Доступные значения фильтра по размерам
 */
export interface CategorySize {
  id: number;
  name: string;
  selectedValues?: ListValue[];
  /** Доступные размеры в сетке категории */
  sizesValue: CategorySizeValue;
}

/**
 * SizeFilter
 * Доступный фильтр по размерам
 */
export type SizeFilter = AbstractProductFilter & {
  type: "SIZE";
  values?: CategorySize[];
  selectedValues?: ListValue[];
  hasMoreValues: boolean;
};

/**
 * ProductFilter
 * Доступный фильтр
 */
export type ProductFilter = BooleanFilter | CategoryTreeFilter | MultiListFilter | PriceFilter | SizeFilter;

/**
 * ProductFilters
 * Описание доступных фильтров, сортировок и товаров
 */
export interface ProductFilters {
  sorting: ProductSorting[];
  filters: ProductFilter[];
  hotFilters: string[];
  itemsCount: number;
  items?: PageOfProductDTO;
}

/** ApiV2ResponseOfProductFilters */
export interface ApiV2ResponseOfProductFilters {
  /** Описание доступных фильтров, сортировок и товаров */
  data?: ProductFilters;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiV2ResponseOfProductFilter */
export interface ApiV2ResponseOfProductFilter {
  /** Доступный фильтр */
  data?: ProductFilter;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiV2ResponseOfItemsCount */
export interface ApiV2ResponseOfItemsCount {
  /** Количество товаров */
  data?: ItemsCount;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/**
 * ItemsCount
 * Количество товаров
 */
export interface ItemsCount {
  itemsCount?: number;
}

/** FilterDescription */
export interface FilterDescription {
  name?: string;
  description?: string;
}

/** Context */
export enum ClientProductFiltrationContext {
  ACCOUNT_PUBLISHED_OFFLINE_PRODUCTS = "ACCOUNT_PUBLISHED_OFFLINE_PRODUCTS",
}

/** VerificationPayloadDto */
export interface VerificationPayloadDto {
  /** @pattern ^\d{4}$ */
  code: string;
  context?: Record<string, string>;
  operation: "VERIFY_PHONE_NUMBER" | "AUTH_OR_REGISTER" | "VERIFY_PHONE_NUMBER_3RD_PARTY";
  /** @pattern ^\+\d{6,15}$ */
  phoneNumber: string;
  token: string;
}

/** GenerationPayloadDto */
export interface GenerationPayloadDto {
  context?: Record<string, string>;
  operation: "VERIFY_PHONE_NUMBER" | "AUTH_OR_REGISTER" | "VERIFY_PHONE_NUMBER_3RD_PARTY";
  path: "SMS" | "WA";
  /** @pattern ^\+\d{6,15}$ */
  phoneNumber: string;
}

/** Api2ResponseOfString */
export interface Api2ResponseOfString1 {
  data?: string;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfString */
export interface Api2ResponseOfString2 {
  data?: string;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ApiResponse */
export interface ApiResponse1 {
  data?: PrimaryPageDTO;
}

/** ApiResponseListContent */
export interface ApiResponseListContent {
  data?: PrimaryContentDTO[];
}

/** ApiResponseApiResponseBannerDTOList */
export interface ApiResponseBannerDTOList {
  data?: BannerDTO[];
}

/** ApiResponsePromoBannerContent */
export interface ApiResponsePromoBannerContent {
  data?: PromoBannerContent;
}

/** ApiResponseSocialContent */
export interface ApiResponseSocialContent {
  data?: SocialContent;
}

/** ApiResponseInstagramFeedItemListContent */
export interface ApiResponseInstagramFeedItemListContent {
  data?: InstagramFeedItem[];
}

/** PrimaryPageDTO */
export interface PrimaryPageDTO {
  items?: PrimaryContentDTO[];
  seoLinks?: PrimaryContentDTO;
  pageNumber?: number;
  pageCount?: number;
  itemsCount?: number;
  concierge?: boolean;
  onboarding?: "PRODUCT_REQUEST_ONBOARDING" | "CONCIERGE_BUYER_ONBOARDING";
  itemType?: "PRODUCT" | "PRODUCT_REQUEST";
  pageTitle?: string;
}

/** PrimaryContentDTO */
export interface PrimaryContentDTO {
  title?: string;
  imagePath?: string;
  contentType?: ContentType;
  id: string;
  contentList?: (
    | PromoBannerDTO
    | BannerDTO
    | CollectionDTO
    | CelebrityDTO
    | PrimaryPageProductDTO
    | BlogDTO
    | TextSlideDTO
    | AdditionalCollectionDTO
    | PrimaryContentDTOProductItem
    | ButtonControlViewDTO
    | FooterButtonDTO
    | SeoLinkGroupDto
  )[];
  content?:
    | PromoBannerDTO
    | BannerDTO
    | CollectionDTO
    | CelebrityDTO
    | PrimaryPageProductDTO
    | BlogDTO
    | AdditionalCollectionDTO
    | FilterDTO
    | StoriesDTO
    | StoreezContentDTO
    | InstagramFeedDTO
    | ShelfWithFiltersDTO
    | TextControlViewDTO;
  nextAnchor?: string;
  additionalData?: PrimaryContentAdditionalData;
  /** @format int64 */
  chapterIndex?: number;
  /** @format date-time */
  updateTime?: string;
  /** @format int64 */
  segmentId?: number;
}

/** PrimaryContentDTO */
export interface PrimaryContentDTOProductItem {
  contentType?: ContentType;
  content?: PrimaryPageProductDTO;
}

/** PrimaryContentAdditionalData */
export interface PrimaryContentAdditionalData {
  /** @format int64 */
  count?: number;
  imgPathList?: string[];
  jumpLink?: string;
  description?: string;
  isSellerHidden?: boolean;
  isOnePageContent?: boolean;
  isJournalMode?: boolean;
  /**
   * Интервал перелистывания слайдов в мс
   * @format int64
   */
  textSlideInterval?: number;
}

/** PromoBannerDTO */
export interface PromoBannerDTO {
  title?: string;
}

/** BannerDTO */
export interface BannerDTO {
  id?: string;
  title?: string;
  description?: string;
  additionalDescription?: string;
  imgPath?: string;
  link?: string;
  catalogLink?: string;
  buttonTitle?: string;
  buttonLink?: string;
  badge?: string;
  descriptionButtonText?: string;
  descriptionButtonUrl?: string;
  isFlexiblyButton?: boolean;
  isEndButton?: boolean;
  isButtonInsideBanner?: boolean;
  isJournalMode?: boolean;
}

/** CollectionDTO */
export interface CollectionDTO {
  id?: string;
  title?: string;
  description?: string;
  imgPath?: string;
  link?: string;
}

/** CelebrityDTO */
export interface CelebrityDTO {
  id?: string;
  description?: string;
  link?: string;
  subscribeLink?: string;
  socialNetworkURI?: string;
  productCount?: number;
  instagramFollowerCount?: string;
  userDTO?: PrimaryPageUserDTO;
}

/** PrimaryPageUserDTO */
export type PrimaryPageUserDTO = {
  id?: string;
  name?: string;
  nickName?: string;
  avatarPath?: string;
  type?: string;
  isTrusted?: boolean;
  isSubscribed?: boolean;
  /** Бейджики пользователя в O!Community */
  communityBadge?: CommunityBadge;
} | null;

/** PrimaryPageProductDTO */
export interface PrimaryPageProductDTO {
  /** @format int64 */
  id?: number;
  name?: string;
  imgPath?: string;
  brandName?: string;
  categoryName?: string;
  /** @format int64 */
  conditionId?: number;
  conditionName?: string;
  price?: number;
  oldPrice?: number;
  discount?: number;
  isLiked?: boolean;
  likeCount?: number;
  withBadge?: boolean;
  formattedSize?: string;
  sizeType?: SizeType1;
  sizes?: SizeValueDTO1[];
  seller?: PrimaryPageUserDTO;
  split?: SplitInfo;
  tabbySplit?: SplitInfo;
  yandexPlus?: YandexPlusInfo;
  /** @format date-time */
  exclusiveSelectionTime?: string;
  /** @format date-time */
  exclusiveSelectionTimeForLowStatuses?: string;
}

/** SizeValueDTO */
export interface SizeValueDTO1 {
  /** @format int64 */
  id?: number;
  productSizeType?: SizeType1;
  productSizeValue?: string;
  categorySizeType?: SizeType1;
  categorySizeValue?: string;
  interestingSizeType?: SizeType1;
  interestingSizeValue?: string;
  count?: number;
  additionalSizeValues?: Record<string, number>;
  offer?: OfferDTO1;
}

/** OfferDTO */
export interface OfferDTO1 {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  price?: number;
  offerStatus?: OfferStatus;
  /** @format int64 */
  negotiatedPrice?: number;
  consumed?: boolean;
}

/** BlogDTO */
export interface BlogDTO {
  id?: string;
  title?: string;
  description?: string;
  imgPath?: string;
  link?: string;
}

/** TextSlideDTO */
export interface TextSlideDTO {
  id?: string;
  title?: string;
  description?: string;
  iconPath?: string;
  deepLinkUrl?: string;
}

/** FilterDTO */
export interface FilterDTO {
  title?: string;
  presets?: Record<string, FilterValue>;
  /** @format int64 */
  baseCategory?: number;
  source?: "PRIMARY" | "BANNER" | "CONCIERGE_BANNER" | "PRODUCT_REQUEST_BANNER" | "CATALOG" | "PROFILE";
  displayMode?: "DEFAULT" | "ONLY_HOT_FILTERS";
}

/** StoriesDTO */
export interface StoriesDTO {
  disableFeed?: boolean;
  showTopPanel?: boolean;
  showStreams?: boolean;
  users?: PrimaryPageUserDTO[];
}

/** StoreezContentDTO */
export interface StoreezContentDTO {
  storeezId?: string;
}

/** InstagramFeedDTO */
export interface InstagramFeedDTO {
  /**
   * Путь с параметрами для получения ленты
   * @example "/api/v2/home/instagram-feed?maxItemsCount=10&minLikesCount=10&minCommentsCount=5"
   */
  feedPath?: string;
  /** Url для перехода на страницу oskelly.co в instagram */
  instagramPageUrl?: string;
  /** Название кнопки для перехода в instagram */
  toInstagramPageButtonTitle?: string;
  /** Отображать или нет количество лайков и комментов */
  indicatorsEnabled?: boolean;
}

/** StoriesDTO */
export interface SocialDTO {
  id?: string | number;
  title?: string;
  imgPath?: string;
  isSeen?: boolean;
  link?: string;
  anchor?: string;
  contentType?: PrimaryContentType;
}

/** AdditionalCollectionDTO */
export interface AdditionalCollectionDTO {
  id?: string;
  title?: string;
  description?: string;
  imgPath?: string;
  link?: string;
  categoryDisplayName?: string;
  isSellerHidden?: boolean;
}

export enum ContentType {
  ADV_BANNER = "ADV_BANNER",
  BANNER = "BANNER",
  SINGLE_BANNER = "SINGLE_BANNER",
  COLLECTION = "COLLECTION",
  ADDITIONAL_COLLECTION = "ADDITIONAL_COLLECTION",
  CELEBRITY = "CELEBRITY",
  RECENTLY_VIEW = "RECENTLY_VIEW",
  BLOG = "BLOG",
  TEXT_SLIDES = "TEXT_SLIDES",
  INSTAGRAM = "INSTAGRAM",
  PRODUCT = "PRODUCT",
  SELECTION = "SELECTION",
  H_SELECTION = "H_SELECTION",
  H_SELECTION_WITH_BANNER = "H_SELECTION_WITH_BANNER",
  LIST_ITEMS = "LIST_ITEMS",
  STORY = "STORY",
  STREAMSALE = "STREAMSALE",
  LINK = "LINK",
  USERS_COLLECTION = "USERS_COLLECTION",
  ARTICLE = "ARTICLE",
  AD_PRODUCT = "AD_PRODUCT",
  SOCIAL_COLLECTION = "SOCIAL_COLLECTION",
  FILTERABLE_ITEMS = "FILTERABLE_ITEMS",
  SQUARE_BANNER_WITH_BUTTON = "SQUARE_BANNER_WITH_BUTTON",
  SMALL_BANNER_WITH_BUTTON = "SMALL_BANNER_WITH_BUTTON",
  PRODUCT_REQUEST_FILTERABLE_ITEMS = "PRODUCT_REQUEST_FILTERABLE_ITEMS",
  STORIES = "STORIES",
  STOREEZ = "STOREEZ",
  INSTAGRAM_FEED = "INSTAGRAM_FEED",
  VERTICAL_BANNER_COLLECTION = "VERTICAL_BANNER_COLLECTION",
  BUTTON_CONTROL = "BUTTON_CONTROL",
  TEXT_CONTROL = "TEXT_CONTROL",
  FILTERABLE_SHELF = "FILTERABLE_SHELF",
  LINK_GROUPS_BLOCK = "LINK_GROUPS_BLOCK",
}

export enum SizeType1 {
  RU = "RU",
  EU = "EU",
  US = "US",
  INT = "INT",
  UK = "UK",
  FR = "FR",
  IT = "IT",
  DE = "DE",
  AU = "AU",
  JPN = "JPN",
  INCHES = "INCHES",
  CENTIMETERS = "CENTIMETERS",
  COLLAR_CENTIMETERS = "COLLAR_CENTIMETERS",
  COLLAR_INCHES = "COLLAR_INCHES",
  RING_RUSSIAN = "RING_RUSSIAN",
  RING_EUROPEAN = "RING_EUROPEAN",
  JEANS = "JEANS",
  HEIGHT = "HEIGHT",
  AGE = "AGE",
  NO_SIZE = "NO_SIZE",
  BUST = "BUST",
}

export enum OfferStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

/** PromoBannerContent */
export interface PromoBannerContent {
  id: string;
  contentType: "ADV_BANNER";
  content: PromoBannerDTO;
}

/** SocialContent */
export interface SocialContent {
  contentList: SocialDTO[];
  nextAnchor?: string;
}

export interface ButtonControlViewDTO {
  id: string;
  title?: string;
  url?: string;
  imageUrl?: string;
}

export interface FooterButtonDTO {
  title: string;
  link: string;
  type: "DEEPLINK" | "SHARE";
}

export interface SeoLinkGroupDto {
  id?: string;
  name?: string;
  links?: SeoLinkDto[];
}

export interface SeoLinkDto {
  text?: string;
  url?: string;
}

export interface TextControlViewDTO {
  id?: string;
  title?: string;
  text?: string;
  imageUrl?: string;
  imageWidth?: number;
  imageHeight?: number;
  userId?: string;
  userSubtitle?: string;
  userName?: string;
  userAvatarUrl?: string;
}

export interface ShelfWithFiltersDTO {
  presetFilter?: FilterDTO;
  options?: ShelfWithFiltersOptionDTO[];
  withFilterOptions?: boolean;
}

export interface ShelfWithFiltersOptionDTO {
  title?: string;
  filter?: object;
  contents?: PrimaryContentDTO[];
}

/** InstagramFeedItem */
export interface InstagramFeedItem {
  /** Оригинальный ИД */
  id?: string;
  /** Url медиа файла */
  mediaUrl?: string;
  /** Url превью медиа файла (заполняется для видео) */
  thumbnailUrl?: string;
  /** Заголовок поста */
  caption?: string;
  /**
   * Количество лайков
   * @format int64
   */
  likesCount?: number;
  /**
   * Количество комментариев
   * @format int64
   */
  commentsCount?: number;
  /** Дата создания */
  timestamp?: string;
  /** Имя пользователя, разместившего пост */
  userName?: string;
  /** Url поста */
  url?: string;
}

export enum PrimaryContentType {
  ADV_BANNER = "ADV_BANNER",
  BANNER = "BANNER",
  SINGLE_BANNER = "SINGLE_BANNER",
  COLLECTION = "COLLECTION",
  ADDITIONAL_COLLECTION = "ADDITIONAL_COLLECTION",
  CELEBRITY = "CELEBRITY",
  RECENTLY_VIEW = "RECENTLY_VIEW",
  BLOG = "BLOG",
  INSTAGRAM = "INSTAGRAM",
  PRODUCT = "PRODUCT",
  SELECTION = "SELECTION",
  H_SELECTION = "H_SELECTION",
  H_SELECTION_WITH_BANNER = "H_SELECTION_WITH_BANNER",
  LIST_ITEMS = "LIST_ITEMS",
  STORY = "STORY",
  STREAMSALE = "STREAMSALE",
  LINK = "LINK",
  USERS_COLLECTION = "USERS_COLLECTION",
  ARTICLE = "ARTICLE",
  AD_PRODUCT = "AD_PRODUCT",
  CONCIERGE_BANNER = "CONCIERGE_BANNER",
  SOCIAL_COLLECTION = "SOCIAL_COLLECTION",
  FILTERABLE_ITEMS = "FILTERABLE_ITEMS",
  PRODUCT_REQUEST = "PRODUCT_REQUEST",
  SQUARE_BANNER_WITH_BUTTON = "SQUARE_BANNER_WITH_BUTTON",
  SMALL_BANNER_WITH_BUTTON = "SMALL_BANNER_WITH_BUTTON",
  PRODUCT_REQUEST_FILTERABLE_ITEMS = "PRODUCT_REQUEST_FILTERABLE_ITEMS",
  STORIES = "STORIES",
  STOREEZ = "STOREEZ",
  INSTAGRAM_FEED = "INSTAGRAM_FEED",
  BUTTON_CONTROL = "BUTTON_CONTROL",
  TEXT_CONTROL = "TEXT_CONTROL",
  FILTERABLE_SHELF = "FILTERABLE_SHELF",
}

/** Api2ResponseOfCategoryTree */
export interface Api2ResponseOfForceUpdateInfo {
  data?: ForceUpdateInfo;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** ForceUpdateInfo */
export interface ForceUpdateInfo {
  updateRequired?: boolean;
  message?: string;
}

/**
 * SearchProductFilterInfoRequest
 * Запрос на получение доступных значений заданного фильтра
 */
export interface SearchProductFilterInfoRequest {
  /** Пользовательский поисковой запрос */
  search?: Record<string, SearchQuery>;
  /**
   * Коллекция фильтров со значениями. Доступные среди прочих фильтры:
   * * offlineOnly: true | false - выдать только товары, находящиеся offline | все товары
   */
  filters?: Record<string, FilterValue>;
  hiddenFilters?: Record<string, FilterValue>;
  /**
   * Коллекция пресетов со значениями. Доступные среди прочих пресеты:
   * * offlineOnly: true | false - выдать только товары, находящиеся offline | все товары
   */
  presets?: Record<string, FilterValue>;
  baseCategory?: number;
  countryId?: number;
  currencyCode?: string;
  contexts?: ClientProductFiltrationContext[];
}

/**
 * SearchProductFilterItemsRequest
 * Запрос на получение списка товаров, удовлетворяющих условиям фильтрации
 */
export type SearchProductFilterItemsRequest = SearchProductFilterInfoRequest & {
  /** @default "NEW" */
  sorting?: string;
  /** @default 1 */
  page?: number;
  /** @default 60 */
  pageLength?: number;
};

/**
 * SearchProductFilterRequest
 * Запрос на получение доступных значений фильтра и товаров по фильтрам
 */
export type SearchProductFilterRequest = SearchProductFilterItemsRequest & {
  /** @default false */
  withAvailableValues?: boolean;
  /** @default false */
  withItems?: boolean;
};

/** SearchQuery */
export interface SearchQuery {
  query?: string;
  /** @default true */
  useCorrection?: boolean;
}

/** Api2ResponseOfSearchPageOfProductDTO */
export interface Api2ResponseOfSearchPageOfProductDTO {
  data?: SearchPageOfProductDTO;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** SearchPageOfProductDTO */
export interface SearchPageOfProductDTO {
  items?: ProductDTORes[];
  /** @format int32 */
  itemsCount?: number;
  /** @format int64 */
  totalAmount?: number;
  /** @format int32 */
  totalPages?: number;
  correctedValue?: string;
  queryHash?: string;
}

/** Api2ResponseOfListOfString */
export interface Api2ResponseOfListOfString {
  data?: string[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Описание статуса пользователя в O!Community для наполнение для баннера */
export interface CommunityBadgeLayout {
  /**
   * Описание статуса
   * @example "Станьте частью O!Community"
   */
  title?: string;
  /** Статус пользователя в O!Community */
  status?: CommunityStatus;
  /**
   * Описание статуса
   * @example "Покупайте и продавайте на платформе и станьте членом O’Community с привилегиями. <a href="myPrivileges">Мои привилегии</a>"
   */
  description?: string;
  /**
   * Ссылка на страницу пользователя в O!Community
   * @example "<a href="https://o-community.com/user/my-priveleges">Мои привилегии</a>"
   */
  link?: string;
}

/** Api2ResponseOfListOfAddressEndpointDTO */
export interface Api2ResponseOfListOfAddressEndpointDTO1 {
  data?: AddressEndpointDTO1[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

/** Api2ResponseOfString */
export interface Api2ResponseOfString3 {
  data?: string;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  humanMessage?: string;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

export interface ChangeCounterpartyVisibilityRequest {
  /** @format int64 */
  counterpartyId?: number;
  visible?: boolean;
}

export interface Api2ResponseOfCounterpartyDTO1 {
  data: any;
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

export interface Api2ResponseOfListOfCounterpartyDTO1 {
  data: CounterpartyDTO1[];
  errorData?: object;
  /** @format int64 */
  executionTimeMillis?: number;
  message?: string;
  /** @format int64 */
  timestamp?: number;
  validationMessages?: Record<string, string>;
}

export interface EditCounterpartyRequest {
  counterparty?: CounterpartyDTO1;
}

export interface AdminAddressView {
  /** @format int64 */
  id?: number;
  fullname?: string;
  phone?: string;
  addressInfo?: string;
  isFias?: boolean;
}

export interface AddressData {
  /** @format int64 */
  endpointId?: number;
  /** @format int64 */
  addressId?: number;
  active?: boolean;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phone?: string;
  region?: string;
  country?: string;
  /** @format int64 */
  countryDataId?: number;
  city?: string;
  /** @format int64 */
  cityDataId?: number;
  regionCity?: string;
  addressFull?: string;
  street?: string;
  house?: string;
  flat?: string;
  /** @default false */
  isValidated?: boolean;
  /** @default false */
  isCityValidated?: boolean;
  lastCityValidationTime?: string;
  isAddressValidated?: boolean;
  lastAddressValidationTime?: string;
  fiasId?: string;
  cityFiasId?: string;
  settlementFiasId?: string;
  dataFullAddress?: string;
  isCis?: boolean;
  displayString?: string;
  cityDisplayString?: string;
}

export interface GetCityHintResponseDTO {
  data: CityHintDTO[];
}

export interface CityHintDTO {
  region?: string;
  city?: string;
}

export interface GetStreetHintResponseDTO {
  data: StreetHintDTO[];
}

export interface StreetHintDTO {
  street?: string;
}

export interface ValidateAddressResponseDTO {
  /** @default false */
  isValidated?: boolean;
  /** @default false */
  isCityValidated?: boolean;
  /** @format date-time */
  lastCityValidationTime?: string;
  isAddressValidated?: boolean;
  /** @format date-time */
  lastAddressValidationTime?: string;
  fiasId?: string;
  cityFiasId?: string;
  settlementFiasId?: string;
  dataFullAddress?: string;
}

export interface DaDataAddressRequest {
  query?: string;
  count?: number;
  from_bound?: DaDataBoundDTO;
  to_bound?: DaDataBoundDTO;
}

export interface DaDataBoundDTO {
  value?: string;
}

export interface BanSearchFilerDTO {
  banIds?: number[];
  userIds?: number[];
  banTypes?: BanType[];
  isBaned?: boolean;
  isDeleted?: boolean;
}

export interface AdminPanelUserBanDTO1 {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  userId?: number;
  banType?: BanType;
  description?: string;
  /** @format int64 */
  createDate?: number;
  /** @format int64 */
  startDate?: number;
  /** @format int64 */
  endDate?: number;
  isBaned?: boolean;
  isDeleted?: boolean;
  /** @format int64 */
  statusChangedUserId?: number;
  adminComment?: string;
}

export interface CounterpartyDTO1 {
  /** @format int64 */
  id?: number;
  type: CounterpartyType1;
  isActive?: boolean;
  /** @format int64 */
  createTiestamp?: number;
  /** @format int64 */
  userId?: number;
  jurAddress?: AddressDTO1;
  physAddress?: AddressDTO1;
  passport?: string;
  inn?: string;
  /** Используется как для юр. лиц, так и для ИП (вместо ОГРНИП) */
  orgn?: string;
  kpp?: string;
  /** ООО, ЗАО. ПАО */
  companyForm?: string;
  /** Название компании без правовой формы и без кавычек (если только часть названия не выделена кавычками) */
  companyName?: string;
  bik?: string;
  correspondentAccount?: string;
  paymentAccount?: string;
  iban?: string;
  swiftCode?: string;
  routingNumber?: string;
  trn?: string;
  billingAddress?: AddressAggregationDTO;
  legalAddress?: AddressAggregationDTO;
  accountType?: BankAccountType;
  countryCounterpartyType?: CountryCounterpartyType;
  country?: CountryDTO1;
  firstName?: string;
  patronymicName?: string;
  lastName?: string;
  phone?: string;
  isCard?: boolean;
  cardRefId?: string;
  cardNumber?: string;
  /** @format date-time */
  cardBindTime?: string;
  /** @format date-time */
  cardUnbindTime?: string;
  cardHolder?: string;
  cardBrand?: string;
  cardBindBank?: string;
  /** Ставка НДС */
  vatRateIndex?: number;
  /** @format date-time */
  cardExpireTime?: string;
  isCardActive?: boolean;
  /** @format date-time */
  deleteTime?: string;
  counterpartyImage?: string;
  isActiveInCurrentOrder?: boolean;
  contractNumber?: string;
  /** @format date-time */
  contractDate?: string;
}

export enum CounterpartyType1 {
  PHYS = "PHYS",
  IP = "IP",
  JUR = "JUR",
  CARD = "CARD",
  BONUS12STOREEZ = "BONUS_12_STOREEZ",
  INTERNATIONAL = "INTERNATIONAL",
  INTERNATIONAL_LEGAL_ENTITY = "INTERNATIONAL_LEGAL_ENTITY",
}

export enum BankAccountType {
  CHECKING = "CHECKING",
  SAVING = "SAVING",
}

export enum CountryCounterpartyType {
  UAE_COUNTERPARTY = "UAE_COUNTERPARTY",
  DEFAULT_COUNTERPARTY = "DEFAULT_COUNTERPARTY",
}

export interface AddressDTO1 {
  /** @format int64 */
  id?: number;
  zipCode?: string;
  country?: string;
  countryData?: CountryDTO1;
  cityData?: CityDTO1;
  region?: string;
  city?: string;
  address?: string;
  addressBreakdown?: AddressBreakdownDTO;
  address2?: string;
  address3?: string;
  fiasId?: string;
  regionFiasId?: string;
  cityFiasId?: string;
  settlementFiasId?: string;
  dadataFullAddress?: string;
  fullCityName?: string;
  fullAddress?: string;
  checked?: boolean;
  /** @format int64 */
  createTime?: number;
  /** @format int64 */
  changeTime?: number;
  cis?: boolean;
}

/** AddressEndpointDTO */
export interface AddressEndpointDTO1 {
  address?: AddressDTO1;
  firstName?: string;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  userId?: number;
  lastName?: string;
  patronymicName?: string;
  phone?: string;
  /** @format int64 */
  deleteTime?: number;
  /** @format int64 */
  changeTime?: number;
}

export interface CountryDTO1 {
  /** @format int64 */
  id?: number;
  name?: string;
  uiCurrencyCode?: string;
  environment?: "RU" | "INT";
  isoCodeAlpha2?: string;
  imageUrl?: string;
  currency?: CurrencyDTO1;
  requireZipcode?: boolean;
}

export interface CurrencyDTO1 {
  /** @format int64 */
  id?: number;
  name?: string;
  sign?: string;
  isoCode?: string;
  isoNumber?: number;
  base?: boolean;
  active?: boolean;
  selectedByDefault?: boolean;
}

export interface CityDTO1 {
  /** @format int64 */
  id?: number;
  name?: string;
  region?: string;
}

export interface AddressAggregationDTO {
  /** @format int64 */
  id?: number;
  zipCode?: string;
  countryData?: CountryDTO1;
  cityData?: CityDTO1;
  address?: string;
  address2?: string;
  address3?: string;
  /** @format date-time */
  createTime?: string;
  /** @format date-time */
  changeTime?: string;
}

export interface CounterpartyData {
  /** @format int64 */
  id?: number;
  active?: boolean;
  counterpartyType?: CounterpartyType1;
  organisationForm?: string;
  companyName?: string;
  ipName?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  inn?: string;
  bik?: string;
  kpp?: string;
  paymentAccount?: string;
  vatRateIndex?: number;
}

export interface ConciergeAdditionalInfoDTO {
  adminComment?: string;
  /** @format date-time */
  deliveryToBuyerDateHint?: string;
  /** @format int64 */
  deliveryToBuyerDateHintInSeconds?: number;
  /** @format int64 */
  clientChannelId?: number;
  /** @format date */
  deliveryToBuyerDate?: string;
}

export interface ConciergeClientChannelDTO {
  /** @format int64 */
  id: number;
  name: string;
}

export interface OrderSourceInfoDTO1 {
  /** @format int64 */
  id: number;
  displayName?: string;
}

export interface ApiV2ConciergeClientChannelResponseDTO {
  data?: ConciergeClientChannelDTO[];
}

export interface ApiV2DictionaryOrderSourceInfoResponseDTO {
  data?: OrderSourceInfoDTO1[];
}

export interface ApiV2AddressCountyListResponseDTO {
  data?: CountryDTO1[];
}

export interface ApiV2AddressCityListResponseDTO {
  data?: AddressDTO1[];
}

export interface ApiV2OrderPaymentListResponseDTO {
  data?: AdminOrderPaymentDTO[];
}

export interface ApiV2PickupOptionListResponseDTO {
  data?: PickupOptionDTO[];
}

export interface ApiV2InternationalAddressCityListResponseDTO {
  data?: CityDTO1[];
}

export interface ApiV2AddressListResponseDTO {
  data?: AddressDTO1[];
}

export interface ApiV2AddressEndpointResponseDTO {
  data?: AddressEndpointDTO1;
}

export interface ApiV2EventDateEstimationListResponseDTO {
  data?: EventDateEstimationDTO[];
}

export interface ApiV2EventDateEstimationHistoryListResponseDTO {
  data?: EventDateEstimationHistoryDTO[];
}

export interface FrontendAdminConfig {
  currency: CurrencyDTO1;
  isInternational: boolean;
}

export interface SaleRejectionReasonDTO {
  /** @format int64 */
  id: number;
  name: string;
  isForAdmin: boolean;
  adminDisplayName: string;
}

export interface ApiV2SaleRejectionReasonResponseDTO {
  data?: SaleRejectionReasonDTO[];
}

export interface AdminOrderPaymentDTO {
  paymentVersion?: string;
  paymentMethods?: string;
  paymentType?: string;
  /** @format int64 */
  amountInBase?: number;
  /** @format date-time */
  authorizeTime?: string;
  /** @format date-time */
  authorizeExpireTime?: string;
  /** @format int64 */
  captureAmountInBase?: number;
  /** @format date-time */
  captureTime?: string;
  /** @format int64 */
  refundAmountInBase?: number;
  /** @format date-time */
  refundsTime?: string;
  /** @format int64 */
  currencyAmount?: number;
  /** @format int64 */
  currencyRate?: number;
}

export interface RefundAmountReqDTO {
  /** @format int64 */
  refundAmount?: number;
  comment?: string;
}

export interface OrderSetOrderSourceInfoRequest {
  orderIds: number[];
  /** @format int64 */
  orderSourceInfoId: number;
}

export interface OrderSetOrderLegalEntityRequest {
  orderIds: number[];
  /** @format int64 */
  legalEntityId: number;
}

export interface OrderSetOrderLegalEntityConciergeRequest {
  orderIds: number[];
}

export interface DateWithIntervalDTO {
  /** @format date-time */
  date?: string;
  timeInterval?: TimeIntervalDTO1;
}

export interface PickupOptionDTO {
  deliveryCompany?: DeliveryCompanyDTO;
  dateList?: DateWithIntervalsDTO1[];
}

export interface DeliveryCompanyDTO {
  /** @format int64 */
  id?: number;
  name?: string;
}

export interface DateWithIntervalsDTO1 {
  /** @format date-time */
  date?: string;
  timeIntervals?: TimeIntervalDTO1[];
}

export interface TimeIntervalDTO1 {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  fromHour?: number;
  /** @format int32 */
  toHour?: number;
  displayName?: string;
}

export interface PickupInfoDTO {
  /** @format int64 */
  deliveryCompanyId?: number;
  /** @format date-time */
  pickupDate?: string;
  /** @format int64 */
  timeIntervalId?: number;
}

export interface EventDateEstimationDTO {
  eventType?: EstimatedEventTypeDTO;
  /** @format date-time */
  estimatedDateFrom?: string;
  /** @format date-time */
  estimatedDateTo?: string;
}

export interface EventDateEstimationHistoryDTO {
  /** @format date-time */
  calculationTime?: string;
  /** @format date-time */
  estimatedDateFrom?: string;
  /** @format date-time */
  estimatedDateTo?: string;
  calculationContext?: string;
}

export enum EstimatedEventTypeDTO {
  DELIVERY_FROM_SELLER_COMPLETION = "DELIVERY_FROM_SELLER_COMPLETION",
  EXPERTISE_COMPLETION = "EXPERTISE_COMPLETION",
  DELIVERY_TO_BUYER_COMPLETION = "DELIVERY_TO_BUYER_COMPLETION",
}

/** LikeCount */
export interface LikeCount {
  /** @format int32 */
  count?: number;
  slideId?: string;
}

/**
 * NewPageStateDTO
 * Представление состояние сторисов для пользователя для новой главной страницы
 */
export interface NewPageStateDTO {
  /** Первичный ключ состояние */
  id?: string;
  /** Показывает индекс следующего сториса */
  nextAnchor?: string;
  /** Список сторисов */
  storyList: StoryDTO[];
}

/**
 * NewPageStoryDTO
 * Представление сторисов для новой главной страницы
 */
export interface NewPageStoryDTO {
  /** Индекс сториса */
  anchor: string;
  /**
   * Время создания сториса
   * @format date-time
   */
  createDate: string;
  /** Первичный ключ сториса */
  id?: string;
  /** Ник автора создавшего сторис */
  nickName: string;
  /** Превью фотография сториса */
  previewContentPath: string;
  seen?: boolean;
  /**
   * Количество сторисов
   * @format int32
   */
  storiesCount: number;
  /** Тип сториса */
  storyType: string;
  /** Заголовок сториса */
  title: string;
}

/**
 * RequestSlideDTO
 * Представление для сохранения слайда
 */
export interface RequestSlideDTO {
  /** Тип слайда */
  slideType: "ADV" | "INFO" | "SYSTEM" | "USER";
  /** Тип действия слайда */
  statusType: "ACTIVE" | "ARCHIVE" | "DELETED" | "HIDDEN" | "SOLD" | "USER" | "WAITING";
  /** Список стикеров слайда */
  stickerDTOList: StickerDTO[];
  /** Список тегов слайда */
  tagDTOList: TagDTO[];
  /** Заголовок слайда */
  title?: string;
}

/**
 * ResponseSlideDTO
 * Представление для получения слайда
 */
export interface ResponseSlideDTO {
  /** Превью фото автора создавшего сторис */
  avatarPath: string;
  /**
   * Количество комментариев данного слайда
   * @format int32
   */
  commentsCount: number;
  /** Ссылка на фотографию слайда */
  contentURL: string;
  /**
   * Время создания слайда
   * @format date-time
   */
  createTime: string;
  /** Первичный ключ слайда */
  id: string;
  liked?: boolean;
  /**
   * Количество лайков для данного слайда
   * @format int32
   */
  likesCount: number;
  /** Ник автора создавшего сторис */
  nickName: string;
  seen?: boolean;
  /** Тип слайда */
  slideType: "ADV" | "INFO" | "SYSTEM" | "USER";
  /** Тип действия слайда */
  statusType: "ACTIVE" | "ARCHIVE" | "DELETED" | "HIDDEN" | "SOLD" | "USER" | "WAITING";
  /** Список стикеров данного слайда */
  stickerDTOList: StickerDTO[];
  /** Список тегов данного слайда */
  tagDTOList: TagDTO[];
  /** Заголовок слайда */
  title?: string;
  /**
   * Первичный ключ пользователя создавшего слайд
   * @format int64
   */
  userId: number;
  /**
   * Количество просмотров данного слайда
   * @format int32
   */
  viewsCount: number;
}

/**
 * ResponseSlideWithPaginationDTO
 * Представление для получения слайда с пагинацией
 */
export interface ResponseSlideWithPaginationDTO {
  /**
   * Количество страниц
   * @format int64
   */
  pageCount: number;
  /** Список слайдов */
  responseSlideDTOS: ResponseSlideDTO[];
  /**
   * Количество слайдов
   * @format int64
   */
  slideCount: number;
}

/**
 * ResponseSlideWithUserInfoDTO
 * Представление для получения слайда для WishList
 */
export interface ResponseSlideWithUserInfoDTO {
  /** Ссылка на фото автарки пользователя */
  avatarPath: string;
  /**
   * Количество комментариев данного слайда
   * @format int32
   */
  commentsCount: number;
  /** Ссылка на фотографию слайда */
  contentURL: string;
  /**
   * Время создания слайда
   * @format date-time
   */
  createTime: string;
  /** Первичный ключ слайда */
  id: string;
  /**
   * Бутик или нет
   * @example false
   */
  isPro: boolean;
  /**
   * Доверенный пользователь
   * @example false
   */
  isTrusted: boolean;
  liked?: boolean;
  /**
   * Количество лайков для данного слайда
   * @format int32
   */
  likesCount: number;
  /** Ник пользователя */
  nickName: string;
  seen?: boolean;
  /** Тип слайда */
  slideType: "ADV" | "INFO" | "SYSTEM" | "USER";
  /** Тип действия слайда */
  statusType: "ACTIVE" | "ARCHIVE" | "DELETED" | "HIDDEN" | "SOLD" | "USER" | "WAITING";
  /** Список стикеров данного слайда */
  stickerDTOList: StickerDTO[];
  /** Список тегов данного слайда */
  tagDTOList: TagDTO[];
  /** Заголовок слайда */
  title?: string;
  /**
   * Первичный ключ пользователя создавшего слайд
   * @format int64
   */
  userId: number;
  /**
   * Количество просмотров данного слайда
   * @format int32
   */
  viewsCount: number;
}

/**
 * ResponseSlideWithUserInfoWithPaginationDTO
 * Представление для получения слайда с пагинацией
 */
export interface ResponseSlideWithUserInfoWithPaginationDTO {
  /**
   * Количество страниц
   * @format int64
   */
  pageCount: number;
  /** Список слайдов */
  responseSlideDTOS: ResponseSlideWithUserInfoDTO[];
  /**
   * Количество слайдов
   * @format int64
   */
  slideCount: number;
}

/**
 * SearchOskellySlidesFilterDTO
 * Представление (фильтр) для фильтрации слайдов oskelly в админке
 */
export interface SearchOskellySlidesFilterDTO {
  /**
   * Значения для фильтрации по дате (конец)
   * @format date-time
   */
  endDate?: string;
  /** Значение для фильтрации по статусу слайда */
  slideStatusType: "ACTIVE" | "ARCHIVE" | "DELETED" | "HIDDEN" | "SOLD" | "USER" | "WAITING";
  /** Значение для фильтрации по типу слайда */
  slideType: "ADV" | "INFO" | "SYSTEM" | "USER";
  /** Значение для типа сортировки */
  sortDirection: "ASC" | "DESC";
  /**
   * Значения для сортировки по критериям
   * @uniqueItems true
   */
  sortFields: ("COMMENT" | "DATE" | "LIKE" | "POPULARITY" | "REPOST" | "VIEW")[];
  /**
   * Значения для фильтрации по дате (начало)
   * @format date-time
   */
  startDate?: string;
  /** Значение для фильтрации по типу тега, только для slideType ADV */
  tagType?: "BRAND" | "COMPILATION" | "INFO" | "PRODUCT" | "SELL" | "SOCIAL" | "SYSTEM" | "USER";
  /** Значение для поиска по наименованию слайда */
  titleName?: string;
}

/**
 * SearchUserSlidesFilterDTO
 * Представление (фильтр) для фильтрации слайдов в админке
 */
export interface SearchUserSlidesFilterDTO {
  /**
   * Значения для фильтрации по первичным ключам категорий
   * @format int64
   */
  categoryId?: number;
  /**
   * Значения для фильтрации по дате (конец)
   * @format date-time
   */
  endDate?: string;
  /**
   * Значения для фильтрации по просмотренным/непросмотренным слайдам
   * @example false
   */
  isSeen?: boolean;
  /**
   * Значение для фильтрации слайдов, лайкнутым этим пользователем
   * @format int64
   */
  likedByUserId?: number;
  /** Значение для фильтрации по типу слайда */
  slideStatusType: "ACTIVE" | "ARCHIVE" | "DELETED" | "HIDDEN" | "SOLD" | "USER" | "WAITING";
  /** Значение для типа сортировки */
  sortDirection: "ASC" | "DESC";
  /**
   * Значения для сортировки по критериям
   * @uniqueItems true
   */
  sortFields: ("COMMENT" | "DATE" | "LIKE" | "POPULARITY" | "REPOST" | "VIEW")[];
  /**
   * Значения для фильтрации по дате (начало)
   * @format date-time
   */
  startDate?: string;
  /**
   * Значение по поиску слайдов по первичному ключу пользователя
   * @format int64
   */
  userId?: number;
}

/** SlideCountDTO */
export interface SlideCountDTO {
  /** @format int32 */
  count?: number;
  /** @format int64 */
  userId?: number;
}

/** SlideViewCount */
export interface SlideViewCount {
  /** @format int32 */
  count?: number;
  slideId?: string;
  /** @uniqueItems true */
  userIds?: string[];
}

/**
 * SlidesCountByProductDTO
 * Представление возвращающее количество слайдов по товарам
 */
export interface SlidesCountByProductDTO {
  /**
   * Первичный ключ товара
   * @format int64
   */
  productId?: number;
  /**
   * Количество слайдов
   * @format int64
   */
  slidesCount?: number;
}

/**
 * StateDTO
 * Представление состояние сторисов для пользователя
 */
export interface StateDTO {
  /** Первичный ключ состояние */
  id?: string;
  /** Представление инфо сторис */
  infoStory?: StoryDTO;
  /**
   * Количество страниц дл пагинации
   * @format int32
   */
  pageCount: number;
  /**
   * Количество общих сторисов в состоянии
   * @format int32
   */
  storiesCount: number;
  /** Список сторисов */
  storyList: StoryDTO[];
}

/**
 * StickerDTO
 * Представление стикера для слайда
 */
export interface StickerDTO {
  /**
   * Наклон стикера
   * @format float
   */
  angle: number;
  /** Эмоджи */
  emoji?: string;
  /**
   * Высота стикера в процентах от реального размера картинки
   * @format float
   */
  height: number;
  /** Первичный ключ стикера */
  id?: string;
  /**
   * Масштаб стикера
   * @format float
   */
  scale: number;
  /** Первичный ключ слайда */
  slideId?: string;
  /** Ссылка на фотографию стикера */
  stickerURL?: string;
  /**
   * Ширина стикера в процентах от реального размера картинки
   * @format float
   */
  width: number;
  /**
   * Местоположение стикера по координате Х
   * @format float
   */
  x: number;
  /**
   * Местоположение стикера по координате Y
   * @format float
   */
  y: number;
}

/**
 * StoryDTO
 * Представление сториса
 */
export interface StoryDTO {
  /** Превью фото автора создавшего сторис */
  avatarPath: string;
  /**
   * Время создания сториса
   * @format date-time
   */
  createDate: string;
  /**
   * Номер первого не просмотренного слайда
   * @format int32
   */
  firstNotSeenSlideNumber: number;
  /** Полное имя автора создавшего сторис */
  fullName?: string;
  /** Первичный ключ сториса */
  id?: string;
  /** Имя автора создавшего сторис */
  name?: string;
  /** Ник автора создавшего сторис */
  nickName: string;
  /** Превью фотография сториса */
  previewContentPath: string;
  pro?: boolean;
  seen?: boolean;
  /** Список слайдов в сторис */
  slideList: ResponseSlideDTO[];
  /**
   * Количество слайдов в сторис
   * @format int32
   */
  slidesCount: number;
  /** Статус сториса */
  statusType: "ACTIVE" | "ARCHIVE" | "DELETED" | "HIDDEN" | "SOLD" | "USER" | "WAITING";
  /** Тип сториса */
  storyType: string;
  subscribe?: boolean;
  /** Заголовок сториса */
  title: string;
  trusted?: boolean;
}

/**
 * TagDTO
 * Представление тега для слайда
 */
export interface TagDTO {
  /** Ссылка на контент тега */
  contentURL: string;
  /** Первичный ключ тега */
  id?: string;
  /**
   * Старая цена товара указанного в теге
   * @format int32
   */
  oldPrice: number;
  /**
   * Цена товара указанного в теге
   * @format int32
   */
  price: number;
  /**
   * Первичный ключ товара указанного в теге
   * @format int64
   */
  productId?: number;
  /** Первичный ключ слайда */
  slideId?: string;
  /** Тип состояния тега */
  statusType: "ACTIVE" | "ARCHIVE" | "DELETED" | "HIDDEN" | "SOLD" | "USER" | "WAITING";
  /** Имя товара или бренда указанного в теге */
  tagName: string;
  /** Тип тега */
  tagType: "BRAND" | "COMPILATION" | "INFO" | "PRODUCT" | "SELL" | "SOCIAL" | "SYSTEM" | "USER";
  /**
   * Местоположение тега по координате Х
   * @format float
   */
  x: number;
  /**
   * Местоположение тега по координате Y
   * @format float
   */
  y: number;
}
