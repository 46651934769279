/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Api2ResponseOfListOfString,
  Api2ResponseOfPageOfProductDTO,
  Api2ResponseOfSearchPageOfProductDTO,
  ApiV2ResponseOfItemsCount,
  ApiV2ResponseOfProductFilter,
  ApiV2ResponseOfProductFilters,
  ProductFilterInfoRequest,
  ProductFilterItemsRequest,
  ProductFilterRequest,
  SearchProductFilterInfoRequest,
  SearchProductFilterItemsRequest,
  SearchProductFilterRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Products<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Получение доступных фильтров (+ опционально товаров) в зависимости от заданной фильтрации
   *
   * @name PostApiV2ProductsFilter
   * @summary getAvailableFilters
   * @request POST:/api/v2/products/filter
   */
  postApiV2ProductsFilter = (data: ProductFilterRequest, params: RequestParams = {}) =>
    this.request<ApiV2ResponseOfProductFilters, any>({
      path: `/api/v2/products/filter`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2ProductsFilterInfo
   * @summary getAvailableFilterInfo
   * @request POST:/api/v2/products/filter/info
   */
  postApiV2ProductsFilterInfo = (
    data: ProductFilterInfoRequest,
    query?: {
      /** Код фильтра */
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2ResponseOfProductFilter, any>({
      path: `/api/v2/products/filter/info`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2ProductsFilterItems
   * @summary getItems
   * @request POST:/api/v2/products/filter/items
   */
  postApiV2ProductsFilterItems = (
    data: ProductFilterItemsRequest,
    query?: {
      /** Код фильтра */
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, any>({
      path: `/api/v2/products/filter/items`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2ProductsFilterCount
   * @summary getItemsCount
   * @request POST:/api/v2/products/filter/count
   */
  postApiV2ProductsFilterCount = (
    data: ProductFilterItemsRequest,
    query?: {
      /** Код фильтра */
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2ResponseOfItemsCount, any>({
      path: `/api/v2/products/filter/count`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Получение доступных фильтров (+ опционально товаров) в зависимости от заданной фильтрации
   *
   * @name PostApiV2ProductsSearch
   * @summary getAvailableFilters
   * @request POST:/api/v2/products/search
   */
  postApiV2ProductsSearch = (data: SearchProductFilterRequest, params: RequestParams = {}) =>
    this.request<ApiV2ResponseOfProductFilters, any>({
      path: `/api/v2/products/search`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2ProductsSearchInfo
   * @summary getAvailableFilterInfo
   * @request POST:/api/v2/products/search/info
   */
  postApiV2ProductsSearchInfo = (
    data: SearchProductFilterInfoRequest,
    query?: {
      /** Код фильтра */
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2ResponseOfProductFilter, any>({
      path: `/api/v2/products/search/info`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2ProductsSearchItems
   * @summary getItems
   * @request POST:/api/v2/products/search/items
   */
  postApiV2ProductsSearchItems = (
    data: SearchProductFilterItemsRequest,
    query?: {
      /** Код фильтра */
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfSearchPageOfProductDTO, Api2ResponseOfSearchPageOfProductDTO>({
      path: `/api/v2/products/search/items`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2ProductsSearchCount
   * @summary getItemsCount
   * @request POST:/api/v2/products/search/count
   */
  postApiV2ProductsSearchCount = (
    data: SearchProductFilterInfoRequest,
    query?: {
      /** Код фильтра */
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2ResponseOfItemsCount, Api2ResponseOfPageOfProductDTO>({
      path: `/api/v2/products/search/count`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name GetApiV2ProductsSearchGetHistory
   * @summary getSearchHistory
   * @request GET:/api/v2/products/search/history
   */
  getApiV2ProductsSearchGetHistory = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfString, any>({
      path: `/api/v2/products/search/history`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name GetApiV2ProductsSearchGetSuggestions
   * @summary getSuggestions
   * @request GET:/api/v2/products/search/suggestions
   */
  getApiV2ProductsSearchGetSuggestions = (
    query: {
      userQuery: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfString, any>({
      path: `/api/v2/products/search/suggestions`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
