import { TGenderPagesValues, TGenderPages } from '~/types/main';
import { PrimaryPageType } from '~/restAPI/data-contracts';

const categoryRootWomenId = 2;
const categoryRootMenId = 105;
const categoryRootKidsId = 188;
const categoryRootLifestyleId = 366;

export const useGender = () => {
  const gender = useCookie<TGenderPagesValues>('gender');

  const genderPages: TGenderPages = {
    [PrimaryPageType.FEMALE]: 'women',
    [PrimaryPageType.MALE]: 'men',
    [PrimaryPageType.CHILD]: 'kids',
    [PrimaryPageType.LIFESTYLE]: 'lifestyle',
  };

  const genderPagesIds = {
    [genderPages[PrimaryPageType.FEMALE]]: categoryRootWomenId,
    [genderPages[PrimaryPageType.MALE]]: categoryRootMenId,
    [genderPages[PrimaryPageType.CHILD]]: categoryRootKidsId,
    [genderPages[PrimaryPageType.LIFESTYLE]]: categoryRootLifestyleId,
  };

  const genderCategories = [
    { id: categoryRootWomenId, name: genderPages[PrimaryPageType.FEMALE], text: 'composables.useGender.women' },
    { id: categoryRootMenId, name: genderPages[PrimaryPageType.MALE], text: 'composables.useGender.men' },
    { id: categoryRootKidsId, name: genderPages[PrimaryPageType.CHILD], text: 'composables.useGender.kids' },
    { id: categoryRootLifestyleId, name: genderPages[PrimaryPageType.LIFESTYLE], text: 'composables.useGender.lifestyle' },
  ];

  const mainGenderRoutes = {
    women: [
      `/${genderPages[PrimaryPageType.FEMALE]}`,
      'zhenskoe',
    ],
    men: [
      `/${genderPages[PrimaryPageType.MALE]}`,
      'muzhskoe',
    ],
    kids: [
      `/${genderPages[PrimaryPageType.CHILD]}`,
      'detskoe',
    ],
    lifestyle: [
      `/${genderPages[PrimaryPageType.LIFESTYLE]}`,
      'lifestyle',
    ],
  };

  function setGender(value: TGenderPagesValues) {
    if (value !== genderPages[PrimaryPageType.CHILD] && value !== genderPages[PrimaryPageType.LIFESTYLE]) {
      gender.value = value;
    }
  }

  function isRouteInclude(arr: string[], url: string) {
    return arr.some((str) => url.includes(str));
  }

  function getGenderCategoryByRoute(url: string) {
    if (isRouteInclude(mainGenderRoutes.women, url)) return genderPages[PrimaryPageType.FEMALE];
    if (isRouteInclude(mainGenderRoutes.men, url)) return genderPages[PrimaryPageType.MALE];
    if (isRouteInclude(mainGenderRoutes.kids, url)) return genderPages[PrimaryPageType.CHILD];
    if (isRouteInclude(mainGenderRoutes.lifestyle, url)) return genderPages[PrimaryPageType.LIFESTYLE];

    return null;
  }

  return {
    genderPages,
    genderCategories,
    mainGenderRoutes,
    gender,
    setGender,
    isRouteInclude,
    getGenderCategoryByRoute,
    genderPagesIds,
  };
};
