export function useExperimentsStore() {
  const { globalSettings } = useAccountStore();

  const experiments = computed(() => globalSettings.value.experiments);
  const experimentIds = computed(() => experiments.value?.map((exp) => String(exp.id)));
  const experimentValueIds = computed(() => experiments.value?.map((exp) => String(exp.valueId)));

  const SHOW_RETURNS_BANNER_EXPERIMENT = computed(() => experiments.value?.find((e) => e.key === 'SHOW_RETURNS_BANNER'));
  const PHONE_AUTH_WEB_EXPERIMENT = computed(() => experiments.value?.find((e) => e.key === 'PHONE_AUTH_WEB'));
  const PHONE_POPUP_WEB_EXPERIMENT = computed(() => experiments.value?.find((e) => e.key === 'PHONE_POPUP_WEB'));
  const PHONE_REGISTRATION_WEB_EXPERIMENT_ON = computed(() => {
    const exp = experiments.value?.find((e) => e.key === 'PHONE_REGISTRATION_WEB');

    return exp?.valueKey === 'on';
  });
  const CHANGE_COUNTRY_WEB_EXPERIMENT_ON = computed(() => {
    const exp = experiments.value?.find((e) => e.key === 'CHANGE_COUNTRY_WEB');

    return exp?.valueKey === 'on';
  });
  const PHONE_VERIFICATION_WHATSAPP_FIRST = computed(() => {
    const exp = experiments.value?.find((e) => e.key === 'PHONE_VERIFICATION_WHATSAPP_FIRST_WEB');

    return exp?.valueKey === 'on';
  });
  const FULLTEXT_SEARCH_WEB_ON = computed(() => {
    const exp = experiments.value?.find((e) => e.key === 'FULLTEXT_SEARCH_WEB');

    return exp?.valueKey === 'on';
  });

  return {
    experiments,
    experimentIds,
    experimentValueIds,
    SHOW_RETURNS_BANNER_EXPERIMENT,
    PHONE_AUTH_WEB_EXPERIMENT,
    PHONE_POPUP_WEB_EXPERIMENT,
    PHONE_REGISTRATION_WEB_EXPERIMENT_ON,
    CHANGE_COUNTRY_WEB_EXPERIMENT_ON,
    PHONE_VERIFICATION_WHATSAPP_FIRST,
    FULLTEXT_SEARCH_WEB_ON,
  };
}
